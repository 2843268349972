<template>
  <v-dialog v-model="dialog" fullscreen hide-overlay>
    <template v-slot:activator="{ on }">
      <v-btn text v-on="on" v-if="auth && auth.loggedIn">Edit</v-btn>
    </template>
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="cancel()">
          <v-icon>fa-times</v-icon>
        </v-btn>
        <v-toolbar-title>Edition de cas test</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn
            v-if="auth && auth.loggedIn"
            dark
            text
            @click="save(true)"
            :disabled="!test.pk"
            >copie</v-btn
          >
          <v-btn dark text :disabled="!valid" @click="save()">{{
            $t('save')
          }}</v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text>
        <v-form ref="form" v-model="valid">
          <h2 class="mt-4">
            {{ jestime.prestations[jestime.fiche.prestation].nom }}
          </h2>
          <v-text-field
            v-model="test.nom"
            label="Nom du test"
            :rules="[rules.required]"
          ></v-text-field>

          <div v-if="!(auth && auth.loggedIn)">
            <h3>
              Estimation faite par jestime
            </h3>
            <equation-renderer
              :equation="e"
              v-for="e in equations"
              :key="e.nom"
            ></equation-renderer>
            <div class="estimation-warn">
              Ce résultat est une estimation arrondie.
            </div>
          </div>
          <div class="j-title">
            Le montant de l'estimation correspond au montant attendu?
          </div>
          <v-btn-toggle
            v-model="test.correct"
            mandatory
            dense
            class="yes-no my-4"
          >
            <v-btn :value="true" :color="test.correct ? 'green' : 'grey'">
              <span>Oui</span>
              <v-icon right>
                fa-thumbs-up
              </v-icon>
            </v-btn>

            <v-btn :value="false" :color="test.correct ? 'grey' : 'red'">
              <span>Non</span>
              <v-icon right>
                fa-thumbs-down
              </v-icon>
            </v-btn>
          </v-btn-toggle>

          <div v-if="!test.correct">
            <h3>
              Introduire montant réel
            </h3>
            <v-textarea v-model="test.descriptif" label="Veuillez indiquer les montants réels pour l’ensemble du foyer ou pour chaque membre séparément"></v-textarea>
          </div>

          <v-textarea v-model="comment" label="Commentaire"></v-textarea>

          <v-text-field
            v-model="test.contact"
            label="Email de contact"
            type="email"
            :rules="[rules.email]"
          ></v-text-field>

          <v-file-input
            v-for="(file, index) in files"
            :key="index"
            v-model="files[index]"
            show-size
            label="Choisir le fichier"
            :rules="[rules.filesize]"
          />
          <v-btn @click="files.push(null)">Dépôt de cas test</v-btn>

          <div class="my-4">
            <v-btn color="primary" :disabled="!valid" @click="save()">{{
              $t('save')
            }}</v-btn>
          </div>
        </v-form>

        <v-tabs v-if="auth && auth.loggedIn">
          <v-tab>Inputs</v-tab>
          <v-tab>Tests</v-tab>

          <v-tab-item>
            <h2 class="mt-2">Inputs</h2>
            <location-selector></location-selector>
            <v-list dense>
              <v-list-item v-for="name in filteredData" :key="name">
                <v-list-item-content>
                  {{ name }}
                  <equation-renderer
                    :equation="$store.getters.allVariables[name]"
                  ></equation-renderer>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn @click="removeInputVar(name)" text>supprimer</v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>

            <h3>Personne Scope</h3>
            <v-checkbox v-model="showExterne" label="Externe"></v-checkbox>
            <personne-selector
              :allow-edit="true"
              :show-externe="showExterne"
            ></personne-selector>
            <v-list dense>
              <v-list-item
                v-for="(value, name) in testPersonnes[currentPersonneIndex]"
                :key="name"
              >
                <v-list-item-content>
                  <equation-renderer
                    :equation="$store.getters.allVariables[name]"
                  ></equation-renderer>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn
                    @click="removeInputVar(name, currentPersonneIndex)"
                    text
                    >supprimer</v-btn
                  >
                </v-list-item-action>
              </v-list-item>
            </v-list>

            <h3>Assurance</h3>
            <assurance-selector></assurance-selector>

            <v-card color="grey lighten-3">
              <v-card-title>Ajouter variable input au cas</v-card-title>
              <v-card-text>
                <v-autocomplete
                  v-model="inputVar"
                  :items="inputs"
                  label="Variable"
                  item-value="nom"
                  return-object
                  :item-text="e => `${e.nom} - ${e.libelle}`"
                ></v-autocomplete>
              </v-card-text>
              <v-card-actions>
                <v-btn text color="primary" @click="addInputVar">Ajouter</v-btn>
              </v-card-actions>
            </v-card>
          </v-tab-item>

          <v-tab-item>
            <h2 class="mt-2">Tests</h2>
            <v-list>
              <v-list-item
                v-for="name in Object.keys(test.toBe).filter(
                  name => name !== 'personnes'
                )"
                :key="name"
              >
                <v-list-item-content>
                  <v-checkbox
                    v-if="typeof test.toBe[name] === 'boolean'"
                    v-model="test.toBe[name]"
                    :label="name"
                  ></v-checkbox>
                  <v-currency-field
                    v-else
                    :label="name"
                    v-model="test.toBe[name]"
                    placeholder="0"
                  ></v-currency-field>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn @click="removeTestVar(name)" text>supprimer</v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>
            <h3>Personne Scope</h3>
            <personne-selector :allow-edit="true"></personne-selector>
            <div v-if="test.toBe.personnes">
              <v-list>
                <v-list-item
                  v-for="(value, name) in test.toBe.personnes[
                    currentPersonneIndex
                  ]"
                  :key="name"
                >
                  <v-list-item-content>
                    <v-checkbox
                      v-if="
                        typeof test.toBe.personnes[currentPersonneIndex][
                          name
                        ] === 'boolean'
                      "
                      v-model="test.toBe.personnes[currentPersonneIndex][name]"
                      :label="name"
                    ></v-checkbox>
                    <v-currency-field
                      v-else
                      :label="name"
                      v-model="test.toBe.personnes[currentPersonneIndex][name]"
                      placeholder="0"
                    ></v-currency-field>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn
                      text
                      @click="removeTestVar(name, currentPersonneIndex)"
                      >supprimer</v-btn
                    >
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </div>
            <v-card color="grey lighten-3">
              <v-card-title>Ajouter variable test au cas</v-card-title>
              <v-card-text>
                <v-autocomplete
                  v-model="testVar"
                  :items="others"
                  label="variable"
                  item-value="nom"
                  return-object
                  :item-text="e => `${e.nom} - ${e.libelle}`"
                ></v-autocomplete>
                <v-radio-group v-model="testVarType" row>
                  <v-radio label="nombre" :value="0"></v-radio>
                  <v-radio label="vrai/faux" :value="true"></v-radio>
                </v-radio-group>
              </v-card-text>
              <v-card-actions>
                <v-btn @click="addTestVar" color="primary" text>Ajouter</v-btn>
              </v-card-actions>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from 'vue'

import PersonneSelector from '@/components/widgets/PersonneSelector'
import LocationSelector from '@/components/widgets/LocationSelector'
import EquationRenderer from '@/components/model/EquationRenderer'
import AssuranceSelector from '@/components/widgets/AssuranceSelector'

export default {
  name: 'TestEditorDialog',
  props: ['test'],
  data() {
    return {
      valid: true,
      dialog: false,
      testVar: null,
      inputVar: null,
      testVarType: 0,
      showExterne: false,
      files: [], // local for now
      comment: '',
      resolve: undefined,
      reject: undefined,
      rules: {
        filesize: () => this.files.filter(f => !!f).reduce((total, f) => total + f.size, 0) < 50000000 || 'Limite de taille totale = 50 MB',
        required: value => !!value || 'Requis.',
        email: v =>
          v === '' || /.+@.+\..+/.test(v) || 'Saisissez une adresse email valable.'
      }
    }
  },
  methods: {
    addTestVar() {
      if (this.testVar.scope) {
        if (!this.test.toBe.personnes) {
          this.test.toBe.personnes = []
        }
        while (this.test.toBe.personnes.length <= this.currentPersonneIndex) {
          this.test.toBe.personnes.push({})
        }
        Vue.set(
          this.test.toBe.personnes[this.currentPersonneIndex],
          this.testVar.nom,
          this.testVarType
        )
      } else {
        Vue.set(this.test.toBe, this.testVar.nom, this.testVarType)
      }
    },
    removeTestVar(name, index) {
      if (typeof index === 'undefined') {
        Vue.delete(this.test.toBe, name)
      } else {
        Vue.delete(this.test.toBe.personnes[index], name)
      }
    },
    addInputVar() {
      if (this.inputVar.scope) {
        if (!this.test.data.personnes) {
          this.test.data.personnes = []
        }
        while (this.test.data.personnes.length <= this.currentPersonneIndex) {
          this.test.data.personnes.push({})
        }
        Vue.set(
          this.testPersonnes[this.currentPersonneIndex],
          this.inputVar.nom,
          null
        )
      } else {
        Vue.set(this.test.data, this.inputVar.nom, null)
      }
    },
    removeInputVar(name, index) {
      if (typeof index === 'undefined') {
        Vue.delete(this.test.data, name)
      } else {
        Vue.delete(this.test.data.personnes[index], name)
      }
    },
    cancel() {
      this.$store
        .dispatch('getTestCases', this.$store.state.jestime.fiche)
        .then(() => {
          this.dialog = false
          this.reject()
        })
    },
    save(copy) {
      // update value before save
      this.test.descriptif += '\n\n' + this.comment
      function updateData(data, vm) {
        Object.keys(data).forEach(key => {
          if (key === 'personnes') {
            data.personnes.forEach((p, i) => updateData(p, vm.personnes[i]))
          } else if (key === 'personnes_externes') {
            data.personnes_externes.forEach((p, i) =>
              updateData(p, vm.personnes_externes[i])
            )
          } else {
            data[key] = vm[key]
          }
        })
      }
      updateData(this.test.data, this.$store.state.sim.vm)
      if (copy) {
        this.test.nom += ' (copy)'
        Vue.delete(this.test, 'pk')
      }
      this.$store
        .dispatch('saveTestCase', this.test)
        .then(async test => {
          if (this.files.filter(f => !!f).length > 0) {
            try {
              await this.$store.dispatch('uploadTestCaseFile', {
                pk: test.pk,
                files: this.files
              })
            } catch (e) {
              this.$root.$emit(
                'snackbar',
                `ERROR: d'envoie des fichiers du cas test avec le numéro #${test.pk}`
              )
              console.log(e)
            }
          }
          this.$store
            .dispatch('getTestCases', this.$store.state.jestime.fiche)
            .then(() => {
              this.dialog = false
            })
          if (this.auth && this.auth.loggedIn) {
            this.$root.$emit(
              'snackbar',
              `Votre cas de test est bien enregistré avec le numéro #${test.pk}`
            )
          } else {
            this.$root.$emit('snackbar', {
              text: `Votre cas de test est bien enregistré avec le numéro #${test.pk}`,
              timeout: -1
            })
          }
        })
        .catch(e => {
          this.$root.$emit(
            'snackbar',
            'ERROR: Saving check console for details'
          )
          console.log(e)
        })
    },
    show() {
      this.dialog = true
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    }
  },
  computed: {
    jestime() {
      return this.$store.state && this.$store.state.jestime
    },
    vmPersonnes() {
      return this.$store.state.sim.vm[
        this.showExterne ? 'personnes_externes' : 'personnes'
      ]
    },
    testPersonnes() {
      return this.test.data[
        this.showExterne ? 'personnes_externes' : 'personnes'
      ]
    },
    auth() {
      return this.$store.state && this.$store.state.auth
    },
    currentPersonneIndex() {
      return this.vmPersonnes.indexOf(this.$store.state.sim.vm.currentPersonne)
    },
    inputs() {
      return Object.values(this.$store.state.jestime.globalVariables)
        .concat(this.$store.state.jestime.variables)
        .concat(this.$store.state.jestime.depVariables)
        .filter(e => e.type === 'I')
        .sort((a, b) => a.nom.localeCompare(b.nom))
    },
    others() {
      return Object.values(this.$store.state.jestime.globalVariables)
        .concat(this.$store.state.jestime.variables)
        .concat(this.$store.state.jestime.depVariables)
        .filter(e => e.type !== 'I')
        .sort((a, b) => a.nom.localeCompare(b.nom))
    },
    filteredData() {
      return Object.keys(this.test.data).filter(
        name =>
          ![
            'personnes',
            'logement',
            'lieuLogement',
            'personnes_externes'
          ].includes(name)
      )
    },
    // for anoymous report
    equations() {
      return this.$store.state.jestime.variables.filter(e => e.type === 'O')
    }
  },
  watch: {
    dialog() {
      if (this.dialog) {
        // loadTest to VM for equation-render widgets to work
        this.$store.dispatch('loadData', this.test.data)
      }
    }
  },
  components: {
    PersonneSelector,
    LocationSelector,
    EquationRenderer,
    AssuranceSelector
  }
}
</script>

<style scoped>
.yes-no .v-item--active,
.yes-no .v-item--active .v-icon {
  color: white !important;
}
</style>
