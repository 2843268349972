<template>
  <v-container>
    <v-row class="estimation-table-box">
      <v-col>
        <h2>A propos</h2>
        <p>
          jestime.ch est un projet de la HES-SO pour faciliter l'accès aux
          prestations sociales des personnes qui ont peu de revenus. Il est
          conduit depuis 2017 par la
          <a href="https://www.he-arc.ch/gestion">Haute école de gestion ARC</a>
          et la
          <a href="https://www.hetsl.ch/"
            >Haute école de travail social et de la santé Lausanne</a
          >.
        </p>

        <h2>Un site qui permet de connaître ses droits sociaux</h2>
        <p>
          De nombreuses personnes ne demandent pas les prestations sociales
          auxquelles elles ont droit. Environ un quart des personnes qui
          pourraient bénéficier de l’aide sociale ne réclament pas cette
          prestation. De nombreuses personnes ne sollicitent pas les subsides à
          l’assurance maladie, tout comme nombre de personnes âgées ou invalides
          ne connaissent pas l’existence des prestations complémentaires qui
          pourraient les aider à boucler leurs fins de mois. Ce non-recours
          produit des inégalités sociales entre les personnes qui font valoir
          leurs droits et les autres. Elle génère également un coût lié à
          l’aggravation de problématiques qui ne sont pas prises en charge.
          Finalement, elle questionne l’efficacité des politiques sociales. À
          quoi bon des dispositifs qui ne touchent pas leur public ?
        </p>

        <p>
          Pour répondre à ce problème, une équipe de recherche a développé un
          site internet qui répond aux deux raisons principales du non-recours :
          la méconnaissance et la complexité des dispositifs.
        </p>

        <p>
          Grâce au financement de la
          <a
            href="https://www.grstiftung.ch/de/media/portfolio~grs-067-17~.html"
            >Gebert Rüf Stiftung</a
          >, Jestime.ch permet d’identifier de manière simple et sur la base
          d’informations mises à jour l’ensemble des droits sociaux sous
          condition de ressources dans les cantons romands. Il est possible
          d’estimer le montant de l’aide financière potentielle et de trouver
          les adresses pertinentes pour faire valoir ses droits. Ce projet
          s’adresse non seulement au grand public, mais encore aux institutions
          de l’action sociale qui peuvent l’utiliser dans leurs permanences.
          Caritas, les CSP, Pro Infirmis et Pro Senectute ont collaboré à ce
          projet.
        </p>

        <h2>Media Kit</h2>
        <p>Flyer (10.2021): <a href="/static/flyer_jestime.pdf">Télécharger flyer_jestime.pdf</a></p>

        <h2>On parle de Jestime.ch</h2>
        <p>Presse écrite</p>
        <ul>
          <li>
            <a
              href="https://www.hes-so.ch/data/documents/Rapport-annuel-HES-SO-2017-9917.pdf"
            >
              Un site qui permet de connaître ses droits sociaux</a
            >, p46, Rapport Annuel 2017, HES-SO
          </li>
          <li>
            <a
              href="/static/press/20181119_Le Courrier_Connaître ses droits Pas évident.pdf"
            >
              Connaître ses droits? Pas évident</a
            >, p7, 19.11.2018, Le Courrier
          </li>
          <li>
            <a
              href="/static/press/20190316_24Heures_Les innovations pédagogiques réinventent l'enseignement.pdf"
            >
              Les innovations pédagogiques réinventent l’enseignement</a
            >, p16, 16.03.2019, 24 heures
          </li>
          <li>
            <a
              href="https://www.he-arc.ch/sites/www.he-arc.ch/files/ges-art200302-journal_du_centre_social_protestant-la_diversite_sauvage_de_la_protection_sociale_mention_heg_arc.pdf"
              >La "diversité sauvage" de la protection sociale</a
            >, p4, Edition Romande N°01 - Mars 2020, Journal du centre social
            protestant
          </li>
          <li>
            <a
              href="https://www.he-arc.ch/sites/www.he-arc.ch/files/ges-art200314-arcinfo-cliquez_pour_connaitre_vos_droits.pdf"
              >Cliquez pour connaître vos droits!</a
            >, p34, 14.03.2020, Arcinfo
          </li>
          <li>
            <a
              href="https://www.he-arc.ch/sites/www.he-arc.ch/files/ges-art210825-le_matin-un_site_pour_calculer_les_droits_des_romands_a_des_prestations_sociales.pdf"
              >Un site pour calculer les droits des Romands à des prestations sociales</a
            >, 25.08.2021, Le Matin
          </li>
          <li>
            <a
              href="https://www.he-arc.ch/sites/www.he-arc.ch/files/ges-art210826-20_minutes-un_site_pour_calculer_les_droits_des_romands_a_des_prestations_sociales.pdf"
              >U5 site pour calculer les droits des Romands à des prestations sociales</a
            >, 25.08.2021, 20 minutes
          </li>
          <li>
            <a
              href="https://www.he-arc.ch/sites/www.he-arc.ch/files/ges-art210826-reiso-jestime.ch_un_site_pour_les_droits_sociaux_mention_heg_arc.pdf"
              >Jestime.ch, un site pour les droits sociaux</a
            >, 26.08.2021, Revue d'information sociale REISO
          </li>
          <li>
            <a
              href="https://www.he-arc.ch/sites/www.he-arc.ch/files/ges-art210826-arcinfo-prestations_sociales_un_site_internet_qui_vous_aide_a_y_voir_clair_mention_heg_arc.pdf"
              >Prestations sociales: un site internet qui vous aide à y voir clair</a
            >, 26.08.2021, Arcinfo
          </li>
          <li>
            <a
              href="https://www.he-arc.ch/sites/www.he-arc.ch/files/ges-art210826-24_heures-jusqua_un_quart_des_gens_ayant_droit_a_une_aide_lignorent_mention_heg_arc.pdf"
              >Jusqu’à un quart des gens ayant droit à une aide l’ignorent. Et vous?</a
            >, 26.08.2021, 24 Heures
          </li>
          <li>
            <a
              href="https://www.he-arc.ch/sites/www.he-arc.ch/files/ges-art210831-le_courrier-un_acces_plus_simple_a_laide_sociale_mentions_c.gaspoz_et_heg_arc.pdf"
              >Un accès plus simple à l’aide sociale</a
            >, 31.08.2021, Le Courrier
          </li>
          <li>
            <a
              href="https://www.rts.ch/info/suisse/12470187-le-site-jestimech-evalue-anonymement-les-droits-aux-prestations-sociales.html"
              >Le site "jestime.ch" évalue anonymement les droits aux prestations sociales</a
            >, 06.09.2021, RTS Info
          </li>
          <li>
            <a
              href="https://www.he-arc.ch/sites/www.he-arc.ch/files/ges-art210913-migros_magazine-prestations_socialescest_une_faillite_de_la_politique_sociale_si_les_gens_ny_font_pas_appel_mention_heg_arc.pdf"
              >«C’est une faillite de la politique sociale si les gens n’y font pas appel»</a
            >, 13.09.2021, Migros Magazine
          </li>
          <li>
            <a
              href="https://www.he-arc.ch/sites/www.he-arc.ch/files/ges-art210917-services_publics-le_social_en_un_clic_mention_c.gaspoz.pdf"
              >Le social en un clic</a
            >, 17.09.2021, Services publics
          </li>
        </ul>
        <p class="mt-4">Radio</p>
        <ul>
          <li>
            <a
              href="https://www.rts.ch/play/radio/tribu/audio/refuser-laide-sociale?id=10412371"
              >RTS Tribu, Refuser lʹaide sociale</a
            >, 20.05.2019
          </li>
          <li>
            <a
              href="https://www.he-arc.ch/sites/www.he-arc.ch/files/ges-son210826-rts-vacarme-precarite_les_meandres_de_laide_sociale_mention_c._gaspoz_heg_arc.mp3"
              >RTS - La 1ère - Vacarme</a
            >, 26.08.2021
          </li>
          <li>
            <a
              href="https://www.he-arc.ch/sites/www.he-arc.ch/files/ges-son210906-rts-la_1ere-on_en_parle-jestime.ch_le_site_qui_permet_de_tester_ses_droits_aux_prestations_sociales_mention_heg_arc.mp3"
              >RTS - La 1ère - On en parle</a
            >, 06.09.2021
          </li>
          <li>
            <a
              href="https://pages.rts.ch/la-1ere/programmes/la-ligne-de-coeur/15-09-2021"
              >RTS - La 1ère - La ligne de coeur: On en parle Quid des droits sociaux en Suisse</a
            >, 15.09.2021
          </li>
        </ul>
        <p class="mt-4">Télévision</p>
        <ul>
          <li>
            <a href="https://www.canalalpha.ch/play/le-journal/topic/23825/un-nouveau-site-internet-pour-lutter-contre-la-precarite">Journal Canal, Canal Alpha</a>, 27.08.2021
          </li>
        </ul>

        <h2 class="mt-4">Nous contacter</h2>
        <p>
          Les questions concernant le projet peuvent être envoyées à <a href="mailto:contact@jestime.ch">contact@jestime.ch</a>
        </p>

        <h2 class="mt-4">Autres personnes participant au projet</h2>
        <ul>
          <li>
            <strong>Prof. Dr. Cédric Gaspoz</strong>, Haute école de gestion Arc
            (HES-SO), responsable de projet et spécialiste en aide à la décision
          </li>
          <li>
            <strong>Prof. Dr. Jean-Pierre Tabin</strong>, Haute école de travail
            social et de la santé Lausanne (HETSL) HES-SO et PRN LIVES,
            spécialiste des politiques sociales
          </li>
          <li>
            <strong>Prof. Dr. Boris Fritscher</strong>, Haute école de gestion
            Arc (HES-SO), docteur en Systèmes d’information, spécialiste en
            conception de systèmes d’information
          </li>
          <li>
            <strong>Anne-Cécile Leyvraz</strong>, Haute école de travail social
            et de la santé Lausanne (HETSL) HES-SO, collaboratrice scientifique
            HES, juriste, spécialiste des questions migratoires
          </li>
          <li>
            <strong>Camille Pellaton</strong>, Haute école de gestion Arc
            (HES-SO), assistante de recherche HES, informaticienne de gestion
          </li>
          <li>
            <strong>Prof. Dr. Ulysse Rosselet</strong>, Haute école de gestion
            Arc (HES-SO), docteur en Systèmes d’information, spécialiste
            organisation et traitement de l’information
          </li>
          <li>
            <strong>Cédric Bouteille</strong>, Haute école de gestion Arc
            (HES-SO), assistant de recherche HES, ingénieur en informatique
          </li>
        </ul>

        <h2 class="mt-4">Ancien·nes membres de l'équipe</h2>
        <ul>
          <li>
            <strong>Dr. Slim Bridji</strong>, économiste, expert des dispositifs
            cantonaux des prestations sociales sous condition de ressources
          </li>
          <li>
            <strong>Frédérique Leresche</strong>, doctorante LIVES, experte de
            la question du non-recours
          </li>
          <li>
            <strong>Bernard Letourmy</strong>, concepteur de systèmes
            d’information
          </li>
          <li><strong>Dylan Montandon</strong>, informaticien de gestion</li>
        </ul>

        <div class="bottom-big-deco">
          <img src="@/assets/dedale-horizontal.jpg" />
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'about'
}
</script>

<style>
</style>
