import axios from 'axios'
import i18n from '@/plugins/i18n'

let promise
const addresses = {
  isLoaded: false,
  isLoading: false,
  addresses: [],
  load() {
    if (addresses.isLoading) {
      return promise
    }
    addresses.isLoading = true
    promise = axios.get(
      `/${i18n.locale}/api/adresse/`
    )
      .then(resp => {
        addresses.addresses = resp.data
        addresses.isLoaded = true
        addresses.isLoading = false
        return resp
      })
    return promise
  },
  // TODO: lookup adresses dynamicaly on backend
  findByCanton(canton) {
    const uniqueNames = []
    return addresses.addresses.filter(address => {
      if (address.canton !== canton) return false
      if (uniqueNames.includes(address.nom)) return false
      uniqueNames.push(address.nom)
      return true
    })
  },
  findByCantonAndCommuneAndService(canton, commune, prestation) {
    return addresses.addresses.reduce((result, address) => {
      if (address.canton === canton || address.canton === 'all') {
        if (address.communes.indexOf(commune) > -1 || address.communes.indexOf(-1) === 0) {
          if (address.prestations.indexOf(prestation) > -1 || address.prestations.indexOf('all') === 0) {
            result.push(address)
          }
        }
      }
      return result
    }, [])
  }
}

export default addresses
