<template>
  <footer>
    <v-container class="pt-5">
      <v-row>
        <v-col cols="12" sm="4">
          <v-row>
            <v-col class="text-right vertical-bar" cols="4">
              <a href="https://www.hes-so.ch/"
                ><img src="@/assets/logos/hes-so-mono.svg" alt="logo hes-so"
              /></a>
            </v-col>
            <v-col
              class="d-flex flex-column justify-space-around align-start py-0 pl-4"
            >
              <a
                style="display:block;height:40%;width:100%"
                href="https://www.he-arc.ch/gestion"
              >
                <img style="height: 50px" src="@/assets/logos/he-arc-mono.svg"  alt="logo he-arc"/>
              </a>
              <v-spacer></v-spacer>
              <a style="height:45%;width:100%" href="https://www.hetsl.ch/">
                <img style="height:50px" src="@/assets/logos/HETSL_BLANC.svg" alt="logo HETSL"/>
              </a>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="4">
          <v-row>
            <v-col class="dot-spacer">
              <h3>avec le soutien de</h3>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-spacer></v-spacer>
            <v-col>
              <a
                href="https://www.grstiftung.ch/de/media/portfolio~grs-067-17~.html"
              >
                <img src="@/assets/logos/grs_mono.svg" alt="logo grstiftung"/>
              </a>
            </v-col>
            <v-col>
              <a href="https://www.centre-lives.ch/fr">
                <img src="@/assets/logos/LIVES-mono.svg" alt="logo lives" />
              </a>
            </v-col>
            <v-spacer></v-spacer>
          </v-row>
        </v-col>
        <v-col cols="12" sm="4">
          <v-row>
            <v-col class="dot-spacer">
              <h3>en partenariat avec</h3>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col>
              <a href="https://csp.ch/">
                <img src="@/assets/logos/csp-mono.svg"  alt="logo csp"/>
              </a>
            </v-col>
            <v-col>
              <a href="https://www.caritas.ch/fr/">
                <img src="@/assets/logos/caritas-mono.svg" alt="logo caritas"/>
              </a>
            </v-col>
            <v-col>
              <a href="https://www.proinfirmis.ch/fr.html">
                <img src="@/assets/logos/proinfirmis-mono.svg" alt="logo proinfirmis" />
              </a>
            </v-col>
            <v-col>
              <a href="https://www.prosenectute.ch/fr.html">
                <img src="@/assets/logos/ProSenectute-mono.svg" alt="logo prosenectute" />
              </a>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <language-selector></language-selector>
        </v-col>
        <v-col align-self="end">
          <div class="version">
            <a
              :href="
                `https://github.com/HEG-Arc/jestime-equation-editor/commit/${commitHash}`
              "
              >{{ commitHash.substring(0, 8) }}</a
            >
          </div>
        </v-col>
      </v-row>
    </v-container>
  </footer>
</template>

<script>
import LanguageSelector from '@/components/layout/LanguageSelector'

export default {
  name: 'app-footer',
  data(vm) {
    return {
      // eslint-disable-next-line
      commitHash: COMMITHASH
    }
  },
  components: {
    LanguageSelector
  }
}
</script>

<style>
.version {
  font-size: 10px;
  color: #979797;
  text-align: right;
}

.v-application .version a {
  text-decoration: none;
  color: #979797;
}

footer {
  background-color: #f5f5f5;
  color: #333;
  padding: 8px;
}

footer img {
  max-width: 100%;
}

footer h3 {
  text-align: center;
  color: #225c77;
  background-color: #f5f5f5;
  padding: 0 0.8em;
  display: inline-block;
  font-size: 0.8em;
  font-weight: normal;
  font-family: 'Open Sans', Roboto, 'Helvetica Neue', sans-serif;
}

footer .vertical-bar {
  border-right: 2px solid #225c77;
}

.dot-spacer {
  background-image: url('../../assets/dot.gif');
  background-repeat: repeat-x;
  background-position: center;
  text-align: center;
  margin: 0;
  padding-top: 0;
  padding-bottom: 0;
}
</style>
