import Vue from 'vue'
import Vuex from 'vuex'

import users from '@/store/services/users'
import jestime from '@/store/services/jestime'
import sim from '@/store/services/sim'
import logs from '@/store/services/logs'
import tests from '@/store/services/tests'
import auth from '@/store/modules/auth'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    users,
    auth,
    jestime,
    sim,
    logs,
    tests
  }
})
store.commit('resetSim')

export default store
