<template>
  <v-container>
    <v-row>
      <v-col>
        <survey :survey="survey" />
        <v-btn
          v-if="survey.isCompletedBefore || $store.state.sim.vm && $store.state.sim.vm.surveyDone"
          :to="{ name: 'prestations' }"
          color="primary"
          >Retour aux prestations</v-btn
        >
        <v-btn v-if="survey.isCompletedBefore" @click="restartSurvey"
          >Recommencer le questionnaire</v-btn
        >
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import * as Survey from 'survey-vue'
import 'survey-vue/modern.min.css'
import { vueToObj } from '@/utils/tools'
import axios from 'axios'
import i18n from '@/plugins/i18n'
import { loadSurveyData, saveSurveyData, restartSurvey } from '@/models/survey'

Survey.StylesManager.applyTheme('modern')

function rowsWithValue(params) {
  if (!params && params.length < 2) return false
  var matrixValue = params[0]
  var value = params[1]
  var rowCount = 0
  for (var key in matrixValue) {
    // eslint-disable-next-line
    if (matrixValue[key] == value) rowCount++
  }
  return rowCount
}
Survey.FunctionFactory.Instance.register('rowsWithValue', rowsWithValue)

function uncapitalize(str1) {
  return str1.charAt(0).toLowerCase() + str1.slice(1)
}

const prestations = [
  {
    text: 'L’aide sociale',
    value: 'aide_sociale',
    genre: 'F'
  },
  {
    text: 'Des prestations complémentaires à l’AVS/AI',
    value: 'pc_avs_ai',
    genre: 'F'
  },
  {
    text: 'Un recouvrement de pension alimentaire',
    value: 'pension_alimentaire'
  },
  {
    text: 'Une bourse d’études ou d’apprentissage',
    value: 'bourse',
    genre: 'F'
  },
  {
    text: 'Des subsides à l’assurance maladie',
    value: 'subsides_lamal'
  },
  {
    text: 'D’autres droits sociaux sous condition de ressource',
    value: 'autres'
  }
]

/*
1. Nous autorisez-vous à utiliser, de manière totalement anonyme, les données que vous avez entrées dans jestime ?
Oui/non si oui, récupérer les données, et passer à la question 2,
si non, passer à la question 2 en indiquant : Les données que vous avez entrées dans jestime n’ont pas été enregistrées.
*/

const surveyJson = {
  locale: 'fr',
  pages: [
    {
      name: 'page2',
      elements: [
        {
          type: 'matrix',
          name: 'q2',
          title: 'Avez-vous l’impression de bien connaître vos droits?',
          columns: ['oui', 'plus ou moins', 'non'],
          rows: [
            'À l’aide sociale',
            'Aux prestations complémentaires à l’AVS/AI',
            'Au recouvrement de pensions alimentaires',
            'Aux bourses d’études et d’apprentissage',
            'Aux subsides à l’assurance maladie',
            'À d’autres droits sociaux sous condition de ressource'
          ]
        },
        {
          type: 'comment',
          name: 'q2c',
          title: 'Commentaire'
        }
      ]
    },
    {
      name: 'page3',
      elements: [
        {
          type: 'radiogroup',
          name: 'q3',
          title:
            'De manière générale, avez-vous l’impression d’être bien informé·e sur vos droits sociaux ?',
          isRequired: true,
          choices: ['Oui', 'Non', 'Sans avis']
        }
      ]
    },
    {
      name: 'page4',
      elements: [
        {
          type: 'matrix',
          name: 'q4',
          title:
            'Vous avez indiqué ne pas être bien informé·e de vos droits sociaux. Selon vous, quelles seraient les meilleures solutions à ce problème ? ',
          columns: [
            "d'accord",
            "partiellement d'accord",
            "pas d'accord",
            'sans avis'
          ],
          rows: [
            'L’État devrait faire davantage d’information sociale',
            'Les prestations sociales devraient être versées automatiquement à partir de la déclaration d’impôts',
            'C’est à chacun·e de s’informer sur ses droits sociaux',
            'C’est la tâche des services sociaux privés d’informer sur les droits sociaux',
            'L’école devrait informer les élèves sur leurs droits sociaux',
            'Une information sur les droits sociaux devrait être donnée à toutes les personnes en âge d’AVS',
            'Les employeurs devraient informer leurs salarié·e·s de leurs droits sociaux',
            'Il n’y a pas de solution à ce problème'
          ]
        },
        {
          type: 'comment',
          name: 'q4c',
          title: 'Commentaire'
        }
      ],
      visibleIf: "{q3} = 'Non'"
    },
    {
      name: 'page5',
      elements: [
        {
          type: 'radiogroup',
          name: 'q5',
          title:
            'Trouvez-vous personnellement difficile de demander une prestation sociale ?',
          isRequired: true,
          choices: [
            'Oui',
            'Non',
            'Cela dépend',
            {
              value: 'sans avis',
              text: {
                fr: 'Sans avis'
              }
            }
          ]
        }
      ]
    },
    {
      name: 'page6',
      elements: [
        {
          type: 'matrix',
          name: 'q6',
          title:
            'Vous avez indiqué que demander une prestation sociale peut être difficile pour vous. Pourquoi?',
          columns: [
            "d'accord",
            "partiellement d'accord",
            "pas d'accord",
            'sans avis'
          ],
          rows: [
            'Parce que je considère que c’est un échec personnel',
            'Parce que j’ai peur qu’on le sache',
            'Parce que les prestations sociales sont difficiles à comprendre',
            'Parce que les horaires d’ouverture des guichets des prestations sociales sont trop limités',
            'Parce que je me sens stigmatisé·e lorsque je vais déposer une demande au guichet des prestations sociales',
            'Parce que les prestations sociales ne sont pas adaptées à mon problème',
            'Ce n’est pas toujours difficile, cela dépend de la prestation sociale',
            'Ce n’est pas toujours difficile, cela dépend des jours'
          ]
        },
        {
          type: 'comment',
          name: 'q6c',
          title: 'Commentaire'
        }
      ]
    },
    {
      name: 'page7',
      elements: [
        {
          type: 'matrix',
          name: 'q7',
          title:
            'Avez-vous déjà fait dans le passé des démarches pour obtenir?',
          columns: ['Oui', 'Non', 'Je ne me souviens pas'],
          rows: prestations
        },
        {
          type: 'comment',
          name: 'q7c',
          title: 'Commentaire'
        }
      ]
    }
  ],
  showQuestionNumbers: 'off',
  showProgressBar: 'bottom',
  sendResultOnPageNext: true
}

// generate middle section

prestations.forEach(p => {
  surveyJson.pages.push({
    name: `page_${p.value}`,
    visibleIf: `{q7.${p.value}} = 'Oui'`,
    elements: [
      {
        type: 'html',
        name: `titre_${p.value}`,
        html: `<h2>${p.text}</h2>`
      },
      {
        type: 'radiogroup',
        name: `q9_${p.value}`,
        title: `L'avez-vous obtenu${p.genre === 'F' ? 'e' : ''}?`,
        isRequired: true,
        choices: ['Oui', 'Non', 'Je ne me souviens pas'],
        colCount: 3
      },
      {
        type: 'checkbox',
        name: `q10_${p.value}`,
        visibleIf: `{q9_${p.value}} = 'Non'`,
        title: 'Pour quelle(s) raison(s) ? ',
        choices: [
          'Mon revenu ou ma fortune étaient trop élevés',
          'L’administration a refusé de m’octroyer ces droits pour une autre raison',
          'C’était trop compliqué, je n’ai jamais finalisé la demande',
          'J’ai décidé de me passer de cette prestation sociale',
          'Mon entourage n’était pas d’accord',
          'J’avais peur de perdre mon permis de séjour en Suisse'
        ]
      },
      {
        type: 'comment',
        name: `q10c_${p.value}`,
        visibleIf: `{q9_${p.value}} = 'Non'`,
        title: 'Commentaire'
      },
      {
        type: 'radiogroup',
        name: `q11_${p.value}`,
        visibleIf: `{q9_${p.value}} = 'Oui'`,
        title:
          'Pendant combien de temps avez-vous reçu cette prestation sociale ?',
        choices: [
          'Moins de 3 mois',
          'Entre 3 et 6 mois',
          'Entre 6 mois et une année',
          'Plus d’une année'
        ]
      },
      {
        type: 'comment',
        name: `q11c_${p.value}`,
        visibleIf: `{q9_${p.value}} = 'Oui'`,
        title: 'Commentaire'
      },
      {
        type: 'radiogroup',
        name: `q12_${p.value}`,
        visibleIf: `{q11_${p.value}} notempty`,
        title: 'Aujourd’hui',
        choices: [
          `Je reçois toujours ${uncapitalize(p.text)}`,
          `Je n’ai plus droit à ${uncapitalize(p.text)}`,
          `Je ne reçois plus ${uncapitalize(p.text)}, parce que j’ai décidé d’y renoncer`
        ]
      },
      {
        type: 'comment',
        name: `q12c_${p.value}`,
        visibleIf: `{q11_${p.value}} notempty`,
        title: 'Commentaire'
      },
      {
        type: 'matrix',
        name: `q13_${p.value}`,
        visibleIf: `{q12_${p.value}} notempty`,
        title: `Qu’en avez-vous pensé ? ${p.text}`,
        isRequired: true,
        columns: ['Oui', 'Plus ou moins', 'Non'],
        rows: [
          '...a correspondu à mes besoins',
          '...a été simple à obtenir ',
          '...a été plus généreuse que ce que j’attendais',
          '...a provoqué de nouveaux problèmes'
        ]
      },
      {
        type: 'comment',
        name: `q13c_${p.value}`,
        visibleIf: `{q12_${p.value}} notempty`,
        title: 'Commentaire'
      }
    ]
  })
})

// add end section
surveyJson.pages = surveyJson.pages.concat([
  {
    name: 'page8',
    // only show this page if all(q7 == non)
    visibleIf: `rowsWithValue({q7}, 'Non') = ${prestations.length}`,
    elements: [
      {
        type: 'checkbox',
        name: 'q8',
        title: 'Pour quelles raisons n’avez-vous pas fait de démarches?',
        choices: [
          'Parce que je pensais que je n’avais aucun droit',
          'Parce que je ne savais pas où m’adresser',
          'Parce que je ne veux pas de l’aide de l’État '
        ]
      },
      {
        type: 'comment',
        name: 'q8c',
        title: 'Commentaire'
      }
    ]
  },
  {
    name: 'page9',
    elements: [
      {
        type: 'html',
        name: 'fin_merci',
        html: {
          fr: '<h3>Merci de votre précieuse participation !</h3>'
        }
      },
      {
        type: 'comment',
        name: 'finc',
        title: {
          default:
            ' Merci de votre précieuse participation ! Auriez-vous quelque chose à ajouter concernant jestime ou plus globalement le non-recours aux droits sociaux ?',
          fr:
            'Auriez-vous quelque chose à ajouter concernant jestime ou plus globalement le non-recours aux droits sociaux ?'
        }
      },
      {
        type: 'html',
        name: 'fin',
        html:
          '<p>Si vous désirez nous joindre par rapport à cette recherche, ou si vous êtes d’accord d’être interviewé·e sur la question du non-recours aux droits sociaux, merci de contacter : <a href="mailto:jean-pierre.tabin@hetsl.ch">jean-pierre.tabin@hetsl.ch</a></p>'
      }
    ]
  }
])

export default {
  data() {
    const survey = new Survey.Model(surveyJson)
    survey.onPartialSend.add(saveSurveyData)
    survey.onComplete.add(result => {
      saveSurveyData(this.survey)

      const flatHeader = []
      const flatData = []

      surveyJson.pages.forEach(page => {
        page.elements
          .filter(q => q.type !== 'html')
          .forEach(q => {
            if (q.hasOwnProperty('rows')) {
              q.rows.forEach(r => {
                if (typeof r === 'string') {
                  flatHeader.push(`${q.name} ${r}`)
                  flatData.push(
                    result.data.hasOwnProperty(q.name)
                      ? result.data[q.name][r]
                      : undefined
                  )
                } else {
                  flatHeader.push(`${q.name} ${r.text}`)
                  flatData.push(
                    result.data.hasOwnProperty(q.name)
                      ? result.data[q.name][r.value]
                      : undefined
                  )
                }
              })
            } else if (q.type === 'checkbox') {
              q.choices.forEach(c => {
                flatHeader.push(`${q.name} ${c}`)
                flatData.push(
                  result.data.hasOwnProperty(q.name)
                    ? Number(result.data[q.name].includes(c))
                    : undefined
                )
              })
            } else {
              flatHeader.push(q.name)
              flatData.push(result.data[q.name])
            }
          })
      })
      function escapeQuote(s) {
        return typeof s === 'string' ? s.replace(/"/g, "'") : s
      }
      function toCSV(list) {
        return list.map(s => `"${escapeQuote(s)}"`).join(',')
      }
      this.$store.dispatch('updateSimValue', {
        nom: 'surveyDone',
        value: true
      })
      let simData
      if (this.$store.state.sim.vm) {
        try {
          simData = vueToObj(this.$store.state.sim.vm)
        } catch (e) {

        }
      }
      const payload = {
        session: this.$store.state.sim.vm && this.$store.state.sim.vm.uuid,
        data_json: result.data,
        sim_json: simData,
        csvContent: toCSV(flatData),
        csvHeader: toCSV(flatHeader)
      }
      axios.post(`/${i18n.locale}/api/survey`, payload)
    })

    return {
      survey
    }
  },
  mounted() {
    loadSurveyData(this.survey)
  },
  methods: {
    restartSurvey() {
      restartSurvey(this.survey)
    }
  },
  components: {
    Survey: Survey.Survey
  }
}
</script>

<style>
.sv-question,
.sv-title,
.sv-table__cell--header,
.sv-btn,
.sv-completedpage,
.sv_window {
  font-family: 'Open Sans', Roboto, 'Helvetica Neue', sans-serif;
}

.sv-container-modern__title {
  color: #3795c2;
}

.sv-matrix__text--сhecked,
.sv-progress__bar,
.sv-footer__complete-btn,
.sv-footer__next-btn,
.sv-footer__prev-btn,
.sv-footer__start-btn,
.sv-checkbox--checked .sv-checkbox__svg,
.sv-imagepicker__item--checked .sv-imagepicker__image,
.sv-rating__item--selected .sv-rating__item-text,
.sv-root-modern ::-webkit-scrollbar-thumb {
  background-color: #3795c2;
}

.sv-text:focus,
.sv-item__control:focus + .sv-item__decorator,
.sv-imagepicker__item--checked .sv-imagepicker__image,
.sv-rating__item--selected .sv-rating__item-text,
.sv-comment:focus {
  border-color: #3795c2;
}
</style>
