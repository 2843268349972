<template>
  <div>
    <div v-for="(c, i) in stoppedContent" :key="i">
      <location-selector v-if="c.type === LocationSelector" class="location-selector"></location-selector>
      <assurance-selector v-if="c.type === AssuranceSelector" class="assurance-selector"></assurance-selector>
      <personne-selector v-if="c.type === PersonneSelector" class="personne-selector" :filter="c.filter" :allow-edit="c.allowEdit" :show-externe="c.showExterne" :initial-data="c.initialData"></personne-selector>
      <calcule-dessaisissement v-if="c.type === CalculeDessaisissement" class="calcule-dessaisissement-selector"></calcule-dessaisissement>
      <transition name="highlight">
        <div v-if="c.type === 'group' && isTrue(c.condition)" class="group">
          <h2 v-if="c.label" :class="`level level-${level}`">{{replacePrenom(c.label)}}</h2>
          <content-renderer :content="c.content" :level="level + 1"></content-renderer>
        </div>
      </transition>
      <equation-renderer v-if="c.type === 'input'" :equation="$store.getters.allVariables[c.name]"></equation-renderer>
      <div v-if="c.type === 'html'" v-html="replacePrenom(c.html)"></div>
    </div>
  </div>
</template>

<script>
import PersonneSelector from '@/components/widgets/PersonneSelector'
import LocationSelector from '@/components/widgets/LocationSelector'
import EquationRenderer from '@/components/model/EquationRenderer'
import AssuranceSelector from '@/components/widgets/AssuranceSelector'
import CalculeDessaisissement from '@/components/widgets/CalculeDessaisissement'
import { replacePrenomFromStateInText } from '@/utils/textTransform'
import { isTrue } from '@/utils/scenarios'

export default {
  name: 'content-renderer',
  props: {
    content: {
      type: Array,
      required: true
    },
    level: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      PersonneSelector: PersonneSelector.name,
      LocationSelector: LocationSelector.name,
      AssuranceSelector: AssuranceSelector.name,
      CalculeDessaisissement: CalculeDessaisissement.name
    }
  },
  computed: {
    stoppedContent() {
      const content = []
      for (const c of this.content) {
        content.push(c)
        if (c.type === 'html' && c.html.includes('class="stop"')) {
          break
        }
        if (c.type === 'group' && this.isTrue(c.condition) && this.hasStopped(c.content)) {
          break
        }
      }
      return content
    }
  },
  methods: {
    hasStopped(content) {
      for (const c of content) {
        if (c.type === 'html' && c.html.includes('class="stop"')) {
          return true
        }
        if (c.type === 'group' && this.isTrue(c.condition)) {
          if (this.hasStopped(c.content)) {
            return true
          }
        }
      }
      return false
    },
    isTrue,
    replacePrenom(text) {
      return replacePrenomFromStateInText(this.$store.state, text)
    }
  },
  components: {
    PersonneSelector,
    LocationSelector,
    EquationRenderer,
    AssuranceSelector,
    CalculeDessaisissement
  }
}
</script>

<style>
.level {
  margin-top: 1em;
  margin-bottom: 0.5em;
}
.level-1 {
  font-size: 22px;
}
.level-2, .level-3, .level-4, .level-5 {
  font-size: 18px;
}

.highlight-enter-active {
  background-color: none;
  max-height: 500px;
  overflow: hidden;
  transition: all 1.5s;
}
.highlight-enter {
  background-color: #fff59d;
  max-height: 0;
}
.highlight-leave-active {
  max-height: 500px;
  overflow: hidden;
  transition: all 0.5s;
 }

.highlight-leave-to {
  max-height: 0;
}
</style>
