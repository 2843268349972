import i18n from '@/plugins/i18n'

export function replacePrenomFromStateInText(state, text) {
  if (!text) {
    return ''
  }
  let prenom = 'cette personne'
  if (
    state.sim.vm &&
    state.sim.vm.currentPersonne &&
    state.sim.vm.currentPersonne.prenom
  ) {
    prenom = state.sim.vm.currentPersonne.prenom
  }
  if (i18n.locale === 'fr') {
    // handling of Élision
    const voyelles = 'aeiouy'
    const startWithVoyelle = prenom.length > 0 && voyelles.includes(prenom.toLocaleLowerCase()[0])
    if (startWithVoyelle) {
      text = String(text).replace(/de _prenom_/g, `d'${prenom}`)
    }
  }
  return String(text).replace(/_prenom_/g, prenom)
}
