<template>
  <div class="ask-jestime">
    <div v-if="auth && auth.loggedIn" class="mt-5">
      <create-issue></create-issue>
    </div>
  </div>
</template>

<script>
import CreateIssue from '@/components/CreateIssue'

export default {
  name: 'AskJestime',
  computed: {
    auth() {
      return this.$store.state && this.$store.state.auth
    }
  },
  components: {
    CreateIssue
  }
}
</script>

<style>
.ask-jestime {
  text-align: center;
}
</style>
