<template>
  <v-card id="logdebug">
    <v-card-title>
      Stats
    </v-card-title>
    <v-card-text>
      dependencyCalculated
      <ul>
        <li v-for="dep in $store.state.sim.vm.dependencyCalculated" :key="dep" @click="remove(dep)">{{ dep }}</li>
      </ul>
      <pre>{{ $store.getters.stats | pretty }}</pre>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  filters: {
    pretty(value) {
      return JSON.stringify(value, null, 2).replace(/"/g, '')
    }
  },
  methods: {
    remove(dep) {
      this.$store.commit('removeDependencyCalculated', dep)
    }
  }
}
</script>

<style>
#logdebug {
  position: fixed;
  top: 180px;
  right: 0;
  width: 250px;
  min-height: 200px;
  max-height: 80vh;
  background-color: rgba(255, 0, 0, 0.2);
  z-index: 7;
  overflow-y: auto;
}
#logdebug:hover {
  background-color: rgb(250, 198, 198);
}
</style>
