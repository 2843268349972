<template>
  <v-btn :href="issue" rel="noopener" target="_blank" color="green" :outlined="outlined" tabindex="-1">New issue</v-btn>
</template>

<script>
export default {
  name: 'CreateIssue',
  props: ['test', 'outlined'],
  computed: {
    issue() {
      let canton = ''
      if (
        this.$store.state.jestime.fiche.pk &&
        this.$store.state.jestime.cantons[
          this.$store.state.jestime.scenario.canton
        ]
      ) {
        canton = this.$store.state.jestime.cantons[
          this.$store.state.jestime.scenario.canton
        ].nom
      } else if (
        this.$store.state.sim.vm &&
        this.$store.state.sim.vm.lieuLogement
      ) {
        canton = Object.values(this.$store.state.jestime.cantons).find(
          c => c.slug === this.$store.state.sim.vm.lieuLogement.canton
        ).nom
      }
      let prestation = 'Formulaire principal'
      if (
        this.$store.state.jestime.scenario.prestation &&
        this.$store.state.jestime.prestations[
          this.$store.state.jestime.scenario.prestation
        ]
      ) {
        prestation = this.$store.state.jestime.prestations[
          this.$store.state.jestime.scenario.prestation
        ].nom
      }

      let body = ''
      if (this.test && this.test.pk) {
        body += `Cas test #${this.test.pk} ${this.test.nom}`
      }
      body += `\n\n\n\n[Info Techniques]\nroute: ${this.$route.fullPath}\nfiche: ${this.$store.state.jestime.fiche.pk}\nscenario: ${this.$store.state.jestime.scenario.pk}\nsession: ${this.$store.state.sim.vm.uuid}`
      body = encodeURIComponent(body)

      return `https://github.com/HEG-Arc/jestime-feedbacks/issues/new?labels=${canton}&title=${prestation}&body=${body}`
    }
  }
}
</script>

<style>
</style>
