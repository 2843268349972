<i18n>
{
  "fr": {
    "more_info": "Pour davantage d'informations:",
    "official_page": "site officiel"
  },
  "de": {
    "more_info": "Für weitere Informationen:",
    "official_page": "offizielle Seite"
  }
}
</i18n>
<template>
  <div class="prestation-item" :class="{'details': details}">
    <div @click="details = !details" class="prestation-item-header">
      <v-icon class="prestation-item-icon">{{ prestation.icon_class }}</v-icon>
      <h3>{{ prestation.nom }}&nbsp;<v-icon>fas {{details ? 'fa-sort-up' : 'fa-sort-down' }}</v-icon></h3>
    </div>
    <div v-if="details">
      <p>{{ prestation.description }}</p>
      <p v-if="prestation.lien_canton && $store.state.jestime.features.lien_canton">
        {{ $t('more_info') }}
        <a
          :href="prestation.lien_canton"
          rel="noopener"
          target="_blank"
          class="open-external"
          >{{ $t('official_page') }}
          <v-icon x-small>fas fa-external-link-alt</v-icon></a
        >
      </p>
      <link-calculateur :prestation="prestation.pk"></link-calculateur>
    </div>
  </div>
</template>
<script>
import LinkCalculateur from '@/components/LinkCalculateur'

export default {
  name: 'HomePrestationItem',
  props: ['prestation'],
  data() {
    return {
      details: false
    }
  },
  components: {
    LinkCalculateur
  }
}
</script>

<style>
.prestation-item {
  background-color: #f5f5f5;
  border-radius: 16px;
  padding: 18px;
  min-height: 180px;
}

.prestation-item-header {
  text-align: center;
  cursor: pointer;
}
.prestation-item.details .prestation-item-header {
  margin-bottom: 20px;
}
.prestation-item-icon svg {
  color: #3795c2;
  height: 50px;
  font-size: 50px;
  width: auto;
  margin-bottom: 8px;
}

.prestation-item-icon svg:before {
  vertical-align: text-bottom;
}

.prestation-item-header h3 .v-icon {
  color: #3795c2;
  vertical-align: top;
}

.prestation-item.details .prestation-item-header h3 .v-icon {
  vertical-align: middle;
}
</style>
