<template>
  <v-container id="prestations-container">
    <v-dialog v-model="showSurveyDialog" max-width="600">
      <v-card>
        <v-card-title class="text-h5">Sondage</v-card-title>

        <v-card-text>
          <p>
            Vous venez d'utiliser jestime. Nous espérons vivement que cet
            estimateur vous a été utile ! Auriez-vous quelques minutes à nous
            consacrer pour répondre à une dizaine de questions dans le cadre
            d'une recherche sur le non-recours aux droits sociaux que nous
            menons pour le pôle de recherche national Lives
            <em
              >« surmonter la vulnérabilité, perspective du parcours de vie
              »</em
            >
            ?
          </p>
          <p>Cela nous serait très utile !</p>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="primary" depressed :to="{ name: 'survey' }"
            >Allez au questionnaire</v-btn
          >
          <v-btn depressed @click="dismissSurveyDialog">Non, merci</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row class="result-direct-access" v-if="jestime.lookupLoaded">
      <v-col
        cols="12"
        md="6"
        lg="4"
        class="pa-5"
        v-for="fiche in sortedFiches"
        :key="fiche.pk"
      >
        <prestation-summary :fiche="fiche"></prestation-summary>
      </v-col>
    </v-row>
    <v-row v-if="showSurveyAlert" class="mt-12 d-print-none">
      <v-col cols="12" md="8" offset-md="2">
        <v-alert border="left" type="warning">
          <p>
            Vous venez d'utiliser jestime. Nous espérons vivement que cet
            estimateur vous a été utile ! Auriez-vous quelques minutes à nous
            consacrer pour répondre à une dizaine de questions dans le cadre
            d'une recherche sur le non-recours aux droits sociaux que nous
            menons pour le pôle de recherche national Lives
            <em
              >« surmonter la vulnérabilité, perspective du parcours de vie
              »</em
            >
            ?
          </p>
          <p>Cela nous serait très utile !</p>
          <p>
            <v-btn depressed color="primary" :to="{ name: 'survey' }"
              >Allez au questionnaire</v-btn
            >
          </p>
        </v-alert>
      </v-col>
    </v-row>
    <v-row class="box-back-to-main d-print-none">
      <v-col class="pr-5">
        <div class="big-title primary-color">Vous et votre foyer</div>
      </v-col>
      <v-col class="px-5">
        Souhaitez-vous changer vos données personnelles et relancer le calcul ?
      </v-col>
      <v-col class="pl-5">
          <v-btn block depressed class="ma-2" :to="{ name: 'jestime' }"
              >Modifier mes données</v-btn>
          <v-btn v-if="$store.state.sim.vm && $store.state.sim.vm.dependencyCalculated.includes('RDU')" block depressed class="ma-2" :to="{name:'jestime', query: {canton: $store.state.jestime.fiches.length > 0 ? $store.state.jestime.fiches[0].canton : '', prestation: 4}}"
              >Modifier RDU</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div class="helpBox">
          <p>
            Le résultat du calcul indicatif des prestations qui pourraient vous
            être octroyées dépend des données que vous avez saisies. Il s'agit
            d'un calcul simplifié qui ne saurait prendre en compte la
            multiplicité des situations personnelles.
          </p>
        </div>

        <div class="bottom-big-deco">
          <img src="@/assets/dedale-horizontal.jpg" />
        </div>
      </v-col>
    </v-row>
    <log-debug v-if="$store.state.sim.vm && $store.state.sim.vm.debug"></log-debug>
  </v-container>
</template>

<script>
import PrestationSummary from '@/components/PrestationSummary'
import { available, isCalculated } from '@/models/fiche'
import LogDebug from '@/components/LogDebug'

const order = {
  default: [
    'avances-pensions-alimentaires',
    'prestations-complementaires-a-lavs-ou-a-lai',
    'subside-lamal',
    'bourse',
    'prestations-complementaires-pour-familles-pc-famil',
    'rente-pont',
    'aide-sociale',
    'rdu'
  ],
  GE: [
    'subside-lamal',
    'avances-pensions-alimentaires',
    'prestations-complementaires-a-lavs-ou-a-lai',
    'prestations-complementaires-avsai-cantonales',
    'bourse',
    'prestations-complementaires-pour-familles-pc-famil',
    'aide-sociale',
    'rdu'
  ]
}

export default {
  name: 'prestations',
  computed: {
    jestime() {
      return this.$store.state && this.$store.state.jestime
    },
    sortedFiches() {
      return this.jestime.fiches.slice(0).sort((a, b) => {
        const sortAvailable =
          available(b) - available(a)
        if (sortAvailable === 0) {
          return this.indexOfFiche(a) - this.indexOfFiche(b)
        }
        return sortAvailable
      })
    },
    anyCalculated() {
      return this.jestime.fiches.some(fiche => isCalculated(fiche))
    },
    allCalculated() {
      const availableFiches = this.jestime.fiches.filter(fiche =>
        available(fiche)
      )
      if (availableFiches.length === 0) return false
      return availableFiches.every(fiche => isCalculated(fiche))
    },
    showSurveyAlert() {
      return this.$store.state.sim.vm && !this.$store.state.sim.vm.surveyDone && this.anyCalculated
    },
    showSurveyDialog() {
      if (
        this.$store.state.sim.vm && (this.$store.state.sim.vm.surveyDone ||
        this.$store.state.sim.vm.dismissSurveyDialog)
      ) {
        return false
      }
      return this.allCalculated
    }
  },
  methods: {
    indexOfFiche(fiche) {
      const prestationSlug =
        this.jestime.prestations[fiche.prestation] &&
        this.jestime.prestations[fiche.prestation].slug

      const canton = this.jestime.cantons[fiche.canton] && this.jestime.cantons[fiche.canton].slug
      const currentOrder = order.hasOwnProperty(canton) ? order[canton] : order.default
      return currentOrder.indexOf(prestationSlug)
    },
    dismissSurveyDialog() {
      this.$store.dispatch('updateSimValue', {
        nom: 'dismissSurveyDialog',
        value: true
      })
    }
  },
  mounted() {
    // comming from main scenario
    if (this.jestime.scenario.prestation === this.jestime.idMainPrestation) {
      this.$store.dispatch('updateStats')
    }
    // reset
    this.$store.commit('setScenarioParent', [])
  },
  watch: {
    '$store.state.sim.vm'(vm) {
      if (
        vm &&
        vm.lieuLogement &&
        vm.lieuLogement.canton &&
        vm.fichesCount === 0
      ) {
        console.debug('getFiches - Prestations')
        this.$store.dispatch('getFiches', vm.lieuLogement)
      }
    }
  },
  components: {
    PrestationSummary,
    LogDebug
  }
}
</script>

<style>
.helpBox {
  background-color: rgba(241, 241, 241, 0.95);
  padding: 24px;
  font-size: 16px;
}

.bottom-big-deco {
  text-align: center;
  margin: 3em 0 1em 0;
}
.bottom-big-deco img {
  width: 70%;
}

.box-back-to-main {
  border-top: 1px solid #B4B4B4;
  margin-top: 6em;
  margin-left: 0;
  margin-right: 0;
  padding-top: 2em;
  font-size: 12px;
  align-items: center;
}

.box-back-to-main .big-title {
  text-align: left;
}

.box-back-to-main .v-btn {
  color: white;
  background-color: #959595 !important;
}

@media print {
  .bottom-big-deco {
    display: none;
  }
  .helpBox {
    margin-top: 10pt;
    background-color: transparent;
    color: black;
    font-size: 14pt;
    text-justify: inter-word !important;
    border-style: dotted;
    border-color: red !important;
    border-radius: 15px;
    border-width: 5px !important;
    padding: 5px !important;
    text-align: justify;
    text-align-last: center;
    vertical-align: center;
    margin-bottom: 0 !important;
    break-inside: avoid;
  }
  .helpBox p {
    margin-bottom: 0 !important;
    break-inside: avoid;
  }
  #prestations-container{
    margin: 0 !important;
    padding-top: 0 !important;
    vertical-align: top;
  }
  .result-direct-access{
    break-inside: auto;
    padding: 0;
    display: block !important;
  }
  .pa-5.col-md-6.col-lg-4.col-12{
    padding: 5pt !important;
    break-inside: avoid !important;
  }
}
</style>
