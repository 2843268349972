import 'core-js/stable'
import 'regenerator-runtime/runtime'
import '@/utils/svg-classlist-polyfill'
import Vue from 'vue'
import vuetify from './plugins/vuetify'
import store from '@/store'
import router from '@/router'
import i18n from './plugins/i18n'
import VueAnalytics from 'vue-analytics'
import * as Sentry from "@sentry/vue"
import { BrowserTracing } from "@sentry/tracing"
import App from '@/App.vue'
import './registerServiceWorker'
import CHF from '@/filters/chf'

import axios from 'axios'
axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'X-CSRFToken'

Vue.config.productionTip = false

// Sentry for logging frontend errors
if (process.env.NODE_ENV === 'production' && process.env.VUE_APP_SENTRY_PUBLIC_DSN) {
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_PUBLIC_DSN,
    // eslint-disable-next-line
    release: COMMITHASH,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
    ],
  })
}

// more info: https://github.com/MatteoGabriele/vue-analytics
if (process.env.VUE_APP_GOOGLE_ANALYTICS) {
  Vue.use(VueAnalytics, {
    id: process.env.VUE_APP_GOOGLE_ANALYTICS,
    router
  })
}

Vue.filter('CHF', CHF)

const app = new Vue({
  vuetify,
  router,
  store,
  i18n,
  data() {
    return {
      loading: false
    }
  },
  render: h => h(App)
}).$mount('#app')
Sentry.attachErrorHandler(app, { logErrors: true });

// show loading progress bar in app if waiting longer than 300ms
let loadingTimer
router.beforeEach((to, from, next) => {
  loadingTimer = setTimeout(() => {
    app.loading = true
  }, 300)
  next()
})

router.afterEach((to, from) => {
  if (loadingTimer) {
    clearTimeout(loadingTimer)
  }
  app.loading = false
})
