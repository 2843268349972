<template>
  <div v-if="sim.currentPersonne">
    <v-autocomplete
      v-model="assuranceMaladie"
      :items="insurersFiltered"
      :loading="isLoadingInsurers"
      :search-input.sync="searchInsurer"
      label="Assurance"
      placeholder="Cherchez le nom de votre assurance"
      return-object
      data-nw="assuranceMaladie"
      no-data-text="Entrez une valeur pour commencer la recherche"
    ></v-autocomplete>
    <v-autocomplete
      v-model="assuranceMaladieModel"
      :items="plans"
      :loading="isLoadingPlans"
      label="Modèle"
      return-object
      data-nw="assuranceMaladieModel"
    ></v-autocomplete>

    <equation-renderer :equation="widgetFranchise"></equation-renderer>
    <equation-renderer :equation="widgetCoverage"></equation-renderer>

    <v-alert
      v-if="sim.currentPersonne && !isNaN(sim.currentPersonne.montant_prime_franchise_actuelle) && sim.currentPersonne.montant_prime_franchise_actuelle === 0"
      text
      color="orange"
      type="error"
    >
    La franchise donnée n'est pas proposée.
    </v-alert>

    <p>
      <a
        href="https://www.priminfo.admin.ch/fr/praemien"
        rel="noopener"
        target="_blank"
      >Source: Calculateur des primes OFSP</a>
    </p>
  </div>
</template>

<script>
import axios from 'axios'
import i18n from '@/plugins/i18n'
import EquationRenderer from '@/components/model/EquationRenderer'

export default {
  name: 'AssuranceSelector',
  data() {
    return {
      widgetFranchise: {
        nom: 'franchise',
        type: 'I',
        scope: 'P',
        input_widget: 'select',
        equation: '',
        libelle: 'Franchise'
      },
      widgetCoverage: {
        nom: 'assurance_accident',
        type: 'I',
        scope: 'P',
        input_widget: 'checkbox',
        libelle: 'Couverture accident'
      },
      isLoadingInsurers: false,
      isLoadingPlans: false,
      insurers: [],
      plans: [],
      searchInsurer: null,
      location: null
    }
  },
  computed: {
    sim() {
      return this.$store.state.sim.vm
    },
    lieuLogement() {
      return this.sim.lieuLogement
    },
    personneIndex() {
      return this.sim.personnes.indexOf(
        this.$store.state.sim.vm.currentPersonne
      )
    },
    rates() {
      if (this.sim.currentPersonne && this.sim.currentPersonne.age < 18) {
        return [0, 100, 200, 300, 400, 500, 600]
      } else {
        return [300, 500, 1000, 1500, 2000, 2500]
      }
    },
    insurersFiltered() {
      if (!this.searchInsurer) {
        if (this.assuranceMaladie) {
          return this.insurers.filter(a => a.value === this.assuranceMaladie.value)
        }
        return []
      }
      return this.insurers.filter(a => a.text.toLowerCase().includes(this.searchInsurer.toLowerCase()))
    },
    assuranceMaladie: {
      get() {
        return (
          this.sim.currentPersonne && this.sim.currentPersonne.assurance_maladie
        )
      },
      set(value) {
        this.$store.dispatch('updateSimValue', {
          nom: 'assurance_maladie',
          value,
          personneIndex: this.personneIndex
        })
        if (value) {
          this.loadPlans(this.location, value.value)
        }
      }
    },
    assuranceMaladieModel: {
      get() {
        return (
          this.sim.currentPersonne &&
          this.sim.currentPersonne.assurance_maladie_model
        )
      },
      set(value) {
        this.$store.dispatch('updateSimValue', {
          nom: 'assurance_maladie_model',
          value,
          personneIndex: this.personneIndex
        })
      }
    },
    yob() {
      return this.sim.currentPersonne
        ? new Date().getFullYear() - this.sim.currentPersonne.age
        : undefined
    },
    franchise() {
      return this.sim.currentPersonne
        ? this.sim.currentPersonne.franchise
        : undefined
    },
    coverage() {
      return this.sim.currentPersonne
        ? this.sim.currentPersonne.assurance_accident
          ? 1
          : 0
        : undefined
    },
    rateDep() {
      return [
        this.location,
        this.assuranceMaladie,
        this.assuranceMaladieModel,
        this.yob,
        this.franchise,
        this.coverage
      ]
    }
  },
  methods: {
    loadInsurers() {
      if (this.isLoadingInsurers) return

      this.isLoadingInsurers = true
      this.insurers = []

      // Lazily load input items
      axios.get(`/${i18n.locale}/api/primeinfo/insurers?location=${this.location}`)
        .then(res => {
          this.insurers = res.data
          if (this.assuranceMaladie) {
            this.loadPlans(this.location, this.assuranceMaladie.value)
          }
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => (this.isLoadingInsurers = false))
    },
    loadPlans(location, insurer) {
      if (this.isLoadingPlans) return

      this.isLoadingPlans = true
      // Lazily load input items
      this.plans = []
      axios.get(`/${i18n.locale}/api/primeinfo/plans?location=${location}&insurer=${insurer}`)
        .then(res => {
          this.plans = res.data
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => (this.isLoadingPlans = false))
    },
    loadRate() {
      const personneIndex = this.personneIndex
      const franchise = this.franchise
      if (
        !this.location ||
        !this.assuranceMaladie ||
        !this.assuranceMaladieModel ||
        !this.yob
      ) {
        return
      }
      axios.get(
        `/${i18n.locale}/api/primeinfo/rate?location=${this.location}&insurer_name=${
          this.assuranceMaladie.text
        }&plan_name=${this.assuranceMaladieModel.text}&yob=${
          this.yob
        }&franchise=${this.franchise}&coverage=${this.coverage}`
      )
        .then(res => {
          this.$store.dispatch('updateSimValue', {
            nom: 'montant_prime_franchise_min',
            value: res.data[0],
            personneIndex
          })
          this.$store.dispatch('updateSimValue', {
            nom: 'montant_prime_franchise_actuelle',
            value: res.data[this.rates.indexOf(franchise)],
            personneIndex
          })
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => (this.isLoadingPlans = false))
    }
  },
  watch: {
    rates: {
      handler(e) {
        this.widgetFranchise.equation = JSON.stringify(
          this.rates.map(r => ({ value: r, text: `CHF ${r}` }))
        )
      },
      immediate: true
    },
    lieuLogement: {
      handler(e) {
        if (!e || !e.npa || !e.commune) {
          return
        }
        axios.get(`/${i18n.locale}/api/primeinfo/location?q=${e.npa} ${e.commune}`)
          .then(res => {
            this.location = res.data
            return this.loadInsurers()
          })
          .catch(err => {
            console.log(err)
          })
      },
      immediate: true
    },
    assuranceMaladie() {
      if (this.assuranceMaladie) {
        this.loadPlans(this.location, this.assuranceMaladie.value)
      }
    },
    rateDep: {
      handler() {
        this.loadRate()
      },
      deep: true
    }
  },
  components: {
    EquationRenderer
  }
}
</script>

<style>
</style>
