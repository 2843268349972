<i18n>
{
  "fr": {
    "add_personne": "Ajouter une personne"
  },
  "de": {
    "add_personne": "Person hinzufügen"
  }
}
</i18n>
<template>
  <div class="d-flex flex-wrap">
    <button
      class="avatar-box"
      :class="{ active: p === personne }"
      v-for="(p, i) in personnes"
      @click="setCurrentPerson(p)"
      v-bind:key="i"
    >
      <div class="avatar-box-inner">
        <v-icon>fa fa-user circle</v-icon>
        <span>{{ p.prenom }}</span>

        <v-btn
          text
          icon
          class="close"
          @click.stop="removePerson(p)"
          v-if="allowEdit && (showExterne || $store.state.sim.vm.personnes.indexOf(p) !== 0)"
        >
          <v-icon>fa fa-times-circle</v-icon>
        </v-btn>
      </div>
      <div class="arrow-down" v-if="p === personne"></div>
    </button>
    <div class="avatar-box" v-if="allowEdit">
      <v-btn
        text
        height="150"
        class="avatar-box-inner add"
        @click="addPerson()"
      >
        <div class="d-flex flex-column">
          <v-icon>fa fa-plus-circle</v-icon>
          <span>{{ $t('add_personne') }}</span>
        </div>
      </v-btn>
    </div>
  </div>
</template>

<script>
import { getPersonnesFromPersonneSelector } from '@/utils/scenarios'
export default {
  props: {
    filter: String,
    allowEdit: {
      type: Boolean,
      default: false
    },
    showExterne: {
      type: Boolean,
      default: false
    },
    initialData: {
      type: String,
      default: '{}'
    }
  },
  name: 'PersonneSelector',
  mounted() {
    if (
      this.personnes.indexOf(this.personne) === -1 &&
      this.personnes.length > 0
    ) {
      this.setCurrentPerson(this.personnes[0])
    }
  },
  computed: {
    personnes() {
      return getPersonnesFromPersonneSelector(this)
    },
    personne() {
      return this.$store.state.sim.vm.currentPersonne
    }
  },
  methods: {
    addPerson() {
      let personne = {}
      try {
        personne = JSON.parse(this.initialData)
      } catch (e) {
      }
      if (this.showExterne) {
        this.$store.commit('updateSimAddPersonExterne', personne)
      } else {
        this.$store.commit('updateSimAddPerson', personne)
      }
    },
    removePerson(personne) {
      if (confirm(`Supprimer ${personne.prenom || 'cette personne'}?`)) {
        const indexToDelete = this.personnes.indexOf(personne)
        const selectedIndex = this.personnes.indexOf(this.personne)
        if (indexToDelete === selectedIndex) {
          if (indexToDelete > 0) {
            console.log(this.personnes[indexToDelete - 1])
            this.setCurrentPerson(this.personnes[indexToDelete - 1])
          } else if (this.personnes.length > 1) {
            this.setCurrentPerson(this.personnes[indexToDelete + 1])
          } else {
            this.setCurrentPerson(null)
          }
        }
        this.$store.commit('updateSimRemovePerson', personne)
      }
    },
    setCurrentPerson(personne) {
      this.$store.dispatch('updateSimValue', {
        nom: 'currentPersonne',
        value: personne
      })
    }
  }
}
</script>

<style>
.avatar-box {
  position: relative;
  margin: 8px;
  text-align: center;
  display: flex;
  flex-direction: column;
  border: 0;
}

.avatar-box .fa.circle {
  font-size: 60px;
  color: #3795c2;
  height: 60px;
  line-height: 70px;
  width: 60px;
  text-align: center;
  margin: 8px auto;
  display: block;
  border: 5px solid #3795c2;
  border-radius: 50%;
  box-sizing: content-box;
  overflow: hidden;
}
.avatar-box .close {
  position: absolute;
  font-size: 16px;
  top: -4px;
  right: -4px;
  margin: 0;
}

.avatar-box.active .avatar-box-inner {
  color: #fff;
  background-color: #3795c2;
}
.avatar-box.active .fa.circle,
.avatar-box.active .close .fa {
  color: #fff;
  border-color: #fff;
}

.avatar-box-inner {
  box-sizing: border-box;
  height: 150px;
  width: 140px;
  padding: 8px;
  margin: 0;
  color: #3795c2;
  background-color: #f5f5f5;
  position: relative;
}

.avatar-box-inner span {
  font-weight: bold;
}

.avatar-box-inner.add {
  text-transform: none;
  white-space: normal;
  line-height: normal;
}

.avatar-box-inner.add .v-btn__content {
  white-space: normal;
  color: #3795c2;
}
.avatar-box-inner.add .fa {
  font-size: 60px;
  color: #3795c2;
  height: 60px;
  width: 60px;
  text-align: center;
  margin: 8px auto;
  display: block;
}

.avatar-box-inner.add .fa::before {
  vertical-align: text-bottom;
}

.arrow-down {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 25px 70px 0 70px;
  border-color: #3795c2 transparent transparent transparent;
}

.avatar-box:focus {
  outline: 0;
}

.avatar-box:focus .avatar-box-inner::before {
  opacity: 0.12;
}
.avatar-box .avatar-box-inner:before {
  background-color: currentColor;
}
.avatar-box .avatar-box-inner:before {
  border-radius: inherit;
  bottom: 0;
  color: inherit;
  content: '';
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transition: opacity 0.2s cubic-bezier(0.4, 0, 0.6, 1);
  transition: opacity 0.2s cubic-bezier(0.4, 0, 0.6, 1);
}
</style>
