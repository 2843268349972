<template>
  <v-container>
    <v-row class="estimation-table-box">
      <v-col>
        <h2>Adresses utiles</h2>
        <p v-if="addresses.isLoading">Chargement...</p>
        <v-expansion-panels :accordion="true" :flat="true" :hover="true" v-model="selected">
          <v-expansion-panel :class="{'expansion-panel-with-content': addresses.findByCanton(c.slug).length > 0}" v-for="c in cantons" :key="c.pk">
            <v-expansion-panel-header v-if="addresses.findByCanton(c.slug).length > 0">{{c.nom}}</v-expansion-panel-header>
            <v-expansion-panel-content v-if="addresses.findByCanton(c.slug).length > 0">
              <v-row>
                <v-col cols="12" sm="6" md="4" v-for="(address, i) in addresses.findByCanton(c.slug)" :key="`${c.slug}_${i}`">
                  <app-address class="address-override" :address="address"></app-address>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
         Les adresses utiles sont tirées du répertoire fourni par le <a href="https://www.guidesocial.ch/" rel="noopener" target="_blank">Guide social romand</a>.
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Address from '@/components/Address'
import addresses from '@/services/addresses'
export default {
  name: 'addresses',
  data() {
    return {
      selected: -1
    }
  },
  mounted() {
    addresses.load().then(() => this.$forceUpdate())
  },
  computed: {
    addresses() {
      return addresses
    },
    canton() {
      return (
        this.$store.state.sim.vm &&
        this.$store.state.sim.vm.lieuLogement &&
        this.$store.state.sim.vm.lieuLogement.canton
      )
    },
    cantons() {
      const cantons = Object.values(this.$store.state.jestime.cantons)
      cantons.sort((a, b) => a.nom.localeCompare(b.nom))
      return cantons
    },
    currentCantonIndex() {
      if (this.canton) {
        return this.cantons
          .map(c => c.slug)
          .indexOf(this.canton)
      }
      return -1
    }
  },
  watch: {
    currentCantonIndex: {
      handler() {
        this.selected = this.currentCantonIndex
      },
      immediate: true
    }
  },
  components: {
    'app-address': Address
  }
}
</script>

<style>
.expansion-panel-with-content {
  border-top: 1px solid rgba(0, 0, 0, 0.87);
  border-radius: unset;
}
.v-expansion-panel-header {
  padding-left: 0;
  padding-right: 0;
}
.v-expansion-panel-content__wrap {
  padding-left: 0;
}
.address-override {
  padding: 0;
}
</style>
