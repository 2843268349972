import store from '@/store'
import { PREFIX_OVERRIDE_CHECK } from '@/store/services/sim'

export function isCalculated(fiche) {
  return (
    store.state.sim.vm &&
    store.state.sim.vm.dependencyCalculated.includes(fiche.nom)
  )
}

export function prestationSlug(fiche) {
  return (
    store.state.jestime.prestations[fiche.prestation] &&
    store.state.jestime.prestations[fiche.prestation].slug.replace(/-/g, '_')
  )
}

export function available(fiche) {
  const pSlug = prestationSlug(fiche)
  if (
    store.state.sim.vm &&
    store.state.sim.vm.hasOwnProperty(`condition_${pSlug}`) &&
    typeof store.state.sim.vm[`condition_${pSlug}`] === 'boolean'
  ) {
    return store.state.sim.vm[`condition_${pSlug}`]
  }
  return false
}

export function total(fiche) {
  const pSlug = prestationSlug(fiche)
  if (store.state.sim.vm && store.state.sim.vm[`total_${pSlug}`] !== undefined && !isNaN(store.state.sim.vm[`total_${pSlug}`])) {
    return store.state.sim.vm[`total_${pSlug}`]
  }
  return -1
}

function _overridden(fiche, type) {
  if (!store.state.jestime.fichesOverrides[fiche.pk]) {
    return false
  }
  return store.state.jestime.fichesOverrides[fiche.pk][type](equation => {
    if (equation.scope === 'P') {
      return store.state.sim.vm.personnes[type](p => p[`${PREFIX_OVERRIDE_CHECK}_${equation.nom}`])
    } else {
      return !!store.state.sim.vm[`${PREFIX_OVERRIDE_CHECK}_${equation.nom}`]
    }
  })
}

export function allOverridden(fiche) {
  return _overridden(fiche, 'every')
}

export function anyOverridden(fiche) {
  return _overridden(fiche, 'some')
}
