import Vue from 'vue'
/* A la carte */
import Vuetify, { VTextField } from 'vuetify/lib'
import VCurrencyField from 'v-currency-field'

import fr from 'vuetify/es5/locale/fr'

import AideSocialIcon from '@/components/icons/AideSocialIcon'
import AvsAiIcon from '@/components/icons/AvsAiIcon'
import BoursesIcon from '@/components/icons/BoursesIcon'
import MaterniteIcon from '@/components/icons/MaterniteIcon'
import PensionsAlimIcon from '@/components/icons/PensionsAlimIcon'
import PcIcon from '@/components/icons/PcIcon'
import SubsidesMaladieIcon from '@/components/icons/SubsidesMaladieIcon'
import RentePont from '@/components/icons/RentePont'

Vue.component('v-text-field', VTextField)
Vue.use(VCurrencyField, {
  locale: 'de-CH',
  decimalLength: 0,
  defaultValue: null,
  autoDecimalMode: true
})

Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    iconfont: 'fa',
    values: {
      radioOn: 'fas fa-circle',
      'aide-social': {
        component: AideSocialIcon
      },
      'avs-ai': {
        component: AvsAiIcon
      },
      bourses: {
        component: BoursesIcon
      },
      maternite: {
        component: MaterniteIcon
      },
      'pensions-alim': {
        component: PensionsAlimIcon
      },
      pc: {
        component: PcIcon
      },
      'subsides-maladie': {
        component: SubsidesMaladieIcon
      },
      'rente-pont': {
        component: RentePont
      }
    }
  },
  theme: {
    themes: {
      light: {
        primary: '#3795c2',
        secondary: '#225c77',
        accent: '#225c77',
        error: '#f44336',
        warning: '#ff9800',
        info: '#2196f3',
        success: '#4caf50'
      }
    }
  },
  lang: {
    locales: { fr },
    current: 'fr'
  }
})
