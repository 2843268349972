import { vueToObj } from '@/utils/tools'
import axios from 'axios'
import i18n from '@/plugins/i18n'
import { vmInit } from './sim'
import { available, isCalculated, total, prestationSlug } from '@/models/fiche'

let timeout

const state = {
  eventsQueue: [],
  timeout: undefined
}

const getters = {
  stats(state, getters, rootState) {
    const main = rootState.sim.vm.personnes.length > 0 ? rootState.sim.vm.personnes[0] : {}
    const stat = {
      uuid: rootState.sim.vm.uuid,
      canton: rootState.sim.vm.lieuLogement?.canton,
      npa: rootState.sim.vm.lieuLogement?.npa,
      nb_personnes: rootState.sim.vm.personnes.length,
      nb_femmes: rootState.sim.vm.personnes.filter(p => p.sexe === 'femme').length,
      nb_hommes: rootState.sim.vm.personnes.filter(p => p.sexe === 'homme').length,
      nb_adultes: rootState.sim.vm.personnes.filter(p => p.age >= 18).length,
      nb_enfants: rootState.sim.vm.personnes.filter(p => p.age < 18).length,
      nb_etudiants: rootState.sim.vm.personnes.filter(p => p.est_etudiant).length,
      nb_avs: rootState.sim.vm.personnes.filter(p => p.est_etudiant).length,
      etat_civil: main.etat_civil,
      age: main.age,
      revenu: main.total_revenue_di,
      fortune: main.fortune_nette_di,
      loyer: main.loyer_mensuel_menage,
      fiches: rootState.jestime.fiches.map(f => {
        let montant = 0
        const calcule = isCalculated(f)
        const disponible = available(f)
        let status = 'non disponible'
        if (disponible) {
          status = 'non calculée'
          if (calcule) {
            montant = total(f)
            if (montant === -1) {
              status = 'erreur'
            } else if (montant > 0) {
              status = 'calculée avec droit'
            } else {
              status = 'calculée sans droit'
            }
          }
        }
        return {
          pk: f.pk,
          prestation_slug: prestationSlug(f),
          disponible,
          calcule,
          droit: calcule && montant > 0,
          montant,
          status
        }
      })
    }
    stat.montant_total = 0
    // stat.duree_total = 0
    stat.nb_disponible = 0
    stat.nb_calcule = 0
    stat.nb_droit = 0
    stat.fiches.forEach(f => {
      stat.montant_total += f.montant > 0 ? f.montant : 0
      // stat.duree_total += f.duree
      stat.nb_disponible += f.disponible
      stat.nb_calcule += f.calcule
      stat.nb_droit += f.droit
    })
    return stat
  }
}

const mutations = {
  addToEventQueue(state, payload) {
    state.eventsQueue.push(payload)
  },
  flushEventQueue(state, payload) {
    const data = state.eventsQueue
    if (timeout) {
      clearTimeout(timeout)
      timeout = undefined
    }
    state.eventsQueue = []
    axios
      .post(
        `/${i18n.locale}/api/logs/events`,
        data
      ).catch(e => {
        // request aborted on page nav
      })
  }
}
const actions = {
  logEvent(context, payload) {
    // TODO: maybe debounce if same name? (in case of number increment )
    payload.fiche = context.rootState.jestime.fiche ? context.rootState.jestime.fiche.pk : null
    payload.scenario = context.rootState.jestime.scenario ? context.rootState.jestime.scenario.pk : null
    payload.uuid = context.rootState.sim.vm.uuid
    payload.created = new Date()
    context.commit('addToEventQueue', payload)
    // payload.flush then force send else queue and timer
    if (payload.flush) {
      context.commit('flushEventQueue')
    } else {
      if (!timeout) {
        timeout = setTimeout(() => {
          context.commit('flushEventQueue')
        }, 5000)
      }
    }
  },
  logEstimation(context, payload = {}) {
    context.commit('flushEventQueue')
    payload.state = {
      data: vmInit.data,
      vm: vueToObj(context.rootState.sim.vm)
    }
    payload.fiche = context.rootState.jestime.fiche ? context.rootState.jestime.fiche.pk : null
    payload.scenario = context.rootState.jestime.scenario ? context.rootState.jestime.scenario.pk : null
    payload.uuid = context.rootState.sim.vm.uuid
    payload.created = new Date()
    return axios
      .post(
        `/${i18n.locale}/api/logs/estimation`,
        payload
      ).then(() => {
        context.dispatch('updateStats')
      })
  },
  updateStats(context) {
    return axios
      .patch(
      `/${i18n.locale}/api/session/${context.getters.stats.uuid}`,
      {
        summary: context.getters.stats
      }
      )
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
