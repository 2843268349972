<template>
  <p class="language" v-if="auth && auth.loggedIn">
    <v-btn text small @click="setLocale('fr')" :class="{active: $i18n.locale === 'fr'}">Français</v-btn>|
    <v-btn text small @click="setLocale('de')" :class="{active: $i18n.locale === 'de'}">Deutsch</v-btn>
  </p>
</template>

<script>
export default {
  name: 'LanguageSelector',
  data(vm) {
    const locales = ['fr', 'de']
    return {
      locale: locales.includes(vm.$i18n.locale) ? vm.$i18n.locale : 'fr',
      locales
    }
  },
  computed: {
    auth() {
      return this.$store.state && this.$store.state.auth
    }
  },
  methods: {
    setLocale(l) {
      this.$i18n.locale = l
    }
  }
}
</script>

<style>
.language .active {
  font-weight: bold;
  color: #3795c2;
}
</style>
