<template>
<transition name="fade" mode="out-in">
  <div v-if="equation" :key="equation.nom + personneIndex">
    <div
      v-if="equation.scope !== 'P' || this.personneIndex >= 0"
      class="equation"
      :class="`${equation.nom} type-${equation.type} ${equation.description ? 'has-hint': ''}`"
    >
      <v-text-field
        v-if="equation.type === 'I' && equation.input_widget == 'text'"
        type="text"
        :value="value"
        @change="value = $event"
        :label="libelle"
        :hint="description"
        persistent-hint
        :placeholder="libelle"
      ></v-text-field>
      <v-currency-field
        v-if="equation.type === 'I' && equation.input_widget == 'number'"
        :value="value"
        @input="updateLocalValue"
        @change="updateValue"
        :label="libelle"
        :hint="description"
        :min="equation.estimation_min"
        :max="equation.estimation_max"
        persistent-hint
        placeholder="0"
      ></v-currency-field>
      <v-checkbox
        v-if="equation.type === 'I' && equation.input_widget === 'checkbox'"
        v-model="value"
        :label="libelle"
        :hint="description"
        persistent-hint
      ></v-checkbox>
      <v-select
        class="mt-3"
        v-if="equation.type === 'I' && equation.input_widget === 'select'"
        v-model="value"
        :label="libelle"
        :hint="description"
        :items="items"
        :item-text="(item) => selectLabel(item)"
        persistent-hint
      ></v-select>
      <p v-if="equation.type === 'I' && equation.input_widget === 'radio'">
        <v-radio-group
          v-model="value" :label="libelle"
          :hint="description"
          persistent-hint
          row>
          <v-radio v-for="(item, index) in items" :label="selectLabel(item)" :value="item.value" :key="index"></v-radio>
        </v-radio-group>
      </p>
      <div v-if="equation.type === 'O'">
        <div class="d-flex" v-if="equation.scope !== 'P'">
          <div>
            {{libelle}}
            <span v-if="debug">[{{equation.nom}}]</span>
          </div>
          <v-spacer class="dots"></v-spacer>
          <div>{{value | CHF}}</div>
        </div>
        <div class="d-flex flex-column" v-if="equation.scope === 'P'">
          <div>
            {{libelle}}
            <span v-if="debug">[{{equation.nom}}]</span>
          </div>
          <div v-for="(p, i) in values"  class="out-p d-flex" :key="i">
            <div>{{p.prenom || '?'}}</div>
            <v-spacer class="dots"></v-spacer>
            <div>{{p.value | CHF}}</div>
            <div v-if="p.override"> *</div>
          </div>
        </div>
        <p class="description">{{description}}</p>
      </div>
      <h3 v-if="equation.type === 'D'">
        {{equation.nom}}
        <a v-if="equation.pk && debug" :href="`/fiche/${equation.fiche}`">edit</a>
      </h3>
      <a v-if="debug && equation.type === 'I'" target="_blank" class="debug-equation" :href="equation.fiche ? `/fiche/${equation.fiche}` : `/global_variables?q=${equation.nom}`">{{equation.fiche ? '' : '[G]'}} {{equation.scope ? 'p.' : ''}}{{equation.nom}}</a>
      <p v-if="debug && ['E', 'C', 'F'].includes(equation.type)">
        {{equation.nom}} = {{stringValue}}
        <code>{{equation.vueFunction}}</code>
        <span v-if="equation.error">{{equation.error}}</span>
      </p>
    </div>
  </div>
</transition>
</template>

<script>
import { replacePrenomFromStateInText } from '@/utils/textTransform'
import { debounce } from '@/utils/tools'

export default {
  name: 'equation-renderer',
  props: ['equation'],
  data() {
    return {
      localValue: null
    }
  },
  computed: {
    items() {
      try {
        if (
          this.equation.type === 'I' && (this.equation.input_widget === 'select' || this.equation.input_widget === 'radio')
        ) {
          return JSON.parse(this.equation.equation)
        }
        return []
      } catch (e) {
        return ['error parsing equation']
      }
    },
    personneIndex() {
      return this.personnes.indexOf(
        this.$store.state.sim.vm.currentPersonne
      )
    },
    personnes() {
      if (this.$store.state.sim.vm.currentPersonne && this.$store.state.sim.vm.currentPersonne.estExterne) {
        return this.$store.state.sim.vm.personnes_externes
      }
      return this.$store.state.sim.vm.personnes
    },
    debug() {
      return this.$store.state.sim.vm.debug
    },
    values() {
      if (this.equation.type === 'O' && this.equation.scope === 'P') {
        let personnes = this.$store.state.sim.vm.personnes
        // input_widget contains condition name to use as filter
        if (this.equation.input_widget) {
          personnes = personnes.filter(p => p[this.equation.input_widget])
        }
        return personnes.map(p => {
          return {
            prenom: p.prenom,
            value: p[this.equation.nom],
            override: p[`beneficiaire_${this.equation.nom}`]
          }
        })
      }
      return []
    },
    stringValue() {
      if (this.value && Array.isArray(this.value) && this.value.length > 0 && this.value[0].hasOwnProperty('prenom')) {
        return this.value.map(p => `#ref/${p.prenom}`)
      }
      if (this.value && this.value.hasOwnProperty('prenom')) {
        return `#ref/${this.value.prenom}`
      }
      return this.value
    },
    value: {
      get() {
        if (this.equation.scope === 'P') {
          if (this.personneIndex >= 0) {
            return this.personnes[this.personneIndex][this.equation.nom]
          }
          return undefined
        }
        return this.$store.state.sim.vm[this.equation.nom]
      },
      set(value) {
        const payload = {
          nom: this.equation.nom,
          value
        }
        if (this.equation.scope === 'P') {
          if (this.personneIndex === -1) return
          payload.personneIndex = this.personneIndex
          payload.estExterne = this.$store.state.sim.vm.currentPersonne.estExterne
        }
        this.$store.dispatch('updateSimValue', payload)
      }
    },
    libelle() {
      return replacePrenomFromStateInText(this.$store.state, this.equation.libelle)
    },
    description() {
      let description = this.equation.description
      if (this.$store.state.jestime.variableDescriptions.hasOwnProperty(this.equation.nom)) {
        description = this.$store.state.jestime.variableDescriptions[this.equation.nom].description
      }
      return replacePrenomFromStateInText(this.$store.state, description)
    }
  },
  methods: {
    updateValue() {
      // used to make v-currency-work on change instead of on input workaround with localVariable and native onchange
      this.value = this.localValue
    },
    updateLocalValue(value) {
      this.localValue = value
      this.debouncedUpdateValue()
    },
    debouncedUpdateValue: debounce(function() {
      this.value = this.localValue
    }, 1000),
    selectLabel(item, locale) {
      if (!locale) {
        locale = this.$i18n.locale
      }
      const key = `text_${locale}`
      let label = ''
      if (item.hasOwnProperty(key)) {
        label = item[key]
      } else if (locale !== 'fr') {
        label = this.selectLabel(item, 'fr')
      } else {
        label = item.text
      }
      return replacePrenomFromStateInText(this.$store.state, label)
    }
  }
}
</script>

<style scoped>
.dots {
  background-image: url(data:image/gif;base64,R0lGODlhCAACAPcAAAAAALu7us7OzgAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACH5BAEAAP8ALAAAAAAIAAIAAAgNAP8JDCBAoMF/BA0GBAA7);
  background-repeat: repeat-x;
  background-position: 0 18px;
  margin: 0 4px;
}
.out-p {
  margin-left: 40px;
}
.description {
  font-style: italic;
}
.debug-equation {
  font-size: 8px;
  color: #e65100;
  margin-top: -20px;
  display: block;
  text-decoration: none;
}
</style>
