<i18n>
{
  "fr": {
    "main_public_title": "J'estime mes droits sociaux",
    "main_public_text": "<p>La Suisse offre une gamme étendue de prestations sociales. Mais faute d'information claire, <b>trop de personnes n'en font pas usage</b>. En fonction de vos revenus, auriez-vous droit à une prestation sociale ? Si vous habitez l'un des cantons romands, <b>vous pouvez facilement le savoir</b> grâce au site jestime.ch</p><p>De façon parfaitement anonyme, ce site permet, si vous êtes majeur·e et domicilé·e en Suisse, d'estimer vos droits notamment à l'<b>aide sociale</b>, aux prestations complémentaires de <b>l'AVS</b> ou de <b>l'AI</b>, aux subsides à l'<b>assurance maladie</b>, à une <b>bourse d’études</b> ou à des avances sur <b>pensions alimentaires</b>.</p><p>Pour connaître complètement vos droits à ces prestations, vous devez vous adresser aux offices compétents de votre canton de domicile <a href=/adresses>adresses utiles</a>.</p>",
    "start_estimation": "Commencer l'estimation",
    "continue_estimation": "Continuer l'estimation",
    "delete_estimation": "Effacer l'estimation",
    "main_public_calcule": "Les prestations sociales sous condition de ressources que jestime peut calculer.",
    "main_public_select": "Choisissez un canton.",
    "main_public_no_prestations": "Malheureusement, aucune prestation n'est actuellement disponible sur jestime.ch pour votre canton."
  },
  "de": {
    "main_public_title": "Ich prüfe meine Rechte auf Sozialleistungen",
    "main_public_text": "<p>Die Schweiz bietet eine breite Palette von Sozialleistungen, aber viele Menschen machen keinen Gebrauch davon. <strong>Und Sie?</strong></p>",
    "start_estimation": "Schätzung starten",
    "continue_estimation": "Schätzung fortsetzen",
    "delete_estimation": "Schätzung löschen",
    "main_public_calcule": "Bedarfsabhängige Sozialleistungen die jestime berechnen kann.",
    "main_public_select": "Wählen Sie einen Kanton.",
    "main_public_no_prestations": "Leider sind auf jestime.ch zurzeit keine Dienste für Ihren Kanton verfügbar."
  }
}
</i18n>
<template>
  <div>
    <v-container fluid v-if="isNew && !go" class="pt-0">
      <v-row class="blue-background pb-4">
        <v-col
          class="pl-16"
          cols="12"
          offset-md="1"
          md="10"
          offset-lg="2"
          lg="8"
          offset-xl="2"
          xl="8"
          :class="{ 'home-top-bg': !$vuetify.breakpoint.xs, 'white': $vuetify.breakpoint.xs }"
        >
          <h1>J'ai des droits sociaux !</h1>
          <ul>
            <li><strong>Aide sociale</strong></li>
            <li>Avances sur <strong>pensions alimentaires</strong></li>
            <li><strong>Bourses</strong> d'études et d'apprentissage</li>
            <li>
              Prestations complémentaires à l'<strong>AVS</strong> ou à
              l'<strong>AI</strong>
            </li>
            <li>Subsides à l'<strong>assurance maladie</strong></li>
            <li>Etc.</li>
          </ul>
        </v-col>
      </v-row>
      <v-row v-if="!$vuetify.breakpoint.xs" class="home-middle-filler">
        <v-col class="home-middle-bg" offset-md="1" offset-lg="2" offset-xl="2"></v-col>
        <v-col style="background-color: white;"></v-col>
      </v-row>
      <v-row
        class="pb-8"
      >
        <v-col offset-sm="1" sm="10" offset-md="2" md="8"  offset-lg="3" lg="6" offset-xl="3" xl="6" >
          <v-alert type="warning"
            tile
            dense
          >
            Faute de financement, le site n’est pas à jour depuis 2022. Pour cette raison, le calculateur jestime.ch
            a été désactivé. Les difficultés rencontrées pour perenniser le projet ont été analysées
            dans un article paru dans la <a href="https://szsa.ch/2023_30_63-83/">Revue suisse de travail social</a>.</p>
          </v-alert>
            <!--
          <h1>Je les estime :</h1>
          <location-selector-home @go="go = true"></location-selector-home>
          -->
        </v-col>
      </v-row>
      <v-row class="mb-16 blue-background">
        <v-col
          cols="10"
          offset="1"
          sm="6"
          offset-md="1"
          md="4"
          offset-lg="2"
          lg="3"
          offset-xl="2"
          xl="3"
          style="padding: 0"
        >
          <v-img src="/img/home/home_bottom_left2.svg"></v-img>
        </v-col>
        <v-col cols="12" class="px-12" sm="6" md="5" lg="5">
          <h3 class="mt-8">
            En fonction de vos revenus, auriez-vous droit à des prestations
            sociales ?
          </h3>
          <p>
            Si vous êtes
            <strong
              >majeur·e et domicilé·e dans l'un des cantons de Suisse
              romande</strong
            >
            vous pouvez les <strong>estimer</strong> de façon parfaitement
            <strong>anonyme.</strong>
          </p>
          <p>
            Pour faire valoir vos droits à ces prestations,
            <strong
              >vous devez vous adresser aux offices compétents de votre canton
              de domicile.</strong
            >
          </p>
        </v-col>
      </v-row>
    </v-container>
    <v-container v-else>
      <v-row>
        <v-col cols="12" md="8" offset-md="2">
          <h1
            class="text-center font-now-light mb-8"
            v-if="
              $store.state.sim.vm &&
              $store.state.sim.vm.lieuLogement &&
              $store.state.sim.vm.lieuLogement.canton
            "
          >
            Voici les prestations du<br /><span class="font-now-bold"
              >canton
              {{
                ['VS', 'JU'].includes($store.state.sim.vm.lieuLogement.canton)
                  ? 'du'
                  : 'de'
              }}
              {{
                $store.state.jestime.cantonsNames[
                  $store.state.sim.vm.lieuLogement.canton
                ]
              }}</span
            ><br />
            disponibles à l'estimation
          </h1>
          <v-row>
            <v-col cols="8" offset="2">
              <div v-if="isNew">
                <v-btn
                  dark
                  depressed
                  block
                  :to="{ name: 'jestime' }"
                  class="pa-8"
                >
                  {{ $t('start_estimation') }}
                  <v-icon right dark>fas fa-caret-right</v-icon>
                </v-btn>
              </div>
              <v-row v-else>
                <v-col>
                  <v-btn
                    dark
                    depressed
                    block
                    :to="{ name: 'jestime' }"
                    class="pa-8"
                  >
                    {{ $t('continue_estimation') }}
                    <v-icon right dark>fas fa-caret-right</v-icon>
                  </v-btn>
                </v-col>
                <v-col>
                  <v-btn depressed @click="restart" color="error">
                    {{ $t('delete_estimation') }}
                    <v-icon right dark>fas fa-trash-alt</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <p class="mt-8">
                Nous allons vous poser des questions sur votre situation
                financière et familiale. Temps nécessaire 10 minutes, anonymat
                parfaitement garanti.
              </p>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="main-direct-access">
        <v-col
          v-for="p in prestations"
          :key="`p_${p.pk}`"
          cols="12"
          md="4"
          class="pa-5"
        >
          <home-prestation-item :prestation="p"></home-prestation-item>
        </v-col>
        <v-col v-if="prestations.length === 0">{{
          $t('main_public_no_prestations')
        }}</v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import HomePrestationItem from '@/components/HomePrestationItem'
import LocationSelectorHome from '@/components/widgets/LocationSelectorHome'

export default {
  name: 'home-public',
  data() {
    return {
      go: false
    }
  },
  computed: {
    prestations() {
      if (this.$store.state.jestime.fiches.length > 0) {
        const parsedPrestation = []
        // replace general prestation text with canton text from fiche
        const prestations = []
        this.$store.state.jestime.fiches.forEach((f) => {
          if (!parsedPrestation.includes(f.prestation)) {
            const prestation = Object.assign(
              {},
              this.$store.state.jestime.prestations[f.prestation]
            )
            prestation.lien_canton = f.lien_canton
            prestation.nom = f.nom ? f.nom : prestation.nom
            prestation.description = f.descriptif
              ? f.descriptif
              : prestation.description
            this.$set(prestation, 'details', false)
            prestations.push(prestation)
            parsedPrestation.push(f.prestation)
          }
        })
        return prestations
      }
      // default show only show global prestations
      return Object.values(this.$store.state.jestime.prestations).filter(
        (p) => p.federal
      )
    },
    isNew() {
      return this.$store.state.sim.vm ? this.$store.state.sim.vm.isNew : true
    }
  },
  methods: {
    /*
    loadFichesCanton(canton) {
      if (canton) {
        console.debug('getFiches - HomePublic', canton)
        this.$store.dispatch('getFiches', {
          canton
        })
      } else {
        this.$store.dispatch('getCalculateurs', {
          canton: 'CH'
        })
      }
    },
    */
    restart() {
      this.$store.dispatch('restart').then(() => {
        window.location.assign('/')
      })
    }
  },
  watch: {
    '$i18n.locale'(value) {
      // WARN: move if moving selectedCanton to store
      // reload locale for fiche descriptif of prestation
      // TODO this.loadFichesCanton(this.selectedCanton)
    }
  },
  components: {
    LocationSelectorHome,
    HomePrestationItem
  }
}
</script>

<style>
.v-application .box-background {
  background-color: #3795c2;
  color: #fff;
  padding: 16px;
}
.v-application .box-background a {
  color: #fff;
}
.v-application .box-background h3 {
  color: white;
  margin-bottom: 1em;
}

.blue-background {
  background-color: #d7e5f1;
}
.home-top-bg {
  background-color: white;
  background-image: url('/public/img/home/home_top_left2.svg'),
    url('/public/img/home/home_top_right2.svg');
  background-position: 0 0, 100% 0;
  background-size: contain, contain;
}
.home-middle-bg {
  background-color: white;
  background-image: url('/public/img/home/home_middle_left2.svg');
  background-position: 0 0;
}
.home-middle-filler {
  height: 45px;
  background-color: #d7e5f1;
}
</style>
