import * as Babel from '@babel/standalone'

function rulesToVueJS({ types: t }) {
  return {
    pre(state) {
      this.identifiers = new Set()
    },
    visitor: {
      CallExpression(path) {
        // transform some(...) to personnes.some( p => ...)
        if (path.node.callee.name === 'some') {
          path
            .get('callee')
            .replaceWith(
              t.memberExpression(
                t.identifier('personnes'),
                t.identifier('some')
              )
            )
          path.node.arguments = [
            t.arrowFunctionExpression(
              [t.identifier('p')],
              path.node.arguments[0]
            )
          ]
        }
        // transform sum( p.age ) to personnes.reduce( (total, p) => agg + p.age, 0)
        if (path.node.callee.name === 'sum') {
          path
            .get('callee')
            .replaceWith(
              t.memberExpression(
                t.identifier('personnes'),
                t.identifier('reduce')
              )
            )
          path.node.arguments[0].extra = { parenthesized: true }
          path.node.arguments = [
            t.arrowFunctionExpression(
              [t.identifier('total'), t.identifier('p')],
              t.binaryExpression('+', t.identifier('total'),
                path.node.arguments[0]
              )

            ),
            t.numericLiteral(0)
          ]
        }
      },
      Identifier(path) {
        // append this in needed cases and collect variables
        if (t.isProperty(path.parent)) {
          return
        }
        if (path.scope.hasBinding(path.node.name)) {
          if (path.parent.property && path.parent.object.name === 'p') {
            this.identifiers.add(`p.${path.parent.property.name}`)
          }
          return
        }
        if (
          !t.isMemberExpression(path.parent) ||
          (path.parent.object === path.node || path.parent.computed)
        ) {
          // skip identifiers which are function names
          const isFunctionName =
            t.isCallExpression(path.parent) &&
            path.parent.callee.name === path.node.name
          if (!isFunctionName) {
            this.identifiers.add(path.node.name)
          }
          path.replaceWith(
            t.memberExpression(t.thisExpression(), t.identifier(path.node.name))
          )
        }
        // get references of it.attribute or p.attribute and personnes[0].age, but not function calls
        if (
          path.node.name !== 'length' &&
          t.isMemberExpression(path.parent) &&
          path.parent.property === path.node &&
          !t.isCallExpression(path.parentPath.parent)
        ) {
          if (t.isThisExpression(path.parent.object)) {
            this.identifiers.add(path.node.name)
          } else {
            this.identifiers.add(`p.${path.node.name}`)
          }
        }
      },
      BinaryExpression(path) {
        // force logic operators
        if (path.node.operator === '&') {
          path.node.operator = '&&'
        }
        if (path.node.operator === '|') {
          path.node.operator = '||'
        }
      }
    },
    post(state) {
      // exfiltrate identifier via options, is there another way?
      state.opts.identifiers = [...this.identifiers]
    }
  }
}
Babel.registerPlugin('rulesToVueJS', rulesToVueJS)

function rulesToVueJSForPerson({ types: t }) {
  return {
    visitor: {
      Identifier(path) {
        // replace var by this.$parent
        if (
          !t.isMemberExpression(path.parent) ||
          (path.parent.object === path.node || path.parent.computed)
        ) {
          path.replaceWith(
            t.memberExpression(
              t.memberExpression(t.thisExpression(), t.identifier('$parent')),
              t.identifier(path.node.name)
            )
          )
        }
        // replace p.var by this.var
        if (
          t.isMemberExpression(path.parent) &&
          path.parent.property === path.node &&
          path.parent.property.name === 'p'
        ) {
          path.parentPath.replaceWith(t.thisExpression())
        }
      },
      BinaryExpression(path) {
        // force logic operators
        if (path.node.operator === '&') {
          path.node.operator = '&&'
        }
        if (path.node.operator === '|') {
          path.node.operator = '||'
        }
      }
    }
  }
}
Babel.registerPlugin('rulesToVueJSForPerson', rulesToVueJSForPerson)

export function parse(source) {
  const r = Babel.transform(source, {
    presets: ['es2015'],
    sourceType: 'script',
    plugins: ['rulesToVueJS']
  })
  return {
    code: r.code,
    identifiers: r.options.identifiers
  }
}

export function parsePerson(source) {
  const r = Babel.transform(source, {
    presets: ['es2015'],
    sourceType: 'script',
    plugins: ['rulesToVueJSForPerson']
  })
  return {
    code: r.code
  }
}

export function conditionToString(condition) {
  let expression = ''
  if (condition.type === 'condition') {
    expression += condition.input
  } else if (condition.type === 'and' || condition.type === 'or') {
    expression +=
      '( ' +
      condition.rules
        .map(conditionToString)
        .join(condition.type === 'and' ? ' && ' : ' || ') +
      ' )'
  }
  return expression
}
