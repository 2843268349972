import PersonneSelector from '@/components/widgets/PersonneSelector'
import store from '@/store'

export function getPersonneSelector(content) {
  for (const c of content) {
    if (c.type === PersonneSelector.name) {
      return c
    }
    if (c.type === 'group') {
      const found = getPersonneSelector(c.content)
      if (found) {
        return found
      }
    }
  }
}

// checks vm id given equation is true if condition is false return true.
export function isTrue(condition) {
  if (!store.state.sim.vm) {
    return false
  }
  if (condition) {
    const equation = store.getters.allVariables[condition]
    if (equation) {
      if (equation.scope === 'P') {
        if (store.state.sim.vm.currentPersonne) {
          return store.state.sim.vm.currentPersonne[condition]
        }
        return false
      }
      return store.state.sim.vm[condition]
    }
  }
  return true
}

export function hasVisibleContent(content) {
  let count = 0
  for (const c of content) {
    if (c.type === 'group') {
      if (isTrue(c.condition)) {
        count++
      }
    } else {
      count++
    }
  }
  return count > 0
}

export function getPersonnesFromPersonneSelector(ps) {
  if (!store.state.sim.vm || !ps) {
    return []
  }
  let personnes = 'personnes'
  if (ps.showExterne) {
    personnes = 'personnes_externes'
  }
  if (ps.filter) {
    return store.state.sim.vm[personnes].filter(p => p[ps.filter])
  }
  return store.state.sim.vm[personnes]
}

export function skipStep(step) {
  if (!step) return false
  const ps = getPersonneSelector(step.content)
  const personnes = getPersonnesFromPersonneSelector(ps)
  const skipWhenPSandEmpty = ps && !ps.allowEdit && personnes.length === 0
  const skipNoPSandEmpty = !hasVisibleContent(step.content)
  return skipWhenPSandEmpty || skipNoPSandEmpty
}
