<template>
  <div class="address">
    <p v-for="(line, i) in address.nom.split('\n')" :key="`a${i}`">
      <strong>{{line}}</strong>
    </p>
    <p v-for="(line, i) in address.adresse.split('\n')" :key="`l${i}`">{{line}}</p>
    <p v-if="address.tel">Tél. {{address.tel}}</p>
    <p v-if="address.email">{{address.email}}</p>
    <p v-if="address.url">
      <a :href="address.url" rel="noopener" target="_blank" :title="address.nom">{{address.url | domain}}</a>
      <a :href="address.url" rel="noopener" target="_blank" class="open-external"><v-icon small>fas fa-external-link-alt</v-icon></a>
    </p>
    <p v-if="address.image" class="logo">
      <a v-if="address.url" :href="address.url" rel="noopener" target="_blank">
        <img :src="`/img/logos_addresses/${address.image}`" :alt="address.nom">
      </a>
      <img v-else :src="`/img/logos_addresses/${address.image}`" :alt="address.nom">
    </p>
  </div>
</template>

<script>
import domain from '@/filters/domain'
export default {
  name: 'app-address',
  props: ['address'],
  filters: {
    domain
  }
}
</script>

<style>
.address {
  margin-bottom: 16px;
  padding: 16px;
}
.address p {
  margin: 0;
}
.address .logo {
  padding: 12px;
}
.address .logo img {
  max-width: 250px;
  max-height: 100px;
}
.open-external {
  text-decoration: none;
  padding-left: 0.5em;
}

.open-external .v-icon {
  color: #3795c2;
}
</style>
