import { parse, parsePerson } from '@/utils/equationParser'
import { roundEstimation } from '@/utils/roundEstimation'
import { restartSurvey } from '@/models/survey'
import Vue from 'vue'
import { v4 as uuidv4 } from 'uuid'
import store from '@/store'

const LOCAL_STORAGE_DATA_KEY = 'jestime_data'
export const PREFIX_OVERRIDE_CHECK = 'beneficiaire'

const defaultInitialData = {
  lieuLogement: null,
  logement: 'estLocataire',
  personnes: [],
  personnes_externes: [],
  dependencyCalculated: [],
  currentPersonne: null,
  fichesCount: 0,
  uuid: '',
  isNew: true,
  dismissSurveyDialog: false,
  surveyDone: false
}

export const vmInit = {
  data: Object.assign({}, defaultInitialData),
  computed: {},
  methods: {
    min: Math.min,
    max: Math.max,
    round: Math.round,
    floor: Math.floor,
    ceil: Math.ceil,
    pow: Math.pow,
    sqrt: Math.sqrt,
    abs: Math.abs,
    arrondi: roundEstimation,
    getIndex(value, table) {
      return table.reduce((r, it) => (value <= it ? r : r + 1), 0)
    }
  },
  personneState: {
    data: {
      assurance_maladie: null,
      assurance_maladie_model: null,
      montant_prime_franchise_min: null,
      montant_prime_franchise_actuelle: null,
      franchise: null,
      assurance_accident: false,
      dessaisissement_avec_amortissement: 0,
      dessaisissements: []
    },
    computed: {}
  }
}

function createPersonneVue(parent, data, index) {
  return new Vue({
    parent,
    data,
    computed: Object.assign({
      // needed to create a computed for tracking the correct watch changes
      watch_age_avs() {
        return this.a_age_avs_normal && this.beneficiaire_avs === null
      }
    }, vmInit.personneState.computed),
    methods: vmInit.methods,
    watch: {
      // TODO create dynamique supoprt through equation of a new type?
      // do we need to be able to set global variable from personneScope? or only personne to personne?
      // waiting for second need example
      // name of equation
      watch_age_avs() {
        // equation could be condition
        if (this.a_age_avs_normal && this.beneficiaire_avs === null) {
          store.dispatch('updateSimValue', {
            personneIndex: index,
            // how to capture nom, value? in equation config?
            nom: 'beneficiaire_avs',
            value: true // could be an equation?
          })
        }
      }
    }
  })
}

// TODO maybe save vueFunction in database to not recompute?
function computeVueFunction(e) {
  if (!e.equation) {
    return
  }
  let vueFunction = ''
  if (e.scope === 'P') {
    vueFunction = parsePerson(e.equation).code
  } else {
    vueFunction = parse(e.equation).code
  }
  return vueFunction
}

const getters = {}

const mutations = {
  resetSim(state, initialData = {}) {
    vmInit.computed = {}
    let localData = {}
    try {
      try {
        localData = JSON.parse(
          localStorage.getItem(LOCAL_STORAGE_DATA_KEY) || '{}'
        )
      } catch (e) {
        localData = JSON.parse(
          sessionStorage.getItem(LOCAL_STORAGE_DATA_KEY) || '{}'
        )
      }
      delete localData.currentPersonne
      delete localData.debug
      delete localData.fichesCount
    } catch (e) {
      console.log(e)
    }

    vmInit.data = Object.assign(
      {},
      defaultInitialData,
      {
        uuid: uuidv4()
      },
      localData,
      initialData
    )
  },
  clearLocalData() {
    try {
      sessionStorage.removeItem(LOCAL_STORAGE_DATA_KEY)
      localStorage.removeItem(LOCAL_STORAGE_DATA_KEY)
    } catch (e) {
      sessionStorage.removeItem(LOCAL_STORAGE_DATA_KEY)
    }
  },
  setData(state, data) {
    vmInit.data = Object.assign(
      // keep all data properties, but set them to null (so we do not need to readd all equations)
      Object.keys(vmInit.data).reduce((obj, key) => {
        obj[key] = null
        return obj
      }, {}),
      defaultInitialData,
      {
        uuid: uuidv4()
      },
      JSON.parse(JSON.stringify(data))
    )
    // save to localstorage to have last test loaded for reuse
    try {
      localStorage.setItem(LOCAL_STORAGE_DATA_KEY, JSON.stringify(vmInit.data))
    } catch (e) {
      sessionStorage.setItem(LOCAL_STORAGE_DATA_KEY, JSON.stringify(vmInit.data))
    }
  },
  addDependencyCalculated(simState, dep) {
    if (!simState.vm.dependencyCalculated.includes(dep)) {
      simState.vm.dependencyCalculated.push(dep)
    }
    if (!vmInit.data.dependencyCalculated.includes(dep)) {
      vmInit.data.dependencyCalculated.push(dep)
    }
    // save to localstorage to have last test loaded for reuse
    try {
      localStorage.setItem(LOCAL_STORAGE_DATA_KEY, JSON.stringify(vmInit.data))
    } catch (e) {
      sessionStorage.setItem(LOCAL_STORAGE_DATA_KEY, JSON.stringify(vmInit.data))
    }
  },
  removeDependencyCalculated(simState, dep) {
    let index = simState.vm.dependencyCalculated.indexOf(dep)
    if (index >= 0) {
      simState.vm.dependencyCalculated.splice(index, 1)
    }
    index = vmInit.data.dependencyCalculated.indexOf(dep)
    if (index >= 0) {
      vmInit.data.dependencyCalculated.splice(index, 1)
    }
    // save to localstorage to have last test loaded for reuse
    try {
      localStorage.setItem(LOCAL_STORAGE_DATA_KEY, JSON.stringify(vmInit.data))
    } catch (e) {
      sessionStorage.setItem(LOCAL_STORAGE_DATA_KEY, JSON.stringify(vmInit.data))
    }
  },
  addEquation(simState, equation) {
    if (!equation || equation.nom === 'personnes' || equation.nom === 'personnes_externes') {
      // protect special array personnes
      return
    }

    const state = equation.scope === 'P' ? vmInit.personneState : vmInit

    const dataExists = state.data.hasOwnProperty(equation.nom)
    const computedExists = state.computed.hasOwnProperty(equation.nom)

    if (!dataExists && !computedExists && equation.type === 'I') {
      state.data[equation.nom] = null
      return
    }
    if (dataExists && equation.type === 'I') return
    // handle override, but protect special variable personnes
    if (dataExists && equation.type !== 'I') {
      delete state.data[equation.nom]
    }
    let vueFunction
    try {
      vueFunction = computeVueFunction(equation)
    } catch (e) {
      Vue.set(equation, 'error', e.message)
      return
    }
    equation.vueFunction = vueFunction
    if (equation.type === 'F') {
      try {
        const computedCode = `try {
${vueFunction}
} catch(e) {
  return this.debug ? e.message : 'N/A';
}
`
        // eslint-disable-next-line
        state.computed[equation.nom] = new Function(computedCode)
      } catch (e) {
        Vue.set(equation, 'error', e.message)
      }
    }
    if (
      equation.type === 'E' ||
      equation.type === 'C' ||
      equation.type === 'O' ||
      equation.type === 'W'
    ) {
      try {
        let computedCode = 'try {\n'
        if (equation.type === 'O') {
          computedCode += `  if (this.${PREFIX_OVERRIDE_CHECK}_${equation.nom}) {
            return this.${equation.nom}_input;
          }\n`
        }
        computedCode += ` const res = ${vueFunction}
  return res === null || isNaN(res) || typeof res === "boolean" || Array.isArray(res) ? res : Number(res);
} catch(e) {
  console.log('${equation.nom}', e);
  return this.debug ? e.message : 'N/A';
}
`
        // eslint-disable-next-line
        state.computed[equation.nom] = new Function(computedCode)
      } catch (e) {
        console.log('Function error', equation.nom, e)
        Vue.set(equation, 'error', e.message)
      }
    }
  },
  initVM(state) {
    for (const p of vmInit.data.personnes) {
      // keep value already stored, but init all properties
      Object.keys(vmInit.personneState.data).forEach(key => {
        if (!p.hasOwnProperty(key)) {
          p[key] = vmInit.personneState.data[key]
        }
      })
    }
    for (const p of vmInit.data.personnes_externes) {
      // keep value already stored, but init all properties
      Object.keys(vmInit.personneState.data).forEach(key => {
        if (!p.hasOwnProperty(key)) {
          p[key] = vmInit.personneState.data[key]
        }
      })
    }
    // protecet against overriding computed with old data variables
    const candidateData = JSON.parse(JSON.stringify(vmInit.data))
    for (const key of Object.keys(candidateData)) {
      if (vmInit.computed.hasOwnProperty(key)) {
        delete candidateData[key]
      }
    }
    const vm = new Vue({
      data: candidateData,
      computed: vmInit.computed,
      methods: vmInit.methods
    })
    vm.personnes = vmInit.data.personnes.map((p, index) => {
      // protecet against overriding computed with old data variables
      const candidateData = JSON.parse(JSON.stringify(p))
      for (const key of Object.keys(candidateData)) {
        if (vmInit.personneState.computed.hasOwnProperty(key)) {
          delete candidateData[key]
        }
      }
      return createPersonneVue(vm, candidateData, index)
    })

    if (vm.personnes.length > 0) {
      vm.currentPersonne = vm.personnes[0]
    }

    vm.personnes_externes = vmInit.data.personnes_externes.map(p => {
      // protecet against overriding computed with old data variables
      const candidateData = JSON.parse(JSON.stringify(p))
      for (const key of Object.keys(candidateData)) {
        if (vmInit.personneState.computed.hasOwnProperty(key)) {
          delete candidateData[key]
        }
      }
      return new Vue({
        parent: vm,
        data: candidateData,
        computed: vmInit.personneState.computed,
        methods: vmInit.methods
      })
    })

    state.vm = vm
    if (vm.personnes.length === 0) {
      mutations.updateSimAddPerson(state, { prenom: 'Je' })
      mutations.updateSimAddPersonExterne(state, { id: 'p1', prenom: 'Parent 1' })
      mutations.updateSimAddPersonExterne(state, { id: 'p2', prenom: 'Parent 2' })
      mutations.updateSimAddPersonExterne(state, { id: 'r1', prenom: 'Responsable 1' })
      mutations.updateSimAddPersonExterne(state, { id: 'r2', prenom: 'Responsable 2' })
      mutations.updateSimAddPersonExterne(state, { id: 'ps', prenom: 'Parents' })
    }
  },
  updateSimValue(state, payload) {
    if (payload.personneIndex !== undefined) {
      let personnes = 'personnes'
      if (payload.estExterne) {
        personnes = 'personnes_externes'
      }
      Vue.set(
        state.vm[personnes][payload.personneIndex],
        payload.nom,
        payload.value
      )
      vmInit.data[personnes][payload.personneIndex][payload.nom] = payload.value
    } else {
      if (state.vm && payload.nom !== 'fichesCount') {
        if (payload.nom !== 'lieuLogement') {
          state.vm.isNew = false
          vmInit.data.isNew = false
        }
        Vue.set(state.vm, payload.nom, payload.value)
      }
      if (!['currentPersonne'].includes(payload.nom)) {
        vmInit.data[payload.nom] = payload.value
      }
    }
    try {
      localStorage.setItem(LOCAL_STORAGE_DATA_KEY, JSON.stringify(vmInit.data))
    } catch (e) {
      sessionStorage.setItem(LOCAL_STORAGE_DATA_KEY, JSON.stringify(vmInit.data))
    }
  },
  updateSimAddPerson(state, payload) {
    // keep value already stored, but init all properties
    Object.keys(vmInit.personneState.data).forEach(key => {
      if (!payload.hasOwnProperty(key)) {
        payload[key] = vmInit.personneState.data[key]
      }
    })
    const data = JSON.parse(JSON.stringify(payload))
    const index = vmInit.data.personnes.push(payload) - 1
    const person = createPersonneVue(state.vm, data, index)
    state.vm.personnes.push(person)
    state.vm.currentPersonne = person
  },
  updateSimAddPersonExterne(state, payload) {
    // for now handle externe as having all the same equations as a normal personne
    // keep value already stored, but init all properties
    Object.keys(vmInit.personneState.data).forEach(key => {
      if (!payload.hasOwnProperty(key)) {
        payload[key] = vmInit.personneState.data[key]
      }
    })
    // mark personne as externe for updateSimValue
    payload.estExterne = true
    vmInit.data.personnes_externes.push(payload)
    const person = new Vue({
      parent: state.vm,
      data: JSON.parse(JSON.stringify(payload)),
      computed: vmInit.personneState.computed,
      methods: vmInit.methods
    })
    state.vm.personnes_externes.push(person)
  },
  updateSimRemovePerson(state, payload) {
    let index = state.vm.personnes.indexOf(payload)
    if (index !== -1) {
      vmInit.data.personnes.splice(index, 1)
      state.vm.personnes.splice(index, 1)
      return
    }
    index = state.vm.personnes_externes.indexOf(payload)
    if (index !== -1) {
      vmInit.data.personnes_externes.splice(index, 1)
      state.vm.personnes_externes.splice(index, 1)
    }
  }
}

const logEventBlacklist = ['currentPersonne', 'fichesCount']

const actions = {
  updateSimValue(context, payload) {
    context.commit('updateSimValue', payload)
    if (!logEventBlacklist.includes(payload.nom)) {
      context.dispatch('logEvent', { name: payload.nom, value: payload.value })
    }
  },
  async restart(context, payload) {
    context.dispatch('logEvent', { name: 'restart', flush: true })
    context.commit('clearLocalData')
    context.commit('resetSim', {})
    context.commit('initVM')
    restartSurvey()
  },
  loadData(context, data) {
    context.commit('clearLocalData')
    context.commit('setData', data)
    context.commit('initVM')
  }
}

export default {
  state: {
    vm: null
  },
  getters,
  mutations,
  actions
}
