import Vue from 'vue'
import i18n from '@/plugins/i18n'
import axios from 'axios'
import { vmInit } from './sim'

const state = {
  tests: []
}
const getters = {}
const mutations = {
  setTestResults(state, { test, results }) {
    Vue.set(test, 'results', results)
  },
  removeTest(state, test) {
    const index = state.tests.indexOf(test)
    if (index > -1) {
      state.tests.splice(index, 1)
    }
  },
  setTestCases(state, data) {
    state.tests = data
  }
}

export function filterData(obj) {
  const filteredData = {}
  Object.keys(obj).forEach(key => {
    if (obj[key] !== null) {
      filteredData[key] = obj[key]
    }
  })
  if (obj.personnes) {
    filteredData.personnes = obj.personnes.map(filterData)
  }
  if (obj.personnes_externes) {
    filteredData.personnes_externes = obj.personnes_externes.map(filterData)
  }
  delete filteredData.dismissSurveyDialog
  delete filteredData.surveyDone
  delete filteredData.uuid
  delete filteredData.fichesCount
  delete filteredData.isNew
  delete filteredData.dependencyCalculated
  delete filteredData.currentPersonne
  return filteredData
}

const JESTIME_KEY_LAST_CONTACT = 'JESTIME_KEY_LAST_CONTACT';

const actions = {
  createTestData(context, payload) {
    const test = {
      data: filterData(vmInit.data),
      nom: '',
      descriptif: payload.descriptif || '',
      contact: localStorage.getItem(JESTIME_KEY_LAST_CONTACT) || '',
      correct: true,
      canton: payload.canton,
      prestation: payload.prestation,
      date_validite: null,
      toBe: {
        personnes: []
      }
    }
    return new Promise(resolve => resolve(test))
  },
  getTestCases(context, payload) {
    let url = `/${i18n.locale}/api/fichetestcase/?actuel=True`
    if (payload && payload.canton && payload.prestation) {
      url += `&canton=${payload.canton}&prestation=${payload.prestation}`
      // TODO: filter on dates?
    }
    return axios
      .get(url)
      .then(response => {
        context.commit('setTestCases', response.data)
        return response.data
      })
      .catch(e => {
        console.log(e)
      })
  },
  saveTestCase(context, payload) {
    try {
      localStorage.setItem(JESTIME_KEY_LAST_CONTACT, payload.contact || '')
    } catch (e) {
      console.log(e)
    }
    if (payload.pk) {
      return axios
        .put(`/${i18n.locale}/api/fichetestcase/${payload.pk}`, payload)
        .then(response => {
          return response.data
        })
    } else {
      return axios
        .post(`/${i18n.locale}/api/fichetestcase`, payload)
        .then(response => {
          Vue.set(payload, 'pk', response.data.pk)
          return response.data
        })
    }
  },
  deleteTestCase(context, payload) {
    if (payload.pk) {
      return axios
        .delete(`/${i18n.locale}/api/fichetestcase/${payload.pk}`)
        .then(response => {
          context.commit('removeTest', payload)
          return response.data
        })
    }
  },
  uploadTestCaseFile(context, payload) {
    if (payload.pk && payload.files) {
      const formData = new FormData()
      payload.files
        .filter(f => !!f)
        .forEach(file => {
          formData.append('files[]', file)
        })
      return axios
        .post(
          `/${i18n.locale}/api/filetestcase/${payload.pk}/files`,
          formData,
          {
            headers: {
              'content-type': 'multipart/form-data'
            }
          }
        )
        .then(response => {
          return response.data
        })
    }
  },
  runTest(context, test) {
    context.dispatch('loadData', test.data)
    const vm = context.rootState.sim.vm
    const results = {
      passed: 0,
      failed: 0,
      total: 0,
      details: [],
      allPassed: true
    }

    function compare(toBeObj, isObj, index = -1) {
      Object.keys(toBeObj).forEach(key => {
        if (key === 'personnes') {
          toBeObj.personnes.forEach((p, i) => {
            compare(p, isObj.personnes[i], i)
          })
        } else {
          const is = isObj[key]
          const expected = toBeObj[key]
          const pass = is === expected
          const name = index > -1 ? `p${index}.${key}` : key
          results.details.push({
            name,
            is,
            expected,
            pass
          })
          if (pass) {
            results.passed++
          } else {
            results.allPassed = false
            results.failed++
          }
          results.total++
        }
      })
    }
    compare(test.toBe, vm)

    context.commit('setTestResults', {
      results,
      test
    })
  },
  runAllTests(context) {
    for (const test of context.state.tests) {
      context.dispatch('runTest', test)
    }
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
