<i18n>
{
  "fr": {
    "restart": "Effacer et recommencer"
  },
  "de": {
    "restart": "Löschen und neustart"
  }
}
</i18n>
<template>
  <div id="toolbar" class="d-flex flex-grow-1 align-stretch d-print-none">
    <v-container style="padding:0;min-width:0;" class="d-flex flex-grow-1 align-stretch">
    <div class="toolbar-link d-flex align-stretch flex-shrink-1" v-for="(s, i) in steps" :key="i">
      <router-link v-if="!s.skip" :class="{active: s.currentStep}" :to="s.to">{{s.text}}</router-link>
      <v-icon  class="px-2" v-if="i < steps.length - 1 && !s.skip">fa fa-play</v-icon>
    </div>
    <v-spacer></v-spacer>
    <v-btn text dark @click="restart()" class="align-self-center">
      <v-icon small left>fa-redo</v-icon>
      {{$t('restart')}}
    </v-btn>
    </v-container>
  </div>
</template>

<script>
import { skipStep } from '@/utils/scenarios'
export default {
  name: 'app-toolbar',
  computed: {
    steps() {
      const steps =
        this.$store.state.jestime.scenario &&
        this.$store.state.jestime.scenario.steps
      let items = []
      if (!steps) {
        return items
      }
      const isPrestations = this.$route.name === 'prestations'
      const isFiche = this.$store.state.jestime.fiche.pk && !isPrestations
      if (!isPrestations) {
        items = steps
          .map((s, i) => {
            const currentStep = this.$route.query.step || 0
            return {
              text: s.name,
              // eslint-disable-next-line
            currentStep: i == currentStep,
              skip: skipStep(s),
              to: { query: Object.assign({}, this.$route.query, { step: i }) }
            }
          })
      } else {
        items.push({
          text: 'Vous',
          currentStep: false,
          to: { name: 'jestime' }
        })
      }

      if (isFiche) {
        // prepend prestation name with canton
        let canton = ' '
        if (this.$store.state.jestime.cantons && this.$store.state.jestime.cantons[this.$store.state.jestime.fiche.canton]) {
          canton += this.$store.state.jestime.cantons[this.$store.state.jestime.fiche.canton].slug
        }
        items.unshift({
          text: this.$store.state.jestime.fiche.nom + canton,
          currentStep: false,
          to: { name: 'prestations' }
        })
        // add last step of a fiche showing prestations output
        // (estimation tab) if not in dependency calculation
        if (this.$store.state.jestime.scenarioParent.length === 0) {
          items = items.concat({
            text: 'Estimations',
            // eslint-disable-next-line
            currentStep: steps.length == this.$route.query.step,
            to: {
              query: Object.assign({}, this.$route.query, { step: steps.length })
            }
          })
        }
      } else {
        // show canton
        let canton = ''
        if (this.$store.state.sim.vm &&
            this.$store.state.sim.vm.lieuLogement) {
          canton = this.$store.state.sim.vm.lieuLogement.canton
        }
        items = items.concat([
          {
            text: `Prestations ${canton}`,
            currentStep: isPrestations,
            to: { name: 'prestations' }
          }
        ])
      }
      return items
    }
  },
  methods: {
    restart() {
      this.$store.dispatch('restart').then(() => {
        window.location.assign('/jestime')
      })
    }
  }
}
</script>

<style>
#toolbar {
  color: #fff;
  background-color: #3795c2;
  margin: 0 -16px -4px -16px;
  height: 48px;
  min-width: 0;
}
#toolbar a {
  color: white;
  background-color: #3795c2;
  margin: 0;
  padding: 8px;
  text-decoration: none;
  line-height: 28px;
  text-overflow: ellipsis;
  overflow: hidden;
}
#toolbar a.active {
  color: #3795c2;
  background-color: white;
}
.toolbar-link .v-icon {
  font-size: 12px;
}
.toolbar-link {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
