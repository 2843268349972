<template>
  <v-row>
    <v-col>
      <div v-if="jestime.prestations[jestime.fiche.prestation]">
        <h2>{{ jestime.prestations[jestime.fiche.prestation].nom }}</h2>
        <p>{{ descriptif }}</p>
      </div>
      <equation-renderer
        :equation="e"
        v-for="e in equations"
        :key="e.nom"
      ></equation-renderer>
      <div class="estimation-warn">
        Ce résultat est une estimation arrondie.
      </div>
      <div class="estimation-warn" v-if="anyOverridden">
        * Valeur non caclulée fournie par vous.
      </div>
      <v-btn
        v-if="
          (jestime.features.advanced_test_report || (auth && aut.loggedIn)) &&
            !test
        "
        outlined
        color="warning"
        @click="saveTestCase()"
        >Enregistrer comme cas test</v-btn
      >
      <span v-if="test"
        >Votre cas de test est bien enregistré avec le numéro #{{
          test.pk || 'non-enregistré'
        }}</span
      >
      <test-editor-dialog
        ref="dialog"
        v-if="test"
        :test="test"
      ></test-editor-dialog>
      <create-issue
        v-if="auth && auth.loggedIn"
        :test="test"
        outlined
      ></create-issue>
      <div
        v-if="
          (jestime.features.advanced_test_report || (auth && aut.loggedIn)) &&
            !test
        "
      >
        <p>
          Mode d'emploi pour l'enregistrement de cas test:
          <a @click="generateManualLink()" target="_blank"
            >Télécharger mode_emploi_test.pdf</a
          >
        </p>
      </div>
      <v-alert type="warning" class="mt-4">
        Le résultat du calcul indicatif des prestations qui pourraient vous être
        octroyées dépend des données que vous avez saisies. Il s'agit d'un
        calcul simplifié qui ne saurait prendre en compte la multiplicité des
        situations personnelles
      </v-alert>
      <v-btn dark :to="{ name: 'prestations' }">Retour aux prestations</v-btn>
      <link-calculateur
        :prestation="jestime.fiche.prestation"
      ></link-calculateur>
      <div class="address-block pa-3">
        <h2>
          {{
            total > 0
              ? 'Nous vous invitons à prendre contact avec les services compétents.'
              : 'Adresses de contact'
          }}
        </h2>
        <h3>Pour évaluer votre situation ou déposer une demande</h3>
        <v-row>
          <v-col
            sm="12"
            md="6"
            lg="4"
            v-for="(address, i) in addressesDepot"
            :key="`d_${i}`"
          >
            <app-address :address="address"></app-address>
          </v-col>
        </v-row>

        <h3>En cas de refus ou autre difficulté d’accès</h3>
        <v-row>
          <v-col
            sm="12"
            md="6"
            lg="4"
            v-for="(address, i) in addressesAide"
            :key="`a_${i}`"
          >
            <app-address :address="address"></app-address>
          </v-col>
        </v-row>
      </div>
      <div v-if="lien_canton && jestime.features.lien_canton">
        Pour davantage d'informations:
        <a
          :href="lien_canton"
          rel="noopener"
          target="_blank"
          class="open-external"
          >site officel <v-icon x-small>fas fa-external-link-alt</v-icon></a
        >
      </div>
      <div v-if="lien_gsr && jestime.features.lien_gsr">
        <a :href="lien_gsr" rel="noopener" target="_blank">
          Fiche de la prestation sur le Guide Social Romand:<br />
          <img class="logo-gsr" src="@/assets/logos/logo-gsr-couleur.svg" />
        </a>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import EquationRenderer from '@/components/model/EquationRenderer'
import Address from '@/components/Address'
import addresses from '@/services/addresses'
import TestEditorDialog from '@/components/modelTest/TestEditorDialog'
import LinkCalculateur from '@/components/LinkCalculateur'
import CreateIssue from '@/components/CreateIssue'
import { total } from '@/models/fiche'
import { PREFIX_OVERRIDE_CHECK } from '@/store/services/sim'
import { replacePrenomFromStateInText } from '@/utils/textTransform'
import axios from 'axios'
import i18n from '@/plugins/i18n'

export default {
  name: 'prestation-output',
  data() {
    return {
      addresses: [],
      test: null,
      modeEmploiUrl: null,
      sourcePk: 140
    }
  },
  mounted() {
    const slug = this.$store.state.jestime.prestations[
      this.$store.state.jestime.fiche.prestation
    ].slug
    this.$store.dispatch('logEstimation')
    this.$store.commit(
      'addDependencyCalculated',
      this.$store.state.jestime.fiche.nom
    )
    addresses.load().then(() => {
      this.addresses = addresses.findByCantonAndCommuneAndService(
        this.$store.state.sim.vm.lieuLogement.canton,
        this.$store.state.sim.vm.lieuLogement.no,
        slug
      )
    })
  },
  computed: {
    total() {
      return total(this.$store.state.jestime.fiche)
    },
    anyOverridden() {
      return this.equations.some(equation => {
        if (equation.scope === 'P') {
          return this.$store.state.sim.vm.personnes.some(
            p => p[`${PREFIX_OVERRIDE_CHECK}_${equation.nom}`]
          )
        } else {
          return !!this.$store.state.sim.vm[
            `${PREFIX_OVERRIDE_CHECK}_${equation.nom}`
          ]
        }
      })
    },
    addressesDepot() {
      return this.addresses.filter(a => a.type === 'd' || a.type === 'di')
    },
    addressesAide() {
      let addresses = this.addresses.filter(
        a => a.type === 'i' || a.type === 'di'
      )
      // hide Pro Senectute if nobody has age_avs
      if (
        this.$store.state.sim.vm &&
        !this.$store.state.sim.vm.personnes.some(p => p.a_age_avs)
      ) {
        addresses = addresses.filter(a => !a.nom.includes('Senectute'))
      }
      return addresses
    },
    auth() {
      return this.$store.state && this.$store.state.auth
    },
    jestime() {
      return this.$store.state && this.$store.state.jestime
    },
    lien_gsr() {
      return (
        this.jestime.fichesLookup[this.jestime.fiche.pk] &&
        this.jestime.fichesLookup[this.jestime.fiche.pk].lien_gsr
      )
    },
    lien_canton() {
      return (
        this.jestime.fichesLookup[this.jestime.fiche.pk] &&
        this.jestime.fichesLookup[this.jestime.fiche.pk].lien_canton
      )
    },
    descriptif() {
      const fiche = this.jestime.fichesLookup[this.jestime.fiche.pk]
      return fiche && fiche.descriptif
        ? fiche.descriptif
        : this.jestime.prestations[this.jestime.fiche.prestation].description
    },
    equations() {
      return this.$store.state.jestime.variables.filter(e => e.type === 'O')
    }
  },
  methods: {
    saveTestCase() {
      let descriptif = ''
      this.$store.state.jestime.variables
        .filter(e => e.type === 'O')
        .forEach(e => {
          if (e.scope === 'P') {
            descriptif += `\n${replacePrenomFromStateInText(
              this.$store.state,
              e.libelle
            )}\n`
            let personnes = this.$store.state.sim.vm.personnes
            // input_widget contains condition name to use as filter
            if (e.input_widget) {
              personnes = personnes.filter(p => p[e.input_widget])
            }
            personnes.forEach(p => {
              descriptif += `${p.prenom}: \n`
            })
          } else {
            descriptif += `${replacePrenomFromStateInText(
              this.$store.state,
              e.libelle
            )}: \n`
          }
        })

      this.$store
        .dispatch('createTestData', {
          canton: this.$store.state.jestime.fiche.canton,
          prestation: this.$store.state.jestime.fiche.prestation,
          descriptif: descriptif
        })
        .then(test => {
          this.test = test
          this.$nextTick(() => {
            this.$refs.dialog.show().catch(() => {
              if (!(this.auth && this.auth.loggedIn)) {
                this.test = null
              }
            })
          })
        })
    },
    generateManualLink() {
      axios
        .get(`/${i18n.locale}/api/source/${this.sourcePk}`)
        .then(response => {
          const source = response.data
          if (source.fichier) {
            const a = document.createElement('a')
            a.href = `/media/${source.fichier}`
            a.setAttribute('target', '_blank')
            a.click()
          }
        })
        .catch(e => {
          console.log(e)
        })
    }
  },
  components: {
    'equation-renderer': EquationRenderer,
    'app-address': Address,
    TestEditorDialog,
    LinkCalculateur,
    CreateIssue
  }
}
</script>

<style>
.address-block {
  background-color: #f5f5f5;
  margin: 20px auto;
}
.logo-gsr {
  background-color: #2b2e76;
  width: 250px;
  height: 92px;
  padding: 20px;
}
.estimation-warn {
  text-align: right;
  font-style: italic;
}
</style>
