<i18n>
{
  "fr": {
    "contrast": "contraste"
  },
  "de": {
    "contrast": "Kontrast"
  }
}
</i18n>
<template>
  <v-app-bar id="main-header" app dark flat>
    <v-container style="padding: 0" class="d-flex align-center">
      <v-toolbar-title>
        <h2 :class="{'text-h6': $vuetify.breakpoint.smAndDown}">
          <router-link tag="a" :to="{ name: 'home_public' }"
            >jestime.ch</router-link
          >
        </h2>
      </v-toolbar-title>
      <div class="subtitle">
        <span class="hidden-md-and-down"
          >{{ $t('slogan') }}</span
        >
      </div>
      <v-spacer></v-spacer>
      <v-btn  class="d-print-none" text :to="{ name: 'home_public' }" :icon="$vuetify.breakpoint.smAndDown" exact>
        <v-icon>fa fa-home</v-icon>
        <span class="hidden-sm-and-down">{{ $t('home') }}</span>
      </v-btn>
      <v-btn class="d-print-none" text :to="{ name: 'about' }" :icon="$vuetify.breakpoint.smAndDown">
        <v-icon>fa fa-info-circle</v-icon>
        <span class="hidden-sm-and-down">{{ $t('about') }}</span>
      </v-btn>
      <v-btn
       class="d-print-none"
        text
        :to="{ name: 'addresses' }"
        :icon="$vuetify.breakpoint.smAndDown"
      >
        <v-icon>fa fa-envelope</v-icon>
        <span class="hidden-sm-and-down">{{ $t('addresses') }}</span>
      </v-btn>
      <v-btn
       class="d-print-none"
        text
        :icon="$vuetify.breakpoint.smAndDown"
        @click="toggleContrast"
        :class="{'v-btn--active': $store.state.jestime.highContrastTheme}"
      >
        <v-icon>fa fa-adjust</v-icon>
        <span class="hidden-sm-and-down">{{ $t('contrast') }}</span>
      </v-btn>
      <v-menu bottom left v-if="!$vuetify.breakpoint.smAndDown">
        <template v-slot:activator="{ on }">
          <v-btn class="d-print-none" v-on="on" dark icon>
            <v-icon>fa fa-ellipsis-v</v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item v-if="auth && auth.loggedIn">
            <v-list-item-title>{{ auth.profile.email }}</v-list-item-title>
          </v-list-item>

          <v-divider v-if="auth && auth.loggedIn && $store.state.sim.vm"></v-divider>

          <v-list-item v-if="auth && auth.loggedIn" :to="{ name: 'home' }">
            <v-list-item-title>Fiches</v-list-item-title>
          </v-list-item>

          <v-list-item
            v-if="auth && auth.loggedIn"
            :to="{ name: 'global_variables' }"
          >
            <v-list-item-title>Global variables</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="auth && auth.loggedIn"
            :to="{ name: 'scenario_list' }"
          >
            <v-list-item-title>Scenarios</v-list-item-title>
          </v-list-item>

          <v-list-item
            v-if="auth && auth.loggedIn"
            href="/admin/"
            rel="noopener"
            target="_blank"
          >
            <v-list-item-title>Admin</v-list-item-title>
          </v-list-item>

          <v-divider v-if="auth && auth.loggedIn && $store.state.sim.vm"></v-divider>

          <v-list-item
            v-if="auth && auth.loggedIn"
            :to="{ name: 'logpreview' }"
          >
            <v-list-item-title>Log Preview</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="auth && auth.loggedIn"
            :to="{ name: 'sessionsummary' }"
          >
            <v-list-item-title>Session Summary</v-list-item-title>
          </v-list-item>

          <v-divider v-if="auth && auth.loggedIn && $store.state.sim.vm"></v-divider>

          <v-list-item
            v-if="auth && auth.loggedIn"
            :to="{ name: 'featureflags' }"
          >
            <v-list-item-title>Feature Flags</v-list-item-title>
          </v-list-item>

          <v-list-item
            v-if="auth && auth.loggedIn && $store.state.sim.vm"
            @click="$store.dispatch('updateSimValue', {nom: 'debug', value: $store.state.sim.vm.debug ? false : true })"
          >
            <v-list-item-title>{{ $store.state.sim.vm.debug ? 'Disable' : 'Enable' }} Debug</v-list-item-title>
          </v-list-item>

          <v-divider v-if="auth && auth.loggedIn && $store.state.sim.vm"></v-divider>

          <v-list-item v-if="auth && auth.loggedIn" @click="logout">
            <v-list-item-title>{{ $t('logout') }}</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="!auth || !auth.loggedIn" :to="{ name: 'home' }">
            <v-list-item-title>{{ $t('login') }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-container>
    <template v-slot:extension v-if="isToolbarVisible">
      <app-toolbar></app-toolbar>
    </template>
  </v-app-bar>
</template>

<script>
import Toolbar from '@/components/layout/Toolbar'

export default {
  name: 'app-header',
  computed: {
    auth() {
      return this.$store.state && this.$store.state.auth
    },
    isToolbarVisible() {
      return ['jestime', 'prestations'].includes(this.$route.name)
    }
  },
  methods: {
    logout() {
      this.$store.dispatch('logout')
    },
    toggleContrast() {
      this.$store.commit('setHighContrastTheme', !this.$store.state.jestime.highContrastTheme)
    }
  },
  components: {
    'app-toolbar': Toolbar
  }
}
</script>

<style>
#main-header {
  background-color: #225c77 !important;
}
#main-header .v-btn.v-btn--text i + span {
  margin-left: 10px;
}
#main-header h2 {
  color: white;
  font-size: 1.8em;
}
#main-header h2 a {
  color: white;
  text-decoration: none;
}

#main-header h2 a:hover {
  color: white;
  text-decoration: underline;
}

#main-header .subtitle {
  height: 23px;
  font-size: 18px;
  padding-left: 2em;
}

@media print{
  #main-header {
    height: 64px !important;
    position: relative !important;
  }
}
</style>
