<template>
  <div>
    <v-autocomplete
      class="location-home"
      v-model="lieuLogement"
      :items="items"
      cache-items
      :loading="isLoading"
      :search-input.sync="search"
      item-text="itemText"
      placeholder="Entrez votre NPA ou commune de domicile"
      no-data-text="Entrez une valeur pour commencer la recherche"
      clearable
      outlined
      return-object
    >
      <template v-slot:append-outer>
        <v-btn dark depressed class="px-8" @click="$emit('go')" :disabled="!(fichesLoaded && fichesCount > 0)">
           <v-icon>fa-search</v-icon>
        </v-btn>
      </template>
    </v-autocomplete>
    <v-alert :value="fichesLoaded && fichesCount === 0" type="error"
      >Aucune préstation actuellement disponible dans le système pour votre
      commune.</v-alert
    >
  </div>
</template>

<script>
import axios from 'axios'
import i18n from '@/plugins/i18n'

export default {
  name: 'LocationSelectorHome',
  data() {
    return {
      isLoading: false,
      items: [],
      search: null,
      fichesLoaded: false
    }
  },
  mounted() {
    if (this.lieuLogement) {
      this.items.push(this.lieuLogement)
      this.loadFiche(this.lieuLogement)
    }
  },
  computed: {
    sim() {
      return this.$store.state.sim
    },
    fichesCount() {
      return this.$store.state.jestime.fiches.length
    },
    lieuLogement: {
      get() {
        return this.sim.vm?.lieuLogement
      },
      set(value) {
        this.loadFiche(value)
        this.$store.dispatch('updateSimValue', {
          nom: 'lieuLogement',
          value
        }).then(() => {
          this.$emit('go')
        })
      }
    }
  },
  methods: {
    loadFiche(value) {
      if (value && value.canton) {
        console.debug('getFiches - LocationSelector')
        this.$store
          .dispatch('getFiches', {
            canton: value.canton
          })
          .then(() => {
            this.fichesLoaded = true
          })
      }
    }
  },
  watch: {
    search(val) {
      // Items have already been requested
      if (this.isLoading) return

      this.isLoading = true

      // Lazily load input items
      axios
        .get(`/${i18n.locale}/api/region?search=${val}`)
        .then((res) => {
          this.items = res.data.results.map((e) => {
            e.itemText = `${e.npa} ${e.localite}`
            if (e.commune !== e.localite) {
              e.itemText += ` (${e.commune})`
            }
            return e
          })
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => (this.isLoading = false))
    }
  }
}
</script>

<style>
.location-home .v-input__append-outer {
  margin: 0 !important;
}
.location-home .v-input__append-outer .v-btn {
  height: 56px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.location-home .theme--dark.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
  background-color: #272727 !important;
}

.location-home fieldset {
  border-right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
</style>
