import Vue from 'vue'
import axios from 'axios'
import router from '@/router'
import i18n from '@/plugins/i18n'
import { cleanEquation } from '@/models/equation'

const state = {
  idMainPrestation: 12,
  highContrastTheme: false,
  cantons: {},
  cantonsNames: {

  },
  prestations: {},
  sources: {},
  fiche: {},
  fiches: [],
  fichesConditions: {},
  fichesOverrides: {},
  fichesLookup: {},
  variables: [],
  variableDescriptions: {},
  globalVariables: {
    fichesCount: {
      nom: 'fichesCount'
    }
  },
  depVariables: [],
  lookupLoaded: false,
  scenario: {},
  scenarios: [],
  scenarioParent: [],
  calculateurs: [],
  features: {},
  featureFlags: []
}

const LOCAL_STORAGE_FEATURES_KEY = 'jestime_features'

const getters = {
  globalVariableNames(state) {
    return Object.keys(state.globalVariables)
  },
  allVariables(state) {
    return Object.assign(
      {},
      state.globalVariables,
      state.depVariables.reduce((dic, v) => {
        dic[v.nom] = v
        return dic
      }, {}),
      state.variables.reduce((dic, v) => {
        dic[v.nom] = v
        return dic
      }, {})
    )
  },
  featureFlags(state) {
    return state.featureFlags.map(f => {
      const clone = Object.assign({}, f)
      clone.overridden = f.enabled !== state.features[f.name]
      clone.enabled = state.features[f.name]
      return clone
    })
  }
}

function buildPKLookup(list) {
  return list.reduce((lookup, obj) => {
    lookup[obj.pk] = obj
    return lookup
  }, {})
}

const mutations = {
  setHighContrastTheme(state, value) {
    state.highContrastTheme = value
  },
  setCantons(state, cantons) {
    state.cantons = buildPKLookup(cantons)
    state.cantonsNames = cantons.reduce((cantons, c) => {
      cantons[c.slug] = c.nom
      return cantons
    }, {})
  },
  setPrestations(state, prestations) {
    state.prestations = buildPKLookup(prestations)
  },
  setSources(state, sources) {
    state.sources = buildPKLookup(sources)
  },
  setFicheCriteres(state, payload) {
    state.fiche.criteres_eligibilite_json = payload
  },
  setFiche(state, payload) {
    state.fiche = payload.fiche
    state.variables = payload.fiche.variables
    state.depVariables = payload.depVariables
  },
  setFiches(state, fiches) {
    state.fiches = fiches
    state.fichesLookup = buildPKLookup(fiches)
  },
  setScenario(state, scenario) {
    state.scenario = scenario
  },
  setScenarios(state, scenarios) {
    state.scenarios = scenarios
  },
  setCalculateurs(state, calculateurs) {
    state.calculateurs = calculateurs
  },
  setVariableDescriptions(state, variableDescriptions) {
    state.variableDescriptions = variableDescriptions.reduce((lookup, obj) => {
      lookup[obj.nom] = obj
      return lookup
    }, {})
  },
  addGlobalVariables(state, variables) {
    variables.forEach(e => {
      Vue.set(state.globalVariables, e.nom, e)
    })
  },
  setLookupLoaded(state, isLoaded) {
    state.lookupLoaded = isLoaded
  },
  addToLookup(state, payload) {
    state[payload.key][payload.value.pk] = payload.value
  },
  setScenarioParent(state, value) {
    state.scenarioParent = value
  },
  updateFichesConditions(state, fiche) {
    const conditions = fiche.variables.filter(e => e.type === 'W')
    Vue.set(state.fichesConditions, fiche.pk, conditions)
    const overrides = fiche.variables.filter(e => e.type === 'O')
    Vue.set(state.fichesOverrides, fiche.pk, overrides)
  },
  setFeatures(state, featureFlags) {
    state.featureFlags = featureFlags
    const backendFeatures = featureFlags.reduce((lookup, obj) => {
      lookup[obj.name] = obj.enabled
      return lookup
    }, {})
    const localFeatures = JSON.parse(localStorage.getItem(LOCAL_STORAGE_FEATURES_KEY) || '{}')
    state.features = Object.assign(backendFeatures, localFeatures)
  },
  toggleFeatureLocally(state, featureName) {
    state.features[featureName] = !state.features[featureName]
    localStorage.setItem(LOCAL_STORAGE_FEATURES_KEY, JSON.stringify(state.features))
  }
}

function addDepVariables(fiche, level = 0) {
  // add recursive dependencies starting with leaf
  let variables = fiche.depend_de
    .filter(f => f.actuel)
    .reduce(
      (dependencies, f) => dependencies.concat(addDepVariables(f, level + 1)),
      []
    )
  // add self
  if (level > 0) {
    variables = variables.concat(
      [
        {
          nom: fiche.nom,
          type: 'D',
          pk: fiche.pk
        }
      ],
      fiche.variables
    )
  }
  return variables
}

const actions = {
  saveVariables(context, payload) {
    return axios
      .post(
        `/${i18n.locale}/api/fiche/${payload.fiche_id}/variables`,
        payload.variables
      )
      .then(response => {
        router.push({ name: 'model', params: { pk: response.data } })
      })
      .catch(e => {
        console.log('ERROR saving:', e.response.data)
        throw e
      })
  },
  getBaseData(context) {
    return Promise.all([
      axios.get(`/${i18n.locale}/api/prestation/`).then(response => {
        context.commit('setPrestations', response.data)
      }),
      axios.get(`/${i18n.locale}/api/canton/`).then(response => {
        context.commit('setCantons', response.data)
      })
    ])
      .then(() => {
        context.commit('setLookupLoaded', true)
      })
      .catch(e => {
        context.commit('setLookupLoaded', false)
        console.log(e)
      })
  },
  getAdminBaseData(context) {
    return Promise.all([
      axios.get(`/${i18n.locale}/api/source/`).then(response => {
        context.commit('setSources', response.data)
      })
    ])
      .then(() => {
        context.commit('setLookupLoaded', true)
      })
      .catch(e => {
        context.commit('setLookupLoaded', false)
        console.log(e)
      })
  },
  getFiche(context, payload) {
    let url = `/${i18n.locale}/api/ficherecursive/`
    if (payload.pk) {
      url += payload.pk
    } else {
      url += '?actuel=true'
      if (payload.canton) {
        url += `&canton=${payload.canton}`
      }
      if (payload.prestation) {
        url += `&prestation=${payload.prestation}`
      }
    }

    return axios
      .get(url)
      .then(response => {
        let fiche = response.data
        if (!payload.pk) {
          if (fiche.length === 0) {
            // set empty fiche
            context.commit('setFiche', {
              fiche: { variables: [] },
              depVariables: []
            })
            return
          }
          fiche = fiche[0]
        }
        const depVariables = addDepVariables(fiche)
        if (payload.onlyVariables) {
          // initial loading to get all condition varibables trigger from getFiches
          context.commit('updateFichesConditions', fiche)
        } else {
          // normal loading for a scenario or editing
          context.commit('setFiche', { fiche, depVariables })
        }

        for (const e of depVariables) {
          context.commit('addEquation', e)
        }
        for (const e of fiche.variables) {
          context.commit('addEquation', e)
        }
        context.commit('initVM')
        return fiche
      })
      .catch(e => {
        console.log(e)
      })
  },
  getFiches(context, payload) {
    let url = `/${i18n.locale}/api/fiche/?actuel=True`
    if (payload && payload.canton) {
      url += `&publique=true&canton__slug=${payload.canton}`
    }
    return axios
      .get(url)
      .then(response => {
        context.commit('setFiches', response.data)
        context.dispatch('getVariableDescriptions', payload)
        context.dispatch('getCalculateurs', payload)
        context.dispatch('updateSimValue', {
          nom: 'fichesCount',
          value: response.data.length
        })
        if (payload && payload.canton) {
          for (const fiche of response.data) {
            context.dispatch('getFiche', { pk: fiche.pk, onlyVariables: true })
          }
        }
        return response.data
      })
      .catch(e => {
        console.log(e)
      })
  },
  getArticles(context, payload) {
    return axios
      .get(`/${i18n.locale}/api/articleloi?source=${payload}`)
      .then(response => {
        return response.data
      })
      .catch(e => {
        console.log(e)
      })
  },
  getGlobalVariables(context) {
    return axios
      .get(`/${i18n.locale}/api/global_variable`)
      .then(response => {
        context.commit('addGlobalVariables', response.data)
        for (const e of response.data) {
          context.commit('addEquation', e)
        }
        context.commit('initVM')
        return response.data
      })
      .catch(e => {
        console.log(e)
      })
  },
  getVariableDescriptions(context, payload) {
    let url = `/${i18n.locale}/api/variable_description/`
    if (payload && payload.canton) {
      url += `?canton__slug=${payload.canton}`
    }
    return axios
      .get(url)
      .then(response => {
        context.commit('setVariableDescriptions', response.data)
        return response.data
      })
      .catch(e => {
        console.log(e)
      })
  },
  getScenarios(context) {
    return axios
      .get(`/${i18n.locale}/api/scenarios`)
      .then(response => {
        context.commit('setScenarios', response.data)
        return response.data
      })
      .catch(e => {
        console.log(e)
      })
  },
  createScenario(context, payload) {
    return axios
      .post(`/${i18n.locale}/api/scenarios/`, payload)
      .then(response => {
        context.commit('setScenario', response.data)
        return response.data
      })
      .catch(e => {
        console.log(e)
      })
  },
  getScenario(context, payload) {
    let url = `/${i18n.locale}/api/scenarios/`
    if (payload.pk) {
      url += payload.pk
    } else {
      url += '?actuel=true'
      if (payload.canton__slug) {
        url += `&canton__slug=${payload.canton__slug}`
      }
      if (payload.canton) {
        url += `&canton=${payload.canton}`
      }
      if (payload.prestation) {
        url += `&prestation=${payload.prestation}`
      }
    }

    return axios
      .get(url)
      .then(response => {
        let scenario = response.data
        if (!payload.pk) {
          if (scenario.length === 0) {
            scenario = {}
          }
          scenario = scenario[0]
        }
        context.commit('setScenario', scenario)
        if (scenario.canton && scenario.prestation) {
          return context.dispatch('getFiche', {
            canton: scenario.canton,
            prestation: scenario.prestation
          }).then((fiche) => {
            return {
              scenario,
              fiche
            }
          })
        } else {
          // set empty fiche
          context.commit('setFiche', {
            fiche: { variables: [] },
            depVariables: []
          })
        }
        return { scenario, fiche: null }
      })
  },
  getCalculateurs(context, payload) {
    let url = `/${i18n.locale}/api/calculateur/`
    if (payload && payload.canton) {
      url += `?canton__slug__in=${payload.canton},CH`
    }
    return axios
      .get(url)
      .then(response => {
        context.commit('setCalculateurs', response.data)
        return response.data
      })
      .catch(e => {
        console.log(e)
      })
  },
  updateScenario(context, payload) {
    return axios
      .patch(`/${i18n.locale}/api/scenarios/${payload.pk}`, payload)
      .then(response => {
        context.commit('setScenario', response.data)
        return response.data
      })
      .catch(e => {
        console.log(e)
      })
  },
  createSource(context, payload) {
    return axios
      .post(`/${i18n.locale}/api/source/`, payload)
      .then(response => {
        context.commit('addToLookup', {
          key: 'sources',
          value: response.data
        })
        return response.data
      })
      .catch(e => {
        console.log(e)
      })
  },
  createArticle(context, payload) {
    return axios
      .post(`/${i18n.locale}/api/articleloi/`, payload)
      .then(response => {
        return response.data
      })
      .catch(e => {
        console.log(e)
      })
  },
  createGlobalVariable(context, payload) {
    payload = cleanEquation(payload)
    return axios
      .post(`/${i18n.locale}/api/global_variable/`, payload)
      .then(response => {
        context.commit('addGlobalVariables', [response.data])
        return response.data
      })
  },
  updateGlobalVariable(context, payload) {
    return axios
      .patch(`/${i18n.locale}/api/global_variable/${payload.pk}`, payload)
      .then(response => {
        context.commit('addGlobalVariables', [response.data])
        return response.data
      })
      .catch(e => {
        console.log(e)
      })
  },
  updateFiche(context, payload) {
    return axios
      .patch(`/${i18n.locale}/api/fiche/${payload.pk}`, payload)
      .then(response => {
        context.commit(
          'setFicheCriteres',
          response.data.criteres_eligibilite_json
        )
        return response.data
      })
      .catch(e => {
        console.log(e)
      })
  },
  getFeatureFlags(context) {
    return axios
      .get(`/${i18n.locale}/api/featureflag`)
      .then(response => {
        context.commit('setFeatures', response.data)
        return response.data
      })
      .catch(e => {
        console.log(e)
      })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
