<template>
    <div :class="jestime.prestations[fiche.prestation].slug" class="prestation-summary">
      <div class="prestation-summary-header d-flex">
        <v-icon>{{jestime.prestations[fiche.prestation].icon_class}}</v-icon>
        <h3>{{fiche.nom}}
          <router-link v-if="$store.state.sim.vm.debug" :to="{name:'model', params:{pk: fiche.pk}}"><v-icon small>fa-pen</v-icon></router-link>
        </h3>
      </div>

      <div v-if="available" class="px-3">
        <div v-if="( isCalculated || allOverridden  ) && total !== -1" >
          <div class="prestation-summary-main">
            <div class="big-title primary-color">
              {{total | CHF('')}}
              <span>CHF / mois</span>
            </div>
            <p class="estimation-warn" v-if="anyOverridden">
                  * Contient une valeur non caclulée fournie par vous.
            </p>
            <p v-if="additionalResult" class="additional-result">{{additionalResult}}</p>
          </div>

          <v-btn
          dark block depressed
          v-if="isCalculated && !allOverridden"
          :to="{name:'jestime', query: {canton: fiche.canton, prestation: fiche.prestation}}"
          class="btn-estimer d-print-none"
        >Recommencer l'estimation</v-btn>
        </div>
        <div v-else>
          <div class="prestation-summary-main">
            <div class="big-title primary-color">À calculer</div>
          </div>
          <v-btn
          dark block depressed
          :to="{name:'jestime', query: {canton: fiche.canton, prestation: fiche.prestation}}"
          class="btn-estimer d-print-none">Estimer</v-btn>
        </div>
        <div class="prestation-summary-main" v-if="fiche && jestime.fichesConditions[fiche.pk] && jestime.fichesConditions[fiche.pk].filter(c => $store.state.sim.vm[c.nom]).length > 0">
            <div v-for="c in jestime.fichesConditions[fiche.pk].filter(c => $store.state.sim.vm[c.nom] && c.input_widget === 'available')" :key="c.pk">
              <div>{{c.description}}</div>
            </div>
        </div>
      </div>
      <div v-else class="px-3">
        <div class="prestation-summary-main">
          <div v-if="fiche && jestime.fichesConditions[fiche.pk] && jestime.fichesConditions[fiche.pk].filter(c => !$store.state.sim.vm[c.nom]).length > 0">
            <div v-for="c in [jestime.fichesConditions[fiche.pk].filter(c => !$store.state.sim.vm[c.nom])[0]]" :key="c.pk">
              <div class="small-title primary-color" v-html="c.input_widget === 'no_right' ? 'Aucun droit à cette prestation' : '&nbsp;'"></div>
              <div>{{c.description}}</div>
            </div>
          </div>
          <div v-else>
              <div class="small-title primary-color">Aucun droit à cette prestation</div>
              <div v-html="fiche.criteres_eligibilite"></div>
          </div>
        </div>
      </div>
      <link-calculateur class="px-3 d-print-none" :prestation="fiche.prestation" style="font-size: 12px"></link-calculateur>

    </div>
</template>

<script>
import LinkCalculateur from '@/components/LinkCalculateur'
import { prestationSlug, isCalculated, available, total, allOverridden, anyOverridden } from '@/models/fiche'

export default {
  name: 'PrestationSummary',
  props: ['fiche'],
  computed: {
    jestime() {
      return this.$store.state && this.$store.state.jestime
    },
    prestationSlug() {
      return prestationSlug(this.fiche)
    },
    available() {
      return available(this.fiche)
    },
    isCalculated() {
      return isCalculated(this.fiche)
    },
    allOverridden() {
      return allOverridden(this.fiche)
    },
    anyOverridden() {
      return anyOverridden(this.fiche)
    },
    total() {
      return total(this.fiche)
    },
    additionalResult() {
      if (this.$store.state.sim.vm && this.$store.state.sim.vm[`total2_${this.prestationSlug}`] !== undefined) {
        return this.$store.state.sim.vm[`total2_${this.prestationSlug}`]
      }
      return false
    }

  },
  components: {
    LinkCalculateur
  }
}
</script>

<style>
.contact-error {
  font-size: 10px;
  position: absolute;
  bottom: 8px;
  left: 12px;
}

.big-title {
  font-size: 34px;
  font-weight: normal;
  text-align: center;
  margin-bottom: 12px;
}

.small-title {
  line-height: 1.2;
  font-size: 28px;
  font-weight: normal;
  text-align: center;
  margin-bottom: 12px;
}

.big-title span {
  font-size: 18px;
  color: rgba(0, 0, 0, 0.87);
}

.prestation-summary {
  text-align: left;
  border-radius: 10px;
  background-color: #F4F4F4;
  height: 100%;
  position: relative;
  min-height: 380px;
}

.prestation-summary-header {
  border-bottom: 1px solid #B4B4B4;
  margin-bottom: 1em;
  margin-top: 40px;
  padding: 16px;
}

.prestation-summary-header h3 {
  min-height: 1em;
  text-align: left;
  line-height: 1.2;
}

.prestation-summary-header .v-icon svg {
  height: 40px;
  font-size: 40px;
  width: auto;
  color: #3795C2;
  margin-right: 20px;
}

.prestation-summary-main {
  min-height: 100px;
  font-size: 14px;
}

.prestation-summary-main .additional-result {
  text-align: center;
}

@media (min-width: 960px) {
  .prestation-summary-header h3 {
    min-height: 2.8em;
  }
}
@media (min-width: 1264px) {
  .prestation-summary-header h3 {
    min-height: 3.5em;
  }
}

@media (min-width: 1904px) {
  .prestation-summary-header h3 {
    min-height: 1em;
  }
}

@media print {
  .big-title {
    color: black !important;
    font-size: 16pt;
  }
  .big-title span {
    color: black !important;
    min-height: 0;
    margin-top: 5pt;
    margin-bottom: 5pt;
    font-size: 16pt;
    background: transparent;
    text-align: center;
  }
  .small-title {
    color: black !important;
    font-size: 16pt;
    margin-top: 5pt;
    margin-bottom: 5pt;
    text-align: center;
  }
  .prestation-summary-header .v-icon svg {
    font-size: 16pt;
    height: 20px;
  }
  .prestation-summary-header {
    margin: 0 !important;
    min-height: 0px;
    color: black;
    padding-bottom: 5px;
    font-size: 14pt !important;
  }
  .prestation-summary-main {
    min-height: 0px;
    color: black;
    padding: 0 !important;
  }
  .prestation-summary {
    min-height: 0px;
    break-inside: avoid;
    color: black;
    display: block;
    background-color: transparent;
  }
  .link-calculateur {
    display: none;
  }
  footer {
    display: none;
  }
  #main-header {
    display: block;
  }
  .v-main{
    vertical-align: top;
    padding: 0;
    margin: 0;
    top: 0;
  }
}
</style>
