import Vue from 'vue'
import VueRouter from 'vue-router'

import HomePublic from '@/components/pages/HomePublic'
import Addresses from '@/components/pages/Addresses'
import About from '@/components/pages/About'

import ScenarioRenderer from '@/components/scenarios/ScenarioRenderer'
import Prestations from '@/components/pages/wizard/Prestations'
import Survey from '@/components/pages/Survey'
import store from '@/store'

const routes = [
  {
    path: '/',
    name: 'home_public',
    component: HomePublic
  },
  {
    path: '/prestations',
    name: 'prestations',
    component: Prestations
  },
  {
    path: '/about',
    name: 'about',
    component: About
  },
  {
    path: '/adresses',
    name: 'addresses',
    component: Addresses
  },
  {
    path: '/jestime',
    name: 'jestime',
    component: ScenarioRenderer,
    meta: {
      analytics: {
        pageviewTemplate(route) {
          const canton = store.state.jestime.cantons[route.query.canton]
          const prestation = store.state.jestime.prestations[route.query.prestation]
          let page = `/${canton ? canton.slug : route.query.canton}/${prestation ? prestation.slug : route.query.prestation}`
          if (route.query.hasOwnProperty('step')) {
            let step = route.query.step
            if (store.state.jestime.scenario && store.state.jestime.scenario.steps) {
              // eslint-disable-next-line
              if (route.query.step == store.state.jestime.scenario.steps.length) {
                step = 'Estimations'
              } else if (store.state.jestime.scenario.steps[route.query.step]) {
                step = store.state.jestime.scenario.steps[route.query.step].name
              }
            }
            page += `/${step}`
          }
          return {
            page
          }
        }
      }
    }
  },
  {
    path: '/survey',
    name: 'survey',
    component: Survey
  },
  {
    path: '/fiche',
    name: 'home',
    component: () => import(/* webpackChunkName: "model" */ '@/components/pages/Home.vue')
  },
  {
    path: '/fiche/:pk',
    name: 'model',
    component: () => import(/* webpackChunkName: "model" */ '@/components/pages/Model.vue')
  },
  {
    path: '/global_variables',
    name: 'global_variables',
    component: () => import(/* webpackChunkName: "model" */ '@/components/pages/GlobalVariables.vue')
  },
  {
    path: '/fiche/:pk/print',
    name: 'modelPrint',
    component: () => import(/* webpackChunkName: "modelPrint" */ '@/components/pages/ModelPrint.vue')
  },
  {
    path: '/parsedebug',
    name: 'debug_parse',
    component: () => import(/* webpackChunkName: "parsedebug" */ '@/components/pages/DebugParse.vue')
  },
  {
    path: '/scenario',
    name: 'scenario_list',
    component: () => import(/* webpackChunkName: "scenario" */ '@/components/pages/ScenarioList.vue')
  },
  {
    path: '/scenario/:pk',
    name: 'scenario',
    component: () => import(/* webpackChunkName: "scenario" */ '@/components/pages/Scenario.vue')
  },
  {
    path: '/logpreview',
    name: 'logpreview',
    component: () => import(/* webpackChunkName: "logpreview" */ '@/components/pages/LogPreview.vue')
  },
  {
    path: '/sessionsummary',
    name: 'sessionsummary',
    component: () => import(/* webpackChunkName: "logpreview" */ '@/components/pages/SessionSummary.vue')
  },
  {
    path: '/featureflags',
    name: 'featureflags',
    component: () => import(/* webpackChunkName: "logpreview" */ '@/components/pages/FeatureFlags.vue')
  },
  {
    path: '/strings',
    name: 'strings',
    component: () => import(/* webpackChunkName: "strings" */ '@/components/pages/Strings.vue')
  }
]

Vue.use(VueRouter)
const router = new VueRouter({
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  },
  mode: 'history',
  routes
})

export default router
