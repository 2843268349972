<i18n>
{
  "fr": {
    "warn_ie": "Depuis février 2019, Microsoft conseille de ne plus utiliser Internet Explorer. Le site fonctionne mieux sous Chrome, Firefox ou la dernière version de Edge.",
    "warn_prod": "Attention! SITE PRODUCTION modifier les données sur <a href='https://dev.jestime.ch/'>dev.jestime.ch</a>."
  },
  "de": {
    "warn_ie": "Ab Februar 2019 rät Microsoft, Internet Explorer nicht mehr zu verwenden. Die Website funktioniert am besten unter Chrome, Firefox oder die neueste Version von Edge.",
    "warn_prod": "Vorsicht! PRODUCTION SITE Ändern Sie die Daten auf <a href='https://dev.jestime.ch/'> dev.jestime.ch </a>."
  }
}
</i18n>
<template lang="html">
  <v-app id="app" :class="{'high-contrast-theme': $store.state.jestime.highContrastTheme}">
    <v-snackbar v-if="snackbarTimeout < 0" centered :timeout="-1" v-model="snackbar" color="primary" class="snackbar-dialog">
      {{ snackbarText }}
      <v-btn @click="snackbar=false">OK</v-btn>
    </v-snackbar>
    <v-snackbar v-else top right :timeout="snackbarTimeout" v-model="snackbar">
      {{ snackbarText }}
    </v-snackbar>
    <v-alert
      v-if="$store.state.jestime.features.warn_msg"
      type="warning"
      tile
      dense
      style="position:fixed;left:0;right:0;z-index:5"
    >
      {{ $store.state.jestime.featureFlags.find(f => f.name === 'warn_msg').description }}
    </v-alert>
    <v-alert
      v-if="isIE"
      type="error"
      tile
      dense
      style="position:fixed;left:0;right:0;z-index:5"
    >
      {{ $t('warn_ie') }}
    </v-alert>
    <v-alert
      v-if="isEditingProd"
      type="error"
      tile
      dense
      style="position:fixed;left:0;right:0;z-index:5"
      v-html="$t('warn_prod')"
    ></v-alert>
    <app-header :style="hasAlert ? 'margin-top: 40px' : ''"></app-header>
    <v-main :style="hasAlert ? 'margin-top: 40px' : ''">
      <v-progress-linear
        :active="$root.loading"
        indeterminate
      ></v-progress-linear>
      <router-view />
    </v-main>
    <app-footer></app-footer>
  </v-app>
</template>

<script>
import Header from '@/components/layout/Header'
import Footer from '@/components/layout/Footer'

export default {
  name: 'App',
  data: () => ({
    // eslint-disable-next-line
    commitHash: COMMITHASH,
    snackbar: false,
    snackbarTimeout: 3000,
    snackbarText: '',
    isIE: /MSIE|Trident/.test(window.navigator.userAgent)
  }),
  computed: {
    hasAlert() {
      return this.$store.state.jestime.features.warn_msg || this.isEditingProd || this.isIE
    },
    auth() {
      return this.$store.state && this.$store.state.auth
    },
    jestime() {
      return this.$store.state && this.$store.state.jestime
    },
    isEditingProd() {
      const safeRoutes = [
        'home_public',
        'prestations',
        'about',
        'addresses',
        'jestime',
        'survey'
      ]
      return (
        this.auth &&
        this.auth.loggedIn &&
        !safeRoutes.includes(this.$route.name) &&
        window.location.host === 'jestime.ch'
      )
    }
  },
  created() {
    this.$store.dispatch('getBaseData')
    this.$store.dispatch('getProfile')
    this.$store.dispatch('getGlobalVariables')
    this.$store.dispatch('getFeatureFlags')
    this.$root.$on('snackbar', payload => {
      if (payload.text) {
        this.snackbarText = payload.text
        this.snackbarTimeout = payload.timeout
      } else {
        this.snackbarText = payload
        this.snackbarTimeout = 3000
      }
      this.snackbar = true
    })
  },
  watch: {
    '$i18n.locale'(value) {
      // reload data with new locales
      this.$store.dispatch('getBaseData')
    }
  },
  components: {
    'app-header': Header,
    'app-footer': Footer
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,700');
@font-face {
  font-family: 'nowbold';
  src: url('assets/fonts/now-bold-webfont.woff2') format('woff2'),
    url('assets/fonts/now-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
    font-family: 'Now-Light';
    src:url('assets/fonts/Now-Light.ttf.woff') format('woff'),
        url('assets/fonts/Now-Light.ttf.svg#Now-Light') format('svg'),
        url('assets/fonts/Now-Light.ttf.eot'),
        url('assets/fonts/Now-Light.ttf.eot?#iefix') format('embedded-opentype');
    font-weight: normal;
    font-style: normal;
}
.font-now-light {
  font-family: 'Now-Light', 'Open Sans', Roboto, 'Helvetica Neue', sans-serif;
  font-weight: normal;
}

.font-now-bold {
  font-family: 'nowbold', 'Open Sans', Roboto, 'Helvetica Neue', sans-serif;
  font-weight: normal;
}
.v-application {
  font-family: 'Open Sans', Roboto, 'Helvetica Neue', sans-serif;
}
.theme--light.v-application {
  background: white;
}
h1,
h2,
h3 {
  font-family: 'nowbold', 'Open Sans', Roboto, 'Helvetica Neue', sans-serif;
  font-weight: normal;
  color: #3795c2;
}

.j-title {
  font-family: 'Open Sans', Roboto, 'Helvetica Neue', sans-serif;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.87);
}

h1 {
  font-size: 38px;
}

.primary-color {
  color: #3795c2;
}

a {
  color: #3795c2;
}

.ck-heading_heading1,
.ck-heading_heading2 {
  color: #3795c2 !important;
}

.ck-heading_paragraph_warn,
p.warn {
  color: #ff9800 !important;
}

.ck-heading_paragraph_stop,
p.stop {
  color: #ef4915 !important;
}

.v-toolbar {
  z-index: 4;
}

.v-messages {
  font-size: 13px;
}

.v-messages__message:not(:empty) {
  margin-bottom: 10px;
}

.v-btn {
  text-transform: none !important;
  font-weight: bold;
}

.equation.has-hint .v-text-field__details {
  margin-bottom: 16px;
}

.v-text-field .v-label--active {
  -webkit-transform: translateY(-20px) scale(0.9);
  transform: translateY(-20px) scale(0.9);
}

.bottom-big-deco {
  text-align: center;
  margin: 3em 0 1em 0;
}

.bottom-big-deco img {
  width: 70%;
}

.snackbar-dialog .v-snack__wrapper  {
  min-width: 600px;
  min-height: 300px;
}

html,
body {
  text-size-adjust: 100%;
  font-size: 16px;
}
/* ie 11*/
.v-btn__content {
  max-width: 100%;
}
.v-btn__content .layout.column {
  width: inherit;
}

.theme--light.v-label {
  color: #333;
}

.high-contrast-theme.theme--light.v-application,
.high-contrast-theme .v-expansion-panels .v-expansion-panel,
.high-contrast-theme .sv-root-modern .sv-container-modern {
  background: #000 !important;
  color: #fff !important;
}

.high-contrast-theme .sv-root-modern .sv-radio__svg {
  border-color: #fff !important;
}

.high-contrast-theme .sv-root-modern .sv-radio--checked .sv-radio__svg {
  border-color: #fcff3c !important;
  fill: #fcff3c !important;
}

.high-contrast-theme .sv-root-modern .sv-footer__next-btn {
  background-color: #600040 !important;
}

.high-contrast-theme .v-list,
.high-contrast-theme .v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
  background: #000 !important;
  color: #fff !important;
}

.high-contrast-theme .v-expansion-panels button {
  border: 1px solid #fff !important;
  color: #fcff3c !important;
  padding: 4px;
}

.high-contrast-theme .theme--light.v-label {
  color: #fff;
}

.high-contrast-theme #main-header,
.high-contrast-theme #toolbar a.active,
.high-contrast-theme .blue-background {
  background-color: #000 !important;
}

.high-contrast-theme .home-top-bg {
  background: #000 !important;
}

.high-contrast-theme .blue-background .v-image,
.high-contrast-theme .home-middle-filler {
  display: none;
}

.high-contrast-theme a,
.high-contrast-theme h1,
.high-contrast-theme h2,
.high-contrast-theme h3,
.high-contrast-theme h4,
.high-contrast-theme .open-external .v-icon,
.high-contrast-theme.v-application .primary--text,
.high-contrast-theme .prestation-summary-header .v-icon svg,
.high-contrast-theme .primary-color {
  color: #fcff3c !important;
}

.high-contrast-theme #toolbar,
.high-contrast-theme #toolbar a,
.high-contrast-theme footer,
.high-contrast-theme footer h3,
.high-contrast-theme footer a,
.high-contrast-theme .box-background,
.high-contrast-theme .helpBox,
.high-contrast-theme .prestation-summary,
.high-contrast-theme .v-card,
.high-contrast-theme .v-card__text,
.high-contrast-theme .address-block,
.high-contrast-theme .prestation-item {
  background-color: #696969 !important;
  color: #fff !important;
}
.high-contrast-theme input,
.high-contrast-theme .v-input,
.high-contrast-theme .v-input input,
.high-contrast-theme .v-input textarea,
.high-contrast-theme textarea,
.high-contrast-theme .v-select__selections,
.high-contrast-theme .v-select__selection--comma,
.high-contrast-theme .v-icon,
.high-contrast-theme .v-messages,
.high-contrast-theme ::placeholder {
  color: #fff !important;
}

.high-contrast-theme .v-item--active,
.high-contrast-theme .v-item--active .theme--light.v-icon {
  color: #fcff3c !important;
}

.high-contrast-theme .v-text-field__slot,
.high-contrast-theme .v-select__slot {
  border: 1px solid #fff !important;
  padding: 4px;
}

.high-contrast-theme .v-text-field__slot label,
.high-contrast-theme .v-select__slot label {
  margin-top: -10px;
}

.high-contrast-theme .main-direct-access > div > .v-icon svg {
  color: #fcff3c !important;
}

.high-contrast-theme .v-btn,
.high-contrast-theme .avatar-box-inner {
  color: #fff !important;
  background-color: #600040 !important;
}
.high-contrast-theme .arrow-down {
  border-top-color: #600040 !important;
}

.high-contrast-theme.v-application .primary {
  background-color: #600040 !important;
  border-color: #600040 !important;
}

.high-contrast-theme .avatar-box .fa.circle {
  border-color: #fff;
}

.high-contrast-theme .avatar-box-inner.add .v-btn__content {
  color: #fff !important;
}
@media print {
  .v-snackbar{
    display: none;
  }
  .v-alert {
    display: none;
  }
  v-progress-linear {
    display: none;
  }
  .v-main {
    margin: 0 !important;
    padding-top: 0 !important;
    vertical-align: top;
  }
}
</style>
