<template>
  <div>
    <v-autocomplete
      v-model="lieuLogement"
      :items="items"
      cache-items
      :loading="isLoading"
      :search-input.sync="search"
      item-text="itemText"
      label="Où habitez-vous?"
      placeholder="Entrez votre numéro postal"
      no-data-text="Entrez une valeur pour commencer la recherche"
      hint="Indiquez votre domicile légal"
      persistent-hint
      return-object
    ></v-autocomplete>
    <v-alert :value="fichesLoaded && fichesCount === 0" type="error"
      >Aucune préstation actuellement disponible dans le système pour votre
      commune.</v-alert
    >
  </div>
</template>

<script>
import axios from 'axios'
import i18n from '@/plugins/i18n'

export default {
  name: 'LocationSelector',
  data() {
    return {
      isLoading: false,
      items: [],
      search: null,
      fichesLoaded: false
    }
  },
  mounted() {
    if (this.lieuLogement) {
      this.items.push(this.lieuLogement)
      this.loadFiche(this.lieuLogement)
    }
  },
  computed: {
    sim() {
      return this.$store.state.sim
    },
    fichesCount() {
      return this.$store.state.jestime.fiches.length
    },
    lieuLogement: {
      get() {
        return this.sim.vm?.lieuLogement
      },
      set(value) {
        this.loadFiche(value)
        this.$store.dispatch('updateSimValue', {
          nom: 'lieuLogement',
          value
        })
      }
    }
  },
  methods: {
    loadFiche(value) {
      if (value && value.canton) {
        console.debug('getFiches - LocationSelector')
        this.$store
          .dispatch('getFiches', {
            canton: value.canton
          })
          .then(() => {
            this.fichesLoaded = true
          })
      }
    }
  },
  watch: {
    search(val) {
      // Items have already been requested
      if (this.isLoading) return

      this.isLoading = true

      // Lazily load input items
      axios
        .get(`/${i18n.locale}/api/region?search=${val}`)
        .then(res => {
          this.items = res.data.results.map(e => {
            e.itemText = `${e.npa} ${e.localite}`
            if (e.commune !== e.localite) {
              e.itemText += ` (${e.commune})`
            }
            return e
          })
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => (this.isLoading = false))
    }
  }
}
</script>

<style></style>
