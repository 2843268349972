export function debounce(func, wait, immediate) {
  let timeout
  return function() {
    const context = this
    const args = arguments
    const later = function() {
      timeout = null
      if (!immediate) func.apply(context, args)
    }
    const callNow = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
    if (callNow) func.apply(context, args)
  }
}

export function vueToObj(vue) {
  const obj = {}
  Object.assign(obj, vue.$data)
  Object.keys(vue.$options.computed).forEach(key => {
    // except for personnes ignore keys with arrays of vm to skip personnes array copy cycles
    if ((key !== 'personnes' && key !== 'personnes_externes') && Array.isArray(vue[key]) && vue[key].length > 0 && vue[key]._isVue) {
      return
    }
    obj[key] = vue[key]
  })
  if (obj.personnes) {
    obj.personnes = obj.personnes.map(vueToObj)
  }
  if (obj.personnes_externes) {
    obj.personnes_externes = obj.personnes_externes.map(vueToObj)
  }
  if (obj.currentPersonne) {
    delete obj.currentPersonne
  }
  if (obj.personnesData) {
    delete obj.personnesData
  }
  return obj
}
