import { roundEstimation } from '@/utils/roundEstimation'
export default function CHF(amount, prefix = 'CHF', rounding = true) {
  if (isNaN(amount)) {
    // test is amount == NaN because NaN !== NaN -> true
    // eslint-disable-next-line
    if (amount !== amount) {
      return ' Il manque des informations pour pouvoir estimer le montant de la prestation.'
    }
    return amount
  }
  if (amount === null) {
    amount = 0
  }
  amount = rounding ? roundEstimation(amount) : amount
  return `${prefix} ${amount.toLocaleString('de-CH')}`
}
