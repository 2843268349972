<template>
  <div>
    <v-row v-for="(line, i) in lines" :key="i">
      <v-col cols="4">
        <v-text-field
          type="number"
          v-model="line.year"
          label="En quelle année ?"
          placeholder="Année"
          :rules="[rules.year]"
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-currency-field
          v-model="line.value"
          label="Valeur en CHF"
          :min="0"
          persistent-hint
          placeholder="Fortune cédée"
        ></v-currency-field>
      </v-col>
      <v-col cols="1">
        <v-btn @click="removeLine(line)" icon><v-icon>fa-trash</v-icon></v-btn>
      </v-col>
    </v-row>
    <v-btn color="primary" depressed @click="addLine">Ajouter un moment</v-btn>
  </div>
</template>

<script>
export default {
  name: 'CalculeDessaisissement',
  data() {
    const self = this
    return {
      rules: {
        year(value) {
          const year = parseInt(value, 10)
          const minYear = self.currentYear - 20
          return year >= minYear && year <= self.currentYear
            ? true
            : `Moment doit être un nombre compris entre ${minYear} et ${self.currentYear}.`
        }
      }
    }
  },
  computed: {
    personneIndex() {
      return this.$store.state.sim.vm.personnes.indexOf(
        this.$store.state.sim.vm.currentPersonne
      )
    },
    currentYear() {
      return new Date().getYear() + 1900
    },
    lines() {
      if (
        this.$store.state.sim.vm &&
        this.$store.state.sim.vm.currentPersonne
      ) {
        return this.$store.state.sim.vm.currentPersonne.dessaisissements
      }
      return []
    },
    total() {
      return this.lines.reduce((total, line) => {
        const amount = parseInt(line.value || 0, 10)
        const year = parseInt(line.year || this.currentYear, 10)
        const n = Math.max(this.currentYear - year - 1, 0)
        total += Math.max(amount - n * 10000, 0)
        return total
      }, 0)
    }
  },
  methods: {
    addLine() {
      this.lines.push({
        year: '',
        value: ''
      })
    },
    removeLine(line) {
      const index = this.lines.indexOf(line)
      if (index > -1) {
        this.lines.splice(index, 1)
      }
    }
  },
  watch: {
    total: {
      immediate: true,
      handler() {
        if (this.lines.length === 0) {
          this.addLine()
        }
        this.$store.dispatch('updateSimValue', {
          nom: 'dessaisissement_avec_amortissement',
          value: this.total,
          personneIndex: this.personneIndex
        })
        this.$store.dispatch('updateSimValue', {
          nom: 'dessaisissements',
          value: this.lines,
          personneIndex: this.personneIndex
        })
      }
    }
  }
}
</script>
