export function cleanEquation(e, keepArticleLoiDetail = false) {
  const cleanEquation = {
    nom: e.nom,
    libelle_fr: e.libelle_fr,
    libelle_de: e.libelle_de,
    equation: e.equation,
    description_fr: e.description_fr,
    description_de: e.description_de,
    remarque: e.remarque,
    type: e.type,
    scope: e.scope,
    estimation_min: isNaN(e.estimation_min) ? null : e.estimation_min,
    estimation_max: isNaN(e.estimation_max) ? null : e.estimation_max,
    article_loi: e.article_loi_detail ? e.article_loi_detail.pk : null
  }
  if (keepArticleLoiDetail) {
    cleanEquation.article_loi_detail = e.article_loi_detail
  }
  if ((e.type === 'I' || e.type === 'W' || e.type === 'O') && e.input_widget) {
    cleanEquation.input_widget = e.input_widget
  }
  return cleanEquation
}

export function createEquation(nom) {
  return {
    nom,
    equation: '',
    libelle_fr: '',
    libelle_de: '',
    description_fr: '',
    description_de: '',
    type: 'E',
    scope: null,
    estimation_min: null,
    estimation_max: null,
    remarque: '',
    article_loi: null,
    article_loi_detail: null
  }
}
