<template>
  <div class="link-calculateur">
    <p v-if="calculateurs.length > 0">
      Pour estimer uniquement cette prestation, vous pouvez également utiliser:
    </p>
    <p v-for="c in calculateurs" :key="c.pk">
      <a :href="c.lien" rel="noopener" target="_blank" class="open-external"
        >{{ c.nom }} <v-icon x-small>fas fa-external-link-alt</v-icon></a
      >
    </p>
  </div>
</template>

<script>
import store from '@/store'

export default {
  name: 'LinkCalculateur',
  props: {
    prestation: Number
  },
  computed: {
    calculateurs() {
      return this.$store.state.jestime.calculateurs.filter(
        c =>
          c.prestation === this.prestation ||
          c.prestation === store.state.jestime.idMainPrestation
      )
    }
  }
}
</script>
<style>
.link-calculateur p {
  margin: 0;
}
</style>
