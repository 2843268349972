import axios from 'axios'
import i18n from '@/plugins/i18n'

const state = {
  loggedIn: false,
  profile: undefined,
  validation: { email: true },
  authError: false
}

const getters = {}

const mutations = {
  login(state) {
    state.loggedIn = true
  },
  logout(state) {
    state.loggedIn = false
    state.profile = undefined
  },
  setProfile(state, payload) {
    state.profile = payload
  },
  setValidationEmail(state, bool) {
    state.validation.email = bool
  },
  setAuthError(state, bool) {
    state.authError = bool
  }
}

const actions = {
  postLogin(context, payload) {
    return axios
      .post(`/${i18n.locale}/api/users/login/`, payload)
      .then(response => {})
      .catch(e => {
        context.commit('setAuthError', true)
        throw e
      })
  },
  postRegister(context, payload) {
    return axios
      .post(`/${i18n.locale}/api/users/register/`, payload)
      .then(response => {
        if (response.data.status === 210) {
          context.commit('setValidationEmail', false)
        } else {
          context.commit('setValidationEmail', true)
          context.commit('login')
          context.commit('setProfile', response.data)
        }
      })
      .catch(e => {
        console.log(e)
      })
  },
  getProfile(context) {
    return axios
      .get(`/${i18n.locale}/api/users/profile/`)
      .then(response => {
        context.commit('login')
        context.commit('setProfile', response.data)
        context.dispatch('getAdminBaseData')
      })
      .catch(e => {
        context.commit('logout')
      })
  },
  logout(context) {
    // TODO: logout from django
    context.commit('logout')
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
