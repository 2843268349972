const SURVEY_DATA_STORAGE_KEY = 'jestime-survey-data'

export function loadSurveyData(survey) {
  const previousData =
    window.localStorage.getItem(SURVEY_DATA_STORAGE_KEY) || null
  if (previousData) {
    const data = JSON.parse(previousData)
    survey.data = data
    survey.isCompletedBefore = data.state === 'completed'
    if (data.pageNo) {
      survey.currentPageNo = data.pageNo
    }
  }
}

export function saveSurveyData(survey) {
  const data = survey.data
  data.pageNo = survey.currentPageNo
  data.state = survey.state
  window.localStorage.setItem(SURVEY_DATA_STORAGE_KEY, JSON.stringify(data))
}

export function restartSurvey(survey) {
  window.localStorage.removeItem(SURVEY_DATA_STORAGE_KEY)
  if (survey) {
    survey.clear()
  }
}
