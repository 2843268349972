<i18n>
{
  "fr": {
    "add_personne_more": "Ajouter une autre personne"
  },
  "de": {
    "add_personne_more": "Person hinzufügen"
  }
}
</i18n>
<template>
  <v-container v-if="$store.state.sim.vm">
    <v-dialog v-model="dialogDep" width="500" v-if="scenarioParent.length > 0">
      <v-card>
        <v-card-title>
          Information
        </v-card-title>
        <v-card-text class="info-dep">
          Vous êtes en train d'estimer le montant <em class="primary-color">{{ lastParentName }}</em> auquel vous pourriez avoir droit.
          <span v-if="$store.state.jestime.prestations[$store.state.jestime.fiche.prestation].slug === 'rdu'">Pour ce faire, nous devons d'abord vous demander davantage de renseignements sur votre situation financière.</span>
          <span v-else>Pour ce faire, il faut d’abord que Jestime puisse estimer le montant <em class="primary-color">{{ $store.state.jestime.prestations[$store.state.jestime.fiche.prestation].nom_mention }}</em> auquel vous et les membres de votre foyer pourriez avoir droit.</span>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn depressed color="primary" @click="dialogDep = false">Continuer</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <transition name="fade" mode="out-in">
      <v-row :key="currentIndex" v-if="!result">
        <v-col md="12" lg="7">
          <content-renderer
            v-if="scenario && currentStep"
            :content="currentStep.content"
          ></content-renderer>
        </v-col>
        <v-col
          lg="4"
          offset-lg="1"
          class="text-lg-center text-md-center text-center hidden-md-and-down"
        >
          <p>
            <img
              :src="`/img/mini/mini-illustration${getMiniIndex()}.png`"
              style="max-width: 400px;"
            />
          </p>
          <ask-jestime></ask-jestime>
        </v-col>
      </v-row>
    </transition>
    <div v-if="personneSelector && personneSelector.allowEdit && personnes.length > 0">
      <div class="avatar-box" style="width:140px">
        <v-btn text height="150" class="avatar-box-inner add" @click="addPerson()">
          <div class="d-flex flex-column">
            <v-icon>fa fa-plus-circle</v-icon>
            <span>{{$t('add_personne_more')}}</span>
          </div>
        </v-btn>
      </div>
    </div>
    <prestation-output v-if="result"></prestation-output>
    <v-row>
      <v-col>
        <div
          class="scenario-nav"
          v-if="
            !(
              $store.state.sim.vm.personnes[0] &&
              $store.state.sim.vm.personnes[0].age < 18
            )
          "
        >
          <v-btn
            class="ma-2 nav-previous"
            v-if="!first || !isFirstPersonne"
            @click="previous()"
          >
            <v-icon left dark>fas fa-caret-left</v-icon>
            {{ $t('previous') }}</v-btn
          >
          <v-btn class="ma-2 nav-next" dark v-if="!last && !isStopped" @click="next()"
            >{{ $t('next') }}
            <v-icon right dark>fas fa-caret-right</v-icon></v-btn
          >
          <v-btn
            class="ma-2 nav-estimation"
            dark
            v-if="
              last &&
                isFiche &&
                !result &&
                scenarioParent.length === 0 &&
                !isStopped
            "
            @click="next()"
            >estimation</v-btn
          >
          <v-btn
            class="ma-2 nav-nextparent"
            dark
            v-if="last && scenarioParent.length > 0"
            @click="redirectToParent()"
            >{{ $t('next') }}
            <v-icon right dark>fas fa-caret-right</v-icon></v-btn
          >
          <v-btn
            class="ma-2 nav-prestations"
            v-if="(last && !isFiche) || result || (isFiche && isStopped)"
            dark
            :to="{ name: 'prestations' }"
            >Prestations</v-btn
          >
        </div>
      </v-col>
    </v-row>
    <v-row
      class="mt-4 text-lg-center text-md-center text-center hidden-lg-and-up"
    >
      <v-col>
        <p>
          <img
            :src="`/img/mini/mini-illustration${getMiniIndex()}.png`"
            style="max-width: 400px;"
          />
        </p>
        <ask-jestime></ask-jestime>
      </v-col>
    </v-row>
    <log-debug v-if="$store.state.sim.vm && $store.state.sim.vm.debug"></log-debug>
  </v-container>
</template>

<script>
import store from '@/store'
import ContentRenderer from './ContentRenderer'
import PrestationOutput from '@/components/pages/wizard/PrestationOutput'
import AskJestime from '@/components/AskJestime'
import LogDebug from '@/components/LogDebug'
import { getPersonneSelector, getPersonnesFromPersonneSelector, skipStep } from '@/utils/scenarios'
import { allOverridden, available } from '@/models/fiche'

function saveDependencyAndGetRouteToParent() {
  store.commit('addDependencyCalculated', store.state.jestime.fiche.nom)
  // redirect to parent
  const route = {
    name: 'jestime',
    query: Object.assign(
      {},
      store.state.jestime.scenarioParent[
        store.state.jestime.scenarioParent.length - 1
      ]
    )
  }
  store.commit(
    'setScenarioParent',
    store.state.jestime.scenarioParent.slice(
      0,
      store.state.jestime.scenarioParent.length - 1
    )
  )
  return route
}

function loadScenario(query, callback) {
  // load only if required
  if (
    query.canton !== undefined &&
    query.prestation !== undefined &&
    store.state.jestime.scenario &&
    // eslint-disable-next-line
    store.state.jestime.scenario.canton == query.canton &&
    // eslint-disable-next-line
    store.state.jestime.scenario.prestation == query.prestation
  ) {
    if (callback) {
      callback()
    }
    return
  }

  const payload = {}
  if (query.canton && query.prestation) {
    payload.canton = query.canton
    payload.prestation = query.prestation
  } else {
    payload.canton__slug = 'CH'
    payload.prestation = store.state.jestime.idMainPrestation
  }
  store
    .dispatch('getScenario', payload)
    .then(({ scenario, fiche }) => {
      if (callback) {
        if (
          fiche &&
          fiche.depend_de.length > 0 &&
          !store.state.jestime.scenarioParent.includes(fiche.nom)
        ) {
          // check if value must be calculated
          let needInput = false
          let depFiche
          for (depFiche of fiche.depend_de) {
            const depNotCalculated = !store.state.sim.vm.dependencyCalculated.includes(depFiche.nom)
            const notCompletelyOverriden = !allOverridden(depFiche)
            const ficheAvailable = available(depFiche)
            if (store.state.sim.vm && store.state.sim.vm.debug) {
              console.debug('check if need to switch to dep', depFiche.pk, depFiche.nom, { depNotCalculated, notCompletelyOverriden, ficheAvailable })
            }
            if (
              depNotCalculated &&
              notCompletelyOverriden &&
              ficheAvailable
            ) {
              needInput = true
              break
            }
          }
          if (needInput) {
            // save current url
            store.commit(
              'setScenarioParent',
              store.state.jestime.scenarioParent.concat([query])
            )
            // redirect to other fiche
            const route = {
              name: 'jestime',
              query: {
                canton: depFiche.canton,
                prestation: depFiche.prestation
              }
            }
            return callback(route)
          }
        }

        if (!(query.canton && query.prestation)) {
          // redirect
          const route = {
            name: 'jestime',
            query: Object.assign({}, query, {
              canton: scenario.canton,
              prestation: scenario.prestation
            })
          }
          callback(route)
        } else {
          callback()
        }
      }
    })
    .catch(() => {
      if (store.state.jestime.scenarioParent.length > 0) {
        callback(saveDependencyAndGetRouteToParent())
      }
    })
}

export default {
  name: 'ScenarioRenderer',
  data() {
    return {
      dialogDepDismissed: false
    }
  },
  computed: {
    dialogDep: {
      get() {
        return !this.dialogDepDismissed && this.scenarioParent.length > 0
      },
      set(value) {
        this.dialogDepDismissed = !value
      }
    },
    scenario() {
      return this.$store.state.jestime.scenario
    },
    personneSelector() {
      return this.currentStep && getPersonneSelector(this.currentStep.content)
    },
    personnes() {
      if (this.hasPersonneSelector) {
        const ps = this.personneSelector
        if (ps && this.$store.state.sim.vm) {
          return getPersonnesFromPersonneSelector(ps)
        }
      }
      return this.$store.state.sim.vm ? this.$store.state.sim.vm.personnes : []
    },
    scenarioParent() {
      return this.$store.state.jestime.scenarioParent
    },
    lastParentName() {
      if (this.scenarioParent.length > 0) {
        return this.$store.state.jestime.prestations[this.scenarioParent[0].prestation].nom_mention
      }
      return ''
    },
    currentIndex: {
      get() {
        return Number(this.$route.query.step) || 0
      },
      set(value) {
        const query = Object.assign({}, this.$route.query, { step: value })
        this.$router.push({ query })
      }
    },
    currentStep() {
      return (
        this.scenario &&
        this.scenario.steps &&
        this.scenario.steps[this.currentIndex]
      )
    },
    hasPersonneSelector() {
      return !!this.personneSelector
    },
    currentPersonneIndex() {
      return this.personnes.indexOf(this.$store.state.sim.vm.currentPersonne)
    },
    isFirstPersonne() {
      return this.currentPersonneIndex <= 0
    },
    isLastPersonne() {
      return this.currentPersonneIndex === this.personnes.length - 1
    },
    first() {
      return this.currentIndex === 0
    },
    last() {
      const isLastStep =
        this.scenario &&
        this.scenario.steps &&
        this.currentIndex >= this.scenario.steps.length - 1
      if (this.hasPersonneSelector) {
        return isLastStep && this.isLastPersonne
      }
      return isLastStep
    },
    isFiche() {
      return this.$store.state.jestime.fiche.pk
    },
    result() {
      return (
        this.isFiche &&
        this.scenario &&
        this.scenario.steps &&
        this.currentIndex === this.scenario.steps.length
      )
    },
    isStopped() {
      return this.currentStep && this.hasStopped(this.currentStep.content)
    }
  },
  methods: {
    getMiniIndex() {
      const count = 5
      let pos = this.currentIndex
      if (this.scenario) {
        pos += this.scenario.pk
      }
      return (pos % count) + 1
    },
    // TODO refactor used in ContentRenderer
    hasStopped(content) {
      for (const c of content) {
        if (c.type === 'html' && c.html.includes('class="stop"')) {
          return true
        }
        if (c.type === 'group' && this.get(c.condition)) {
          if (this.hasStopped(c.content)) {
            return true
          }
        }
      }
      return false
    },
    get(condition) {
      if (condition) {
        const equation = this.$store.getters.allVariables[condition]
        if (equation) {
          if (equation.scope === 'P') {
            if (this.$store.state.sim.vm.currentPersonne) {
              return this.$store.state.sim.vm.currentPersonne[condition]
            }
            return false
          }
          return this.$store.state.sim.vm[condition]
        }
      }
      return true
    },
    // END
    previous() {
      if (this.hasPersonneSelector && !this.isFirstPersonne) {
        this.$store.dispatch('updateSimValue', {
          nom: 'currentPersonne',
          value: this.personnes[this.currentPersonneIndex - 1]
        })
        window.scrollTo(0, 0)
      } else {
        this.currentIndex--
        if (this.currentIndex < 0) {
          const route = {
            name: 'prestations'
          }
          this.$router.push(route)
        }
      }
    },
    next() {
      if (this.hasPersonneSelector && !this.isLastPersonne) {
        this.$store.dispatch('updateSimValue', {
          nom: 'currentPersonne',
          value: this.personnes[this.currentPersonneIndex + 1]
        })
        window.scrollTo(0, 0)
      } else {
        this.currentIndex++
      }
    },
    redirectToParent() {
      this.currentIndex = 0
      this.$router.push(saveDependencyAndGetRouteToParent())
    },
    addPerson() {
      const personne = {}
      this.$store.commit('updateSimAddPerson', personne)
      window.scroll(0, 0)
    }
  },
  beforeRouteEnter(to, from, next) {
    loadScenario(to.query, next)
  },
  // when route changes and this component is already rendered,
  // the logic will be slightly different.
  beforeRouteUpdate(to, from, next) {
    loadScenario(to.query, next)
  },
  watch: {
    currentIndex: {
      handler(val, old) {
        if (!this.$store.state.sim.vm) return // not ready
        if (!this.hasPersonneSelector || val > old) {
          this.$store.dispatch('updateSimValue', {
            nom: 'currentPersonne',
            value: this.personnes[0]
          })
        }
        if (this.hasPersonneSelector && old === undefined) {
          this.$store.dispatch('updateSimValue', {
            nom: 'currentPersonne',
            value: this.personnes[0]
          })
        }
        if (this.hasPersonneSelector && val < old) {
          this.$store.dispatch('updateSimValue', {
            nom: 'currentPersonne',
            value: this.personnes[this.personnes.length - 1]
          })
        }
        // skip scenario steps with empty personne selector, which cannot edit
        if (
          skipStep(this.currentStep)
        ) {
          if (old === undefined) {
            this.next()
          } else if (val > old) {
            this.next()
          } else if (val < old) {
            this.previous()
          }
        }
      },
      immediate: true
    }
  },
  components: {
    ContentRenderer,
    PrestationOutput,
    AskJestime,
    LogDebug
  }
}
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.scenario-nav {
  margin-top: 1em;
  margin-left: -8px; /* negate ma-2 of first button (not always the same button) */
}

.scenario-nav .v-btn {
  min-width: 180px !important;
}

.info-dep em {
  font-weight: bold;
  font-style: normal;
}
</style>
